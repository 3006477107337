import moment from "moment/moment";
export const COL_DEF = [
    { headerName: '이름', field: 'name' },
    { headerName: '위치', field: 'zoneName' },
    { headerName: '적용 서버', field: 'vmName' },
    { headerName: '상태', field: 'vmState' },
    { headerName: '타입', field: 'storageType' },
    { headerName: '상품', field: 'templateName' },
    { headerName: '용량', field: 'size' },
    { headerName: 'IOPS', field: 'serviceOfferingName' },
    { headerName: '생성일시',
        field: 'created',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss')
    }
];
