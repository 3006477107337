var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAlertDialog } from '@innogrid-ui/core';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onApiResponse, STEP_VALIDATIONS, } from '@/pages/console/myData/applicationDataManagement/dataProcess/stepConfig';
import { useDataProcessStore } from '@/stores';
export const useValidation = (id, type, updateStep, isLastStep) => {
    const navigate = useNavigate();
    const { open } = useAlertDialog();
    const { stepState, updateStepState, clearData, setInitDataset } = useDataProcessStore((state) => ({
        stepState: state.stepState[id],
        updateStepState: state.updateStepState,
        clearData: state.clearData,
        setInitDataset: state.setInitDataset,
    }));
    const stepStateRef = useRef();
    useEffect(() => {
        stepStateRef.current = stepState;
    }, [stepState]);
    const onDialogOpen = useCallback((config) => new Promise((resolve) => {
        open(Object.assign(Object.assign({}, config), { onClickConfirm: () => {
                if (config.onClickConfirm) {
                    config.onClickConfirm();
                }
                resolve(true);
            } }));
    }), [open]);
    const onError = useCallback((message) => {
        open({ children: message, variant: 'common' });
        return false;
    }, [open]);
    const onCompletion = useCallback((response) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!stepState)
            return false;
        if (isLastStep) {
            open({
                children: onApiResponse(response)
                    ? '데이터 개방이 완료 되었습니다.'
                    : ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.message) || '처리가 완료되었습니다.',
                variant: 'common',
                onClickConfirm: () => {
                    clearData(id);
                    navigate('/my-data/application-data-management');
                },
            });
        }
        else {
            updateStep({ direction: 'increment' });
        }
        return true;
    }), [stepState, isLastStep, id, clearData, navigate, updateStep, open]);
    const [isLoading, setIsLoading] = useState(false);
    const validateAndProceed = useCallback((currentStep) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e;
        const stepConfig = (_b = STEP_VALIDATIONS[type]) === null || _b === void 0 ? void 0 : _b[currentStep];
        if (!stepConfig)
            return true;
        // 유효성 검사
        const validation = (_c = stepConfig.validate) === null || _c === void 0 ? void 0 : _c.call(stepConfig, { state: { [id]: stepState } });
        if (!isEmpty(validation) && !validation.isValid) {
            return onError(validation.message || '값이 올바르지 않습니다.');
        }
        // 경고 다이얼로그 표시
        if (stepConfig.alert && !isLastStep) {
            const shouldProceed = yield onDialogOpen(stepConfig.alert);
            if (!shouldProceed)
                return false;
            // dataProcess 단계에서 마스킹 처리 검증
            if (stepConfig.alert.onClickConfirm) {
                const currentState = useDataProcessStore.getState().stepState;
                const currentId = useDataProcessStore.getState().id;
                const deid_yn = (_e = (_d = currentState === null || currentState === void 0 ? void 0 : currentState[currentId]) === null || _d === void 0 ? void 0 : _d.dataProcess) === null || _e === void 0 ? void 0 : _e.deid_yn;
                if (deid_yn !== 'true') {
                    onError('마스킹 처리가 필요합니다.');
                    return false;
                }
            }
        }
        // API 호출
        if (stepConfig.mutation) {
            setIsLoading(true);
            const response = yield stepConfig.mutation({
                state: { [id]: stepStateRef.current },
                updateStepState,
                setInitDataset,
            });
            setIsLoading(false);
            if (!onApiResponse(response)) {
                return onError((response === null || response === void 0 ? void 0 : response.data.message) || '처리 중 오류가 발생했습니다.');
            }
            return onCompletion(response);
        }
        return onCompletion();
    }), [type, id, stepState, isLastStep, onDialogOpen, onError, onCompletion, updateStepState, setInitDataset]);
    return { validateAndProceed, isLoading };
};
export const useBaseDialog = () => {
    const { open } = useAlertDialog();
    return useCallback((props) => {
        open(Object.assign({ variant: 'common', cancelButtonText: '취소' }, props));
    }, [open]);
};
