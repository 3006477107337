import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Stepper } from '@innogrid-ui/core';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '@/components';
import { useBaseDialog, useValidation } from '@/hooks/useValidation';
import { ConsolePageLayout } from '@/layouts';
import { Steps } from '@/pages/console/myData/applicationDataManagement/dataProcess/stepConfig';
import { useDataProcessStore } from '@/stores';
import styles from './ApplicationDataProcessPage.module.scss';
import { COMPONENT_MAP } from './stepConfig';
export default function ApplicationDataProcessPage() {
    var _a, _b, _c;
    const navigate = useNavigate();
    const { state } = useLocation();
    const { title: stateTitle, titles, createdAt } = state || {};
    const type = state.type;
    const showBaseDialog = useBaseDialog();
    const { id, stepState, updateStep } = useDataProcessStore((state) => ({
        id: state.id,
        stepState: state.stepState,
        updateStep: state.updateStep,
    }));
    const currentStep = ((_a = stepState === null || stepState === void 0 ? void 0 : stepState[id]) === null || _a === void 0 ? void 0 : _a.currentStep) || 0;
    const fusionTableName = type === 'dataFusion' && id ? (_c = (_b = stepState === null || stepState === void 0 ? void 0 : stepState[id]) === null || _b === void 0 ? void 0 : _b.dataFusionCreate) === null || _c === void 0 ? void 0 : _c.tableName : undefined;
    const steps = useMemo(() => (type ? Steps[type] : []), [type]);
    const isLastStep = currentStep === steps.length - 1;
    const isShowPrevButton = useMemo(() => {
        if (type === 'dataFusion') {
            return [1, 3, 4].includes(currentStep);
        }
        return !isLastStep && currentStep !== 0;
    }, [type, currentStep, isLastStep]);
    const [pageTitle, setPageTitle] = useState('');
    useEffect(() => {
        if (type === 'dataFusion') {
            setPageTitle(fusionTableName || (titles === null || titles === void 0 ? void 0 : titles.join(' / ')));
        }
        else if (type === 'api' || type === 'dataset') {
            setPageTitle(stateTitle);
        }
    }, [type, currentStep]);
    const { validateAndProceed, isLoading } = useValidation(id, type, updateStep, isLastStep);
    const onClickNext = useCallback(() => validateAndProceed(currentStep), [validateAndProceed, currentStep]);
    const onClickPrev = useCallback(() => {
        showBaseDialog({
            children: '이전 단계로 이동하시겠습니까?',
            onClickConfirm: () => updateStep({ direction: 'decrement' }),
        });
    }, [showBaseDialog, updateStep]);
    const onClickList = useCallback(() => {
        showBaseDialog({
            title: '목록으로 이동하시겠습니까?',
            children: (_jsxs(_Fragment, { children: ["\uBAA9\uB85D\uC73C\uB85C \uC774\uB3D9 \uD6C4\uC5D0\uB3C4 \uC791\uC5C5 \uB0B4\uC6A9\uC740 \uC800\uC7A5\uB418\uC5B4", _jsx("br", {}), "\uC774\uC5B4\uC11C \uC791\uC5C5\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4."] })),
            onClickConfirm: () => navigate('/my-data/application-data-management'),
        });
    }, [showBaseDialog, navigate]);
    const renderComponent = useCallback(() => {
        var _a;
        const Component = type && ((_a = COMPONENT_MAP[type]) === null || _a === void 0 ? void 0 : _a[currentStep]);
        if (!Component)
            return null;
        return _jsx(Component, {});
    }, [type, currentStep]);
    const Header = useMemo(() => (_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: pageTitle })), _jsx("div", Object.assign({ className: styles.dateBox }, { children: (type === 'api' || type === 'dataset') && (_jsxs(_Fragment, { children: [_jsx("em", { children: type.toUpperCase() }), _jsxs("span", { children: ["\uC2B9\uC778\uC77C : ", createdAt ? moment(createdAt).format('YYYY. MM. DD') : '-'] })] })) }))] }))), [type, pageTitle, createdAt]);
    return (_jsxs(ConsolePageLayout, Object.assign({ breadcrumb: ['My Data', '사용 신청 데이터 관리', '데이터 가공'] }, { children: [_jsx("div", Object.assign({ className: styles.boardListHeader }, { children: Header })), _jsx("div", Object.assign({ className: styles.stepperBox }, { children: _jsx(Stepper, { step: currentStep, steps: steps }) })), _jsx("div", Object.assign({ className: styles.contentWrapper }, { children: isLoading ? _jsx(Loading, { color: "#268eff" }) : renderComponent() })), _jsxs("div", Object.assign({ className: styles.btns }, { children: [_jsx(Button, Object.assign({ color: "common", onClick: onClickList }, { children: "\uBAA9\uB85D" })), _jsxs("div", { children: [isShowPrevButton && (_jsx(Button, Object.assign({ color: "common", onClick: onClickPrev }, { children: "\uC774\uC804" }))), _jsx(Button, Object.assign({ onClick: onClickNext }, { children: isLastStep ? '확인' : '다음' }))] })] }))] })));
}
