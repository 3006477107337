import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from '@/hooks';
import styles from './PageNavigation.module.scss';
export default function PageNavigation({ navigation }) {
    const navigate = useNavigate();
    const handleClick = (link) => {
        navigate(link);
        window.scrollTo({ top: 0 });
    };
    return (_jsxs("div", Object.assign({ className: styles.board_read_page }, { children: [_jsxs("div", Object.assign({ className: styles.board_next_wrap }, { children: [_jsx("div", Object.assign({ className: styles.board_next }, { children: _jsx("span", { children: "\uB2E4\uC74C\uAE00" }) })), _jsx("div", Object.assign({ className: styles.titWrapper }, { children: navigation.next.title ? (_jsxs("span", Object.assign({ className: styles.pageTit, onClick: () => handleClick(navigation.next.link) }, { children: [navigation.next.title, ' '] }))) : (_jsx("span", { children: "\uB2E4\uC74C \uAE00\uC774 \uC5C6\uC2B5\uB2C8\uB2E4." })) }))] })), _jsxs("div", Object.assign({ className: styles.board_prev_wrap }, { children: [_jsx("div", Object.assign({ className: styles.board_prev }, { children: _jsx("span", { children: "\uC774\uC804\uAE00" }) })), _jsx("div", Object.assign({ className: styles.titWrapper }, { children: navigation.prev.title ? (_jsxs("span", Object.assign({ className: styles.pageTit, onClick: () => handleClick(navigation.prev.link) }, { children: [navigation.prev.title, ' '] }))) : (_jsx("span", { children: "\uC774\uC804 \uAE00\uC774 \uC5C6\uC2B5\uB2C8\uB2E4." })) }))] }))] })));
}
