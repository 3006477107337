import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import ReactDom from 'react-dom';
import { useEvent } from 'react-use';
import close from '@/assets/images/icon/ico_close.svg';
import { Button } from '@/components';
import styles from './Modal.module.scss';
const Portal = (props) => {
    const { isOpen, children } = props;
    if (!isOpen)
        return null;
    return ReactDom.createPortal(_jsx("div", Object.assign({ className: styles.overlay }, { children: children })), document.getElementById('portal-root'));
};
export default function Modal({ title, children, isOpen, setIsOpen, width = '460px', onClickSubmit, isSubmitDisabled, }) {
    const modalRef = useRef(null);
    const onEscKeydownCloseModal = React.useCallback((event) => {
        if (event.key === 'Escape') {
            setIsOpen(false);
        }
    }, []);
    const onClickAwayCloseModal = React.useCallback(() => {
        setIsOpen(false);
    }, []);
    useEvent('keydown', onEscKeydownCloseModal);
    // useClickAway(modalRef, onClickAwayCloseModal);
    return (_jsx(Portal, Object.assign({ isOpen: isOpen }, { children: _jsxs("div", Object.assign({ ref: modalRef, className: styles.modalWrapper, style: { minWidth: width } }, { children: [_jsxs("div", Object.assign({ className: styles.modalHeader }, { children: [_jsx("h2", Object.assign({ className: styles.modalHeaderTitle }, { children: title })), _jsx("button", Object.assign({ type: "button", onClick: () => setIsOpen(false) }, { children: _jsx("img", { src: close, alt: "\uB2EB\uAE30" }) }))] })), _jsx("div", Object.assign({ className: styles.modalContainer }, { children: _jsx("div", Object.assign({ className: styles.modalContent }, { children: children })) })), _jsxs("div", Object.assign({ className: styles.modalFooter }, { children: [_jsx(Button, Object.assign({ className: styles.cancel, onClick: () => setIsOpen(false) }, { children: "\uB2EB\uAE30" })), onClickSubmit && (_jsx(Button, Object.assign({ className: styles.submit, onClick: onClickSubmit, disabled: isSubmitDisabled }, { children: "\uD655\uC778" })))] }))] })) })));
}
