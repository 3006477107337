import { useQueries } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useCallback } from '@/hooks';
import axiosUtil from '@/utils/axios';
import { requestFn } from '../utils';
export default function useReactSelect(props) {
    const getPlaceHolder = useCallback((queryResult) => {
        const { isFetching, isPaused, isError, isSuccess, data } = queryResult;
        if (isFetching)
            return '데이터 조회 중입니다';
        else if (!isError && data === undefined)
            return '필수 정보를 선택해주세요.';
        else if (isError)
            return '데이터 조회에 실패했습니다.';
        else if (isSuccess && isEmpty(data))
            return '데이터가 존재하지 않습니다.';
        else
            return '선택해주세요';
    }, []);
    return useQueries({
        queries: props.map(({ queryKey, enabled, queryFnOptions, labelValue, select, noContentKey = false, isSort = true }) => {
            return {
                queryKey,
                queryFn: () => noContentKey
                    ? axiosUtil(queryKey[0], Object.assign(Object.assign({}, queryFnOptions), { method: 'get' }))
                    : requestFn(queryKey[0], Object.assign(Object.assign({}, queryFnOptions), { method: 'get' })),
                enabled,
                select: select
                    ? select
                    : (data) => data.map((d) => (Object.assign({ label: d[labelValue[0]], value: d[labelValue[1]] }, (labelValue.length > 2 &&
                        labelValue.slice(2).reduce((acc, label) => {
                            acc[label] = d[label];
                            return acc;
                        }, {}))))),
            };
        }),
    }).reduce((prev, current, index) => {
        var _a;
        const isSort = props[index].isSort;
        return Object.assign(Object.assign({}, prev), { [props[index].key]: {
                //options: current.data?.sort((a: any, b: any) => (a.label > b.label ? 1 : -1)),
                options: isSort ? (_a = current.data) === null || _a === void 0 ? void 0 : _a.sort((a, b) => (a.label > b.label ? 1 : -1)) : current.data,
                placeholder: getPlaceHolder(current),
                isLoading: current.isFetching,
                isDisabled: isEmpty(current.data),
                styles: current.isError
                    ? {
                        control: (provided) => (Object.assign(Object.assign({}, provided), { borderColor: 'red' })),
                        // eslint-disable-next-line no-mixed-spaces-and-tabs
                    }
                    : {},
            } });
    }, {});
}
