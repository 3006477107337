import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useLocation } from '@/hooks';
import { useCommonStore } from '@/stores';
import MenuItem from './MenuItem';
import styles from './navMenuList.module.scss';
export default function NavMenuList({ menuList }) {
    const { pathname } = useLocation();
    const setDepthByParsedUrl = useCommonStore((state) => state.setDepthByParsedUrl);
    useEffect(() => {
        setDepthByParsedUrl(pathname.split('/').slice(1));
    }, [pathname]);
    return (_jsx("div", Object.assign({ className: styles.menuListWrapper }, { children: menuList === null || menuList === void 0 ? void 0 : menuList.map((subItem, index) => _jsx(MenuItem, { item: subItem }, index)) })));
}
