export const COL_DEF = [
    { headerName: '이름', field: 'loadBalancerName' },
    { headerName: '상태', field: 'loadBalancerInstanceStatusName', width: 150 },
    {
        headerName: '네트워크',
        field: 'loadBalancerNetworkType',
        valueFormatter: (params) => params.value.codeName,
        width: 150,
    },
    { headerName: 'VPC ID', field: 'vpcNo', width: 150 },
    { headerName: '유형', field: 'loadBalancerType', valueFormatter: (params) => params.value.codeName },
    { headerName: '접속 정보', field: 'loadBalancerDomain' },
    { headerName: '메모', field: 'loadBalancerDescription', valueFormatter: (params) => params.value || '-' },
];
