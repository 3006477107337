import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Viewer } from '@toast-ui/react-editor';
import { Link } from 'react-router-dom';
import { useCustomQuery, useEffect, useParams, useRef } from '@/hooks';
import { DetailLayout } from '@/layouts';
import styles from './TermPage.module.scss';
export default function TermPage() {
    const { type = 'general' } = useParams();
    const viewerRef = useRef(null);
    const { data } = useCustomQuery({
        queryKey: ['/api/v1/cmp/systems/terms'],
        select: (data) => data.reduce((obj, { termType, contents }) => {
            obj[termType] = contents;
            return obj;
        }, {}),
    });
    useEffect(() => {
        var _a, _b;
        if (type === 'privacy') {
            (_a = viewerRef === null || viewerRef === void 0 ? void 0 : viewerRef.current) === null || _a === void 0 ? void 0 : _a.getInstance().setMarkdown(data === null || data === void 0 ? void 0 : data.PRIVACY_POLICY);
        }
        else {
            (_b = viewerRef === null || viewerRef === void 0 ? void 0 : viewerRef.current) === null || _b === void 0 ? void 0 : _b.getInstance().setMarkdown(data === null || data === void 0 ? void 0 : data.TERM_OF_SERVICE);
        }
    }, [data, type]);
    return (_jsx(DetailLayout, Object.assign({ breadcrumb: { Home: '/', 약관: '' } }, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("aside", Object.assign({ className: styles.sideContainer }, { children: _jsxs("ul", Object.assign({ className: styles.nav }, { children: [_jsx("li", Object.assign({ className: `${styles.navItem} ${type === 'general' && styles.selected}` }, { children: _jsx(Link, Object.assign({ to: "/term/general" }, { children: "\uC0AC\uC774\uD2B8 \uC774\uC6A9\uC57D\uAD00" })) })), _jsx("li", Object.assign({ className: `${styles.navItem} ${type === 'privacy' && styles.selected}` }, { children: _jsx(Link, Object.assign({ to: "/term/privacy" }, { children: "\uAC1C\uC778\uC815\uBCF4 \uCC98\uB9AC \uBC29\uCE68" })) }))] })) })), _jsx("div", Object.assign({ className: styles.termContainer }, { children: _jsx(Viewer, { ref: viewerRef }) }))] })) })));
}
