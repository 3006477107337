var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@tanstack/react-query';
import { requestFn } from '../utils';
export default function useCustomQuery(queryOptions) {
    const { queryKey, queryFnOptions } = queryOptions, options = __rest(queryOptions, ["queryKey", "queryFnOptions"]);
    return useQuery(Object.assign(Object.assign({}, options), { queryKey, queryFn: () => requestFn(queryKey[0], Object.assign(Object.assign({}, queryFnOptions), { method: 'get' })), useErrorBoundary: (error) => {
            var _a, _b;
            return !['7'].includes((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.code);
        } }));
}
