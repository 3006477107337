import moment from 'moment/moment';
export const COL_DEF = [
    { headerName: '공인 IP', field: 'publicIp' },
    { headerName: '비공인 IP', field: 'privateIp', valueFormatter: (params) => params.value || '-' },
    { headerName: '적용 인스턴스', field: 'serverName', valueFormatter: (params) => params.value || '-' },
    { headerName: '상태', field: 'publicIpInstanceStatusName' },
    { headerName: '메모', field: 'publicDescription', valueFormatter: (params) => params.value || '-' },
    {
        headerName: '생성일시',
        field: 'createDate',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    },
];
