import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import '@/index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });
root.render(_jsx(BrowserRouter, { children: _jsxs(QueryClientProvider, Object.assign({ client: queryClient }, { children: [_jsx(App, {}), _jsx(ReactQueryDevtools, { initialIsOpen: false })] })) }));
