import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import styles from './breadcrumb.module.scss';
export function Breadcrumb({ pathArr }) {
    const navigate = useNavigate();
    const keys = Object.keys(pathArr);
    return (_jsxs("div", Object.assign({ className: styles.breadcrumbsArea }, { children: [_jsx("h2", Object.assign({ className: styles.subTitle }, { children: keys[keys.length - 1] })), _jsx("div", Object.assign({ className: styles.subBreadcrumbs }, { children: keys.map((key) => (_jsx("span", Object.assign({ style: { cursor: pathArr[key] ? 'pointer' : '' }, onClick: () => pathArr[key] && navigate(pathArr[key]) }, { children: key }), pathArr[key]))) }))] })));
}
export function ConsoleBreadcrumb({ pathArr }) {
    return (_jsx("div", Object.assign({ className: styles.consoleBreadcrumbsArea }, { children: pathArr.map((name) => (_jsx("span", { children: name }, name))) })));
}
