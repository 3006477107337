import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { sortBy } from 'lodash';
import shortid from 'shortid';
import { useCustomQuery } from '@/hooks';
import { DefaultLayout } from '@/layouts';
import ServiceApiCard from './_components/ServiceApiCard';
import styles from './ServiceApiPage.module.scss';
function ServiceApiPage() {
    const { data } = useCustomQuery({
        queryKey: [`/api/v1/cmp/catalog/service-api`],
        queryFnOptions: {
            params: { commonCode: 'service-api' },
        },
    });
    return (_jsx(DefaultLayout, Object.assign({ breadcrumb: { Home: '/', '서비스 API': '' } }, { children: _jsx("div", Object.assign({ className: styles.box }, { children: data &&
                data.length > 0 &&
                sortBy(data, ['parentOrder']).map((serviceApiData) => (_jsxs("div", Object.assign({ className: styles.cardContainer }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: serviceApiData.parentName })), _jsx("div", Object.assign({ className: styles.cardInner }, { children: _jsx(ServiceApiCard, { childService: serviceApiData.childService }) }))] }), shortid.generate()))) })) })));
}
export default ServiceApiPage;
