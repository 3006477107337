import moment from "moment/moment";
export const COL_DEF = [
    { headerName: 'ID', field: 'id' },
    { headerName: 'Name', field: 'name' },
    { headerName: 'Default Pool ID', field: 'defaultPoolId' },
    { headerName: 'Protocol', field: 'protocol' },
    { headerName: 'Description', field: 'description' },
    { headerName: 'Load Balancer ID', field: 'loadbalancers[0].id' },
    { headerName: 'Protocol Port', field: 'protocolPort' },
    { headerName: 'Cert Expire Date',
        field: 'certExpireDate',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss') },
];
