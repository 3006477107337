// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N54pKL7nasxzbS7Tc7dQ{height:auto;display:grid;grid-template-columns:repeat(4, 1fr);grid-gap:16px;max-height:100%;overflow-y:auto;flex-wrap:wrap;margin-top:20px;margin-bottom:20px}.tO6wN9vrNKDEfoTpSrAf{margin-top:50px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/frontCard/components/CardList.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,oCAAA,CACA,aAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CACA,iBAAA","sourcesContent":[".gallery_list {\r\n  height: auto;\r\n  display: grid;\r\n  grid-template-columns: repeat(4, 1fr);\r\n  grid-gap: 16px;\r\n  max-height: 100%;\r\n  overflow-y: auto;\r\n  flex-wrap: wrap;\r\n  margin-top: 20px ;\r\n  margin-bottom: 20px\r\n}\r\n\r\n.noData {\r\n  margin-top: 50px;\r\n  text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery_list": `N54pKL7nasxzbS7Tc7dQ`,
	"noData": `tO6wN9vrNKDEfoTpSrAf`
};
export default ___CSS_LOADER_EXPORT___;
