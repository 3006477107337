import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from '@/hooks';
import { useCommonStore } from '@/stores';
import { axios, parseSearchParams } from '@/utils';
export const useFrontTable = (props) => {
    const { apiUrl, apiOptions, extraQueryKey, dataSize = '10' } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const size = dataSize;
    const setGlobalTableSize = useCommonStore((state) => state.setTableSize);
    const [rowData, setRowData] = useState(); // 표시할 테이블 데이터
    const [totalData, setTotalData] = useState(); // 전체 데이터 갯수
    const [totalPage, setTotalPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedSearchParams = parseSearchParams(searchParams);
    const page = parseInt(searchParams.get('page') || '1');
    const [inputValue, setInputValue] = useState(parsedSearchParams.search || '');
    const [inputPageValue, setInputPageValue] = useState(searchParams.get('page') || '1');
    const queryClient = useQueryClient();
    const params = searchParams.get('page') ? searchParams.toString() : `${searchParams.toString()}&page=1`;
    const { data: pagingInfo } = useQuery({
        queryKey: [apiUrl, Object.assign(Object.assign({}, parseSearchParams(searchParams)), { size: size.toString() }), ...(extraQueryKey || [])],
        queryFn: () => axios.get(`${apiUrl}?${params}&size=${size}`, apiOptions).then((response) => {
            setRowData(response.content);
            setTotalData(response.page.totalElements);
            setTotalPage(response.page.totalPage);
            return response.page;
        }),
        keepPreviousData: true,
    });
    const onClickFirstPage = useCallback(() => {
        if (page === 1)
            return;
        setSearchParams(Object.assign(Object.assign({}, parsedSearchParams), { page: (1).toString() }));
    }, [setSearchParams, page, parsedSearchParams]);
    const onClickPreviousPage = useCallback(() => {
        if (page === 1)
            return;
        setSearchParams(Object.assign(Object.assign({}, parsedSearchParams), { page: (page - 1).toString() }));
    }, [setSearchParams, page, parsedSearchParams]);
    const onClickNextPage = useCallback(() => {
        if (totalPage === 0)
            return;
        setSearchParams(Object.assign(Object.assign({}, parsedSearchParams), { page: (page + 1).toString() }));
    }, [setSearchParams, parsedSearchParams, page]);
    const onClickLastPage = useCallback(() => {
        if (totalPage === 0)
            return;
        if (page === totalPage)
            return;
        setSearchParams(Object.assign(Object.assign({}, parsedSearchParams), { page: totalPage.toString() }));
    }, [setSearchParams, page, parsedSearchParams]);
    const onChangePageSize = useCallback((event) => {
        setGlobalTableSize(parseInt(event.target.value));
    }, [setGlobalTableSize]);
    const onNavigateRow = useCallback((id, link) => {
        const current = location.pathname;
        if (!isEmpty(link)) {
            window.open(link, '_blank');
        }
        else {
            navigate(current + `/${id}`);
            window.scrollTo({ top: 0 });
        }
    }, [location]);
    const onChangeInputValue = (e) => {
        setInputValue(e.currentTarget.value);
    };
    const onClickRefresh = () => {
        if (inputValue)
            setInputValue('');
        queryClient.invalidateQueries([apiUrl], Object.assign({}, parseSearchParams(searchParams)));
        setSearchParams({ page: '1' });
    };
    const onSearch = () => {
        if (inputValue) {
            if (isEqual(inputValue, searchParams.get('search'))) {
                setSearchParams(Object.assign(Object.assign({}, parsedSearchParams), { search: inputValue }));
            }
            else {
                setSearchParams({ search: inputValue, page: '1' });
            }
        }
        else {
            setSearchParams({ page: '1' });
        }
    };
    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (e.currentTarget.value) {
                if (isEqual(e.currentTarget.value, searchParams.get('search'))) {
                    setSearchParams(Object.assign(Object.assign({}, parsedSearchParams), { search: e.currentTarget.value }));
                }
                else {
                    setSearchParams({ search: inputValue, page: '1' });
                }
            }
            else {
                setSearchParams({ page: '1' });
            }
        }
    };
    // 페이지 입력 함수
    const onChangePageValue = (e) => {
        if ((pagingInfo === null || pagingInfo === void 0 ? void 0 : pagingInfo.totalElements) !== 0) {
            setInputPageValue(e.target.value);
        }
    };
    // Enter 입력 시에만 searchParams의 page 값 변화
    const onKeyUpPageValue = (e) => {
        if (e.key === 'Enter') {
            let value = inputPageValue;
            if ((pagingInfo === null || pagingInfo === void 0 ? void 0 : pagingInfo.totalPage) && Number(value) > pagingInfo.totalPage) {
                value = pagingInfo.totalPage.toString();
            }
            else if (Number(value) < 1)
                value = '1';
            if (!isEqual(page.toString(), inputPageValue)) {
                setInputPageValue(value);
                setSearchParams(Object.assign(Object.assign({}, parsedSearchParams), { page: value }));
            }
        }
    };
    useEffect(() => {
        if (!isEqual(page.toString(), inputPageValue))
            setInputPageValue(searchParams.get('page') || '1');
    }, [page]);
    return {
        pagingInfo,
        rowData,
        onClickFirstPage,
        onClickPreviousPage,
        onClickNextPage,
        onClickLastPage,
        onChangePageSize,
        onNavigateRow,
        inputValue,
        inputPageValue,
        apiUrl,
        onClickRefresh,
        onChangeInputValue,
        onSearch,
        onKeyPress,
        onChangePageValue,
        onKeyUpPageValue,
    };
};
