export const COL_DEF = [
    { headerName: 'ID', field: 'subnetNo' },
    { headerName: '이름', field: 'subnetName' },
    { headerName: '상태', field: 'subnetStatus', valueFormatter: (params) => params.value.codeName },
    { headerName: 'VPC ID', field: 'vpcNo' },
    { headerName: 'IP 주소 범위', field: 'subnet' },
    { headerName: 'Zone', field: 'zoneCode' },
    {
        headerName: 'Internet Gateway 전용 여부',
        field: 'subnetType',
        valueFormatter: (params) => (params.value.code === 'PUBLIC' ? 'Y' : 'N'),
    },
    { headerName: '용도', field: 'usageType', valueFormatter: (params) => params.value.codeName },
];
