// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DEHhqqiF75rEk0SYOK84{width:100vw;height:100vh;overflow:hidden;position:fixed;top:0;right:0;bottom:0;left:0;background-color:rgba(0,0,0,.4);z-index:100;text-align:center}.DEHhqqiF75rEk0SYOK84 svg{position:absolute;top:calc(50vh - 40px);left:calc(50vw - 40px)}`, "",{"version":3,"sources":["webpack://./src/components/loading/loaderSpinner.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,eAAA,CACA,cAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,+BAAA,CACA,WAAA,CACA,iBAAA,CAGF,0BACE,iBAAA,CACA,qBAAA,CACA,sBAAA","sourcesContent":[".loadingContainer {\r\n  width: 100vw;\r\n  height: 100vh;\r\n  overflow: hidden;\r\n  position: fixed;\r\n  top: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  background-color:rgba(0,0,0,0.4);\r\n  z-index:100;\r\n  text-align:center;\r\n}\r\n\r\n.loadingContainer svg {\r\n  position:absolute;\r\n  top:calc(50vh - 40px);\r\n  left:calc(50vw - 40px);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": `DEHhqqiF75rEk0SYOK84`
};
export default ___CSS_LOADER_EXPORT___;
