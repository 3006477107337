import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Input, useAlertDialog } from '@innogrid-ui/core';
import { useMutation } from '@tanstack/react-query';
import React, { memo, useState } from 'react';
import { processTextConversion } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/promise';
import { useDataProcessStore } from '@/stores';
import { validateError } from '@/utils';
const Component = ({ column, id, submenu }) => {
    const { open } = useAlertDialog();
    const updateProcessInfo = useDataProcessStore((state) => state.updateProcessInfo);
    const { processResult } = useDataProcessStore((state) => state.processInfo)[id];
    const [formData, setFormData] = useState({ value: '', target_value: '' });
    const onChangeInput = (e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { [e.target.id]: e.target.value })));
    };
    const { mutate } = useMutation({
        mutationFn: processTextConversion,
        onSuccess: ({ data }) => {
            if (!validateError(data, open)) {
                updateProcessInfo({ data: data.result, actionType: 'increment' });
            }
        },
    });
    const onClick = () => {
        mutate({
            defaultData: { datasetInfo: processResult, column },
            contents: formData,
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "dataBlock" }, { children: submenu.map(({ procs_submenu_kor_nm }) => {
                    const isOriginal = procs_submenu_kor_nm.includes('바꾸고');
                    return (_jsxs("div", { children: [_jsx("p", { children: procs_submenu_kor_nm }), _jsx("div", Object.assign({ className: "dataInput" }, { children: _jsx(Input, { id: isOriginal ? 'value' : 'target_value', value: isOriginal ? formData.value : formData.target_value, onChange: onChangeInput }) }))] }, procs_submenu_kor_nm));
                }) })), _jsx("div", Object.assign({ className: "dataBtns" }, { children: _jsx(Button, Object.assign({ onClick: onClick, size: "small" }, { children: "\uC801\uC6A9" })) }))] }));
};
export const TextConversion = memo(Component);
