import moment from 'moment/moment';
export const COL_DEF = [
    { headerName: 'ID', field: 'nasVolumeInstanceNo' },
    { headerName: '이름', field: 'nasVolumeInstanceStatusName' },
    { headerName: '사이즈', field: 'volumeSize' },
    { headerName: 'Region', field: 'regionCode' },
    { headerName: 'Zone', field: 'zoneCode' },
    {
        headerName: '생성일시',
        field: 'createDate',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    },
];
