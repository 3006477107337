import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment/moment';
export const NoticeColDef = [
    {
        field: 'title',
        headerName: '제목',
        format: (value, rowData) => {
            if ((rowData === null || rowData === void 0 ? void 0 : rowData.noticeImportance) === 'IMPORTANCE') {
                return (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ style: { color: 'red', marginRight: '3px' } }, { children: "[\uC911\uC694]" })), _jsx("span", { children: value })] }));
            }
            return value;
        },
    },
    {
        field: 'creatorUsername',
        headerName: '작성자',
    },
    {
        field: 'createdAt',
        headerName: '작성일',
        format: (date) => moment(date).add(9, 'h').format('YYYY-MM-DD HH:mm'),
    },
];
