import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import icoTop from '@/assets/images/icon/ico_top.svg';
import logo from '@/assets/images/logo/logo_header_footer.svg';
import { CmpMenu } from '@/constants';
import styles from './Footer.module.scss';
export default function Footer() {
    const [showTopButton, setShowTopButton] = useState(false);
    const onScroll = () => {
        setShowTopButton(window.scrollY > 0);
    };
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);
    return (_jsx("footer", Object.assign({ className: styles.footer }, { children: _jsxs("div", Object.assign({ className: styles.contentWrapper }, { children: [showTopButton && (_jsx("button", Object.assign({ type: "button", className: styles.btnTop, onClick: () => window.scrollTo({ top: 0 }) }, { children: _jsx("img", { src: icoTop, alt: "go top" }) }))), _jsxs("nav", Object.assign({ className: styles.footerNav }, { children: [_jsx("h2", Object.assign({ className: styles.footerLogo }, { children: _jsx("img", { src: logo, alt: "footer logo" }) })), _jsx("ul", { children: CmpMenu.map((menu) => {
                                var _a;
                                return (_jsxs("li", { children: [_jsx("p", { children: menu.name }), (_a = menu === null || menu === void 0 ? void 0 : menu.children) === null || _a === void 0 ? void 0 : _a.map((submenu) => (_jsx(Link, Object.assign({ to: submenu.link, onClick: () => window.scrollTo({ top: 0 }) }, { children: submenu.name }), submenu.menuId)))] }, menu.menuId));
                            }) })] })), _jsxs("div", Object.assign({ className: styles.copyBox }, { children: [_jsxs("div", { children: [_jsx(Link, Object.assign({ to: "/term/general" }, { children: "\uC774\uC6A9\uC57D\uAD00" })), _jsx(Link, Object.assign({ to: "/term/privacy" }, { children: "\uAC1C\uC778\uC815\uBCF4\uCC98\uB9AC\uBC29\uCE68" }))] }), _jsx("p", { children: "Copyright \u00A9 2022 Digitalsolveup. All rights reserved." })] }))] })) })));
}
