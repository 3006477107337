export const COL_DEF = [
    { headerName: '삭제 보호', field: '' },
    { headerName: '즐겨찾기', field: '' },
    { headerName: '이름', field: 'name' },
    { headerName: 'OS', field: 'osType' },
    { headerName: 'IP 주소', field: 'addressessIp' },
    { headerName: '인스턴스 타입', field: 'addressesIns' },
    { headerName: '키 페어', field: 'keyName' },
    { headerName: '가용성 영역', field: 'availabilityZone' },
    { headerName: '상태', field: 'status' }
];
