var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import axiosUtil from '@/utils/axios';
export function useJobHistoryTable({ apiUrl, datasetId }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [currentPage, setCurrentPage] = useState(1);
    const { data: response, isFetching } = useQuery({
        queryKey: ['jobHistoryTable', apiUrl, datasetId, currentPage],
        queryFn: () => __awaiter(this, void 0, void 0, function* () {
            const response = yield axiosUtil.get(apiUrl, {
                params: {
                    datasetId,
                    currentPage,
                },
            });
            const responseData = response;
            if (!(responseData === null || responseData === void 0 ? void 0 : responseData.code)) {
                throw new Error();
            }
            return {
                code: responseData.code,
                message: responseData.message,
                result: {
                    content: responseData.result.content,
                    size: responseData.result.size,
                    totalPages: responseData.result.totalPages,
                    currentPage: responseData.result.currentPage,
                    totalElements: responseData.result.totalElements,
                },
            };
        }),
        enabled: !!datasetId,
        keepPreviousData: true,
    });
    const onChangePage = useCallback((page) => {
        setCurrentPage(page);
    }, []);
    return {
        data: (_b = (_a = response === null || response === void 0 ? void 0 : response.result) === null || _a === void 0 ? void 0 : _a.content) !== null && _b !== void 0 ? _b : [],
        currentPage: (_d = (_c = response === null || response === void 0 ? void 0 : response.result) === null || _c === void 0 ? void 0 : _c.currentPage) !== null && _d !== void 0 ? _d : 1,
        totalPage: (_f = (_e = response === null || response === void 0 ? void 0 : response.result) === null || _e === void 0 ? void 0 : _e.totalPages) !== null && _f !== void 0 ? _f : 1,
        totalCount: (_h = (_g = response === null || response === void 0 ? void 0 : response.result) === null || _g === void 0 ? void 0 : _g.totalElements) !== null && _h !== void 0 ? _h : 0,
        isFetching,
        onChangePage,
    };
}
