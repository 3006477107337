import { swal } from './swal';
export const isFormValidate = (data, validation) => {
    let isValidate = true;
    for (const input of Object.entries(data)) {
        const [key, value] = input;
        if (Object.keys(validation).includes(key)) {
            if (validation[key].notNull) {
                if (!value) {
                    swal(`${validation[key].fieldName}을/를 입력해주세요`);
                    isValidate = false;
                    break;
                }
            }
            if (validation[key].length) {
                if (data[key].length > validation[key].length) {
                    swal(`${validation[key].fieldName}은/는 최대 ${validation[key].length} 글자입니다`);
                    isValidate = false;
                    break;
                }
            }
            if (validation[key].match) {
                if (!validation[key].match.includes(data[key])) {
                    swal(`${validation[key].fieldName} 값이 올바르지 않습니다`);
                    isValidate = false;
                    break;
                }
            }
        }
    }
    return isValidate;
};
