export const parseSearchParams = (searchParams) => Object.fromEntries(new URLSearchParams(searchParams.toString()));
// 현재 searchParams에 특정 param을 추가
export const createQueryString = (searchParams, targetArr) => {
    const params = new URLSearchParams(searchParams.toString());
    targetArr.forEach((target) => params.set(target.name, target.value));
    return params.toString();
};
// 현재 searchParams에 특정 param을 제거
export const deleteQueryString = (searchParams, targetArr) => {
    const params = new URLSearchParams(searchParams.toString());
    targetArr.forEach((target) => params.delete(target));
    return params.toString();
};
