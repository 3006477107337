import moment from 'moment/moment';
export const DATA_API_URL = '/api/v1/public-data/data/combine';
export const COL_DEF = [
    {
        key: 'title',
        title: '테이블명',
        width: '55%',
        className: 'txLeft',
    },
    {
        key: 'createdAt',
        title: '생성일',
        render: (data) => moment(data.createdAt).format('YYYY-MM-DD'),
    },
    {
        key: 'updatedAt',
        title: '수정일',
        render: (data) => moment(data.updatedAt).format('YYYY-MM-DD'),
    },
    {
        key: 'status',
        title: '등록 신청 상태',
    },
    {
        key: 'dataApply',
        title: '등록 신청',
        render: (data) => (data.publicYn ? '등록' : '미등록'),
    },
    {
        key: 'dataDelete',
        title: '삭제',
    },
    {
        key: 'dataHistory',
        title: '스케줄 이력',
    },
];
export const CYCLE_MAP = {
    type: {
        every_minute: '분 배치',
        hourly: '시간 배치',
        daily: '일 배치',
        weekly: '주 배치',
        monthly: '월 배치',
    },
    dayOfWeek: {
        mon: '월요일',
        tue: '화요일',
        wed: '수요일',
        thu: '목요일',
        fri: '금요일',
        sat: '토요일',
        sun: '일요일',
    },
};
const STATUS_LIST = [
    { label: '등록 신청', code: 'DATA_REG_REQ_CD' },
    { label: '미등록', code: 'DATA_UN_REG_CD' },
    { label: '승인', code: 'DATA_REG_COM_CD' },
    { label: '거절', code: 'DATA_REG_REJ_CD' },
];
export const STATUS_TYPE = STATUS_LIST.map(({ label, code }) => ({ label, value: code }));
export const STATUS_CODE = Object.fromEntries(STATUS_LIST.map(({ label, code }) => [code, label]));
