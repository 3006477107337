import moment from "moment/moment";
export const COL_DEF = [
    { headerName: '이름', field: 'name' },
    { headerName: '운영체제', field: 'osTypeName' },
    { headerName: '위치', field: 'zoneName' },
    { headerName: '상태', field: 'status' },
    { headerName: '공유설정', field: 'isPublic' },
    { headerName: 'Disk 크기', field: 'size' },
    { headerName: '생성일시',
        field: 'created',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss')
    }
];
