import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Breadcrumb, ConsoleBreadcrumb } from '@/components';
import styles from './pageLayout.module.scss';
const ContentLayout = ({ children }) => {
    return (_jsx("main", Object.assign({ className: styles.container }, { children: _jsx("div", Object.assign({ className: styles.contentWrapper }, { children: children })) })));
};
export const DefaultLayout = ({ breadcrumb, children }) => {
    return (_jsxs(ContentLayout, { children: [_jsx(Breadcrumb, { pathArr: breadcrumb }), _jsx("div", Object.assign({ style: { marginTop: '3rem' } }, { children: children }))] }));
};
export const DetailLayout = ({ breadcrumb, children }) => {
    return (_jsxs(ContentLayout, { children: [_jsx(Breadcrumb, { pathArr: breadcrumb }), children] }));
};
export const FormLayout = ({ breadcrumb, children }) => {
    return (_jsxs(ContentLayout, { children: [_jsx(Breadcrumb, { pathArr: breadcrumb }), _jsx("div", Object.assign({ style: { marginTop: '3rem', height: '60vh' } }, { children: children }))] }));
};
export const ConsolePageLayout = ({ breadcrumb, children }) => {
    return (_jsxs(_Fragment, { children: [breadcrumb && _jsx(ConsoleBreadcrumb, { pathArr: breadcrumb }), _jsx("div", Object.assign({ className: styles.consoleContainer }, { children: children }))] }));
};
export const ConsoleTablePageLayout = ({ breadcrumb, children, }) => {
    return (_jsxs(_Fragment, { children: [breadcrumb && _jsx(ConsoleBreadcrumb, { pathArr: breadcrumb }), _jsx("div", Object.assign({ className: styles.consoleTableLayout }, { children: children }))] }));
};
