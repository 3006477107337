import { io } from 'socket.io-client';
import { useCallback, useEffect, useQuery } from '@/hooks';
import { swal } from '@/utils';
import axiosUtil from '@/utils/axios';
const randomValue = Date.now() + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
export default function useNiceVerification({ onAuthDone, }) {
    useEffect(() => {
        const socket = io(`ws://${window.location.hostname}:80`, {
            reconnectionDelayMax: 10000,
            query: {
                room: randomValue,
            },
        });
        console.log('소켓 확인 : ', socket);
        console.log(window.location.hostname);
        socket.on('auth', function (data) {
            onAuthDone(data);
        });
        return () => {
            socket.disconnect();
        };
    }, []);
    const { data = { sEncData: '' } } = useQuery({
        queryKey: ['/nice/codes'],
        queryFn: () => axiosUtil.get('/nice/codes', {
            params: { room: randomValue },
            baseURL: `https://${window.location.hostname}`,
        }),
    });
    const onClickNiceVerification = useCallback(() => {
        const form = document.createElement('form');
        form.style.display = 'none';
        form.method = 'post';
        form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
        form.target = 'popupChk';
        const inputFields = [
            { name: 'EncodeData', value: data.sEncData },
            { name: 'm', value: 'checkplusService' },
        ];
        inputFields.forEach((field) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = field.name;
            input.value = field.value;
            form.appendChild(input);
        });
        document.body.appendChild(form);
        const popupWindow = window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        // @ts-ignore
        if (popupWindow) {
            form.submit();
        }
        else {
            swal('팝업이 차단되었습니다. 팝업을 허용해주세요.');
        }
    }, [data.sEncData]);
    return {
        onClickNiceVerification,
    };
}
