import moment from 'moment';
export const COL_DEF = [
    { headerName: '이름', field: 'name' },
    { headerName: '버킷 이름', field: 'bucket' },
    { headerName: '상태', field: 'status' },
    {
        headerName: '생성일시',
        field: 'created',
        valueFormatter: (params) => moment(Number(params.value)).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    },
];
