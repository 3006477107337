import moment from 'moment';
export const FaqColDef = [
    {
        field: 'title',
        headerName: '제목',
    },
    {
        field: 'creatorUsername',
        headerName: '작성자',
    },
    {
        field: 'createdAt',
        headerName: '작성일',
        format: (date) => moment(date).add(9, 'h').format('YYYY-MM-DD HH:mm'),
    },
];
