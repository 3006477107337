import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useClickAway, useRef, useState } from '@/hooks';
import ReactDom from 'react-dom';
import styles from './dropdownBtn.module.scss';
export default function DropdownButton(props) {
    const { children, actions } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [btnPosition, setBtnPosition] = useState(null);
    const clickAwayRef = useRef(null);
    useClickAway(clickAwayRef, () => setIsOpen(false));
    const Portal = (props) => {
        const { isOpen, children } = props;
        if (!isOpen)
            return null;
        return ReactDom.createPortal(_jsx("div", Object.assign({ className: styles.dropdownWrapper, style: {
                top: btnPosition.top + btnPosition.height + 3,
                width: btnPosition.width,
                left: btnPosition.left,
            } }, { children: children })), document.getElementById('portal-root'));
    };
    const onClickBtn = (e) => {
        const { top, left, width, height } = e.currentTarget.getBoundingClientRect();
        setIsOpen(true);
        setBtnPosition({ top, left, width, height });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("button", Object.assign({ className: styles.dropdown }, props, { onClick: onClickBtn }, { children: [_jsx("span", Object.assign({ className: styles.btnText }, { children: children })), _jsx("span", { className: styles.divider }), _jsx("span", { className: styles.dropdownBtnIcon })] })), _jsx(Portal, Object.assign({ isOpen: isOpen }, { children: _jsx("div", Object.assign({ ref: clickAwayRef, className: styles.btnsWrapper }, { children: actions.map((action) => {
                        return (_jsx("button", Object.assign({ type: "button", className: styles.btns, onClick: action.onClick }, { children: action.name }), action.name));
                    }) })) }))] }));
}
