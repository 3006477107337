import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Checkbox, Input, RadioButton, useAlertDialog } from '@innogrid-ui/core';
import { useMutation } from '@tanstack/react-query';
import { filter } from 'lodash';
import React, { useMemo, useState } from 'react';
import { processColumnSplitter } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/promise';
import { useDataProcessStore } from '@/stores';
import { validateError } from '@/utils';
export function ColumnSplitter({ column, id, submenu }) {
    const { open } = useAlertDialog();
    const { processResult } = useDataProcessStore((state) => state.processInfo)[id];
    const updateProcessInfo = useDataProcessStore((state) => state.updateProcessInfo);
    const [formData, setFormData] = useState({ options: '', value: '' });
    const isTypeSeperator = formData.options === 'seperator';
    const isTypeLength = formData.options === 'length';
    const { seperatorInfo, lengthInfo } = useMemo(() => {
        const [seperatorInfo] = filter(submenu, ['options', 'seperator']);
        const [lengthInfo] = filter(submenu, ['options', 'length']);
        return { seperatorInfo, lengthInfo };
    }, []);
    const onClickRadio = (e) => {
        const value = e.currentTarget.value;
        setFormData({ options: value, value: '', reverse: value === 'length' ? false : undefined });
    };
    const onChangeInput = (e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { value: e.target.value })));
    };
    const { mutate } = useMutation({
        mutationFn: processColumnSplitter,
        onSuccess: ({ data }) => {
            if (!validateError(data, open)) {
                updateProcessInfo({ data: data.result, actionType: 'increment' });
            }
        },
    });
    const onClick = () => {
        mutate({
            defaultData: { datasetInfo: processResult, column },
            contents: formData,
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "dataBlock" }, { children: [_jsxs("div", { children: [_jsx(RadioButton, { label: seperatorInfo === null || seperatorInfo === void 0 ? void 0 : seperatorInfo.procs_submenu_kor_nm, onClick: onClickRadio, value: seperatorInfo === null || seperatorInfo === void 0 ? void 0 : seperatorInfo.options, variant: "outlined", checked: isTypeSeperator }), _jsx("div", Object.assign({ className: "dataInput" }, { children: _jsx(Input, { id: "seperator", onChange: onChangeInput, value: isTypeSeperator ? formData.value : '', disabled: !isTypeSeperator }) }))] }), _jsxs("div", { children: [_jsx(RadioButton, { label: lengthInfo === null || lengthInfo === void 0 ? void 0 : lengthInfo.procs_submenu_kor_nm, onClick: onClickRadio, value: lengthInfo === null || lengthInfo === void 0 ? void 0 : lengthInfo.options, variant: "outlined", checked: isTypeLength }), _jsxs("div", Object.assign({ className: "dataInput" }, { children: [_jsx(Input, { id: "length", onChange: onChangeInput, style: { width: '111px' }, value: isTypeLength ? formData.value : '', disabled: !isTypeLength }), _jsx("div", Object.assign({ className: "dataChkEct" }, { children: _jsx(Checkbox, { label: "\uC5ED\uBD84\uD560", id: "checkboxId1", layout: "horizontal", onCheckedChange: (checked) => setFormData((prev) => (Object.assign(Object.assign({}, prev), { reverse: checked }))), checked: formData.reverse, disabled: !isTypeLength }) }))] }))] })] })), _jsx("div", Object.assign({ className: "dataBtns" }, { children: _jsx(Button, Object.assign({ onClick: onClick, size: "small" }, { children: "\uC801\uC6A9" })) }))] }));
}
