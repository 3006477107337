// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.omXu6JrKfAYT1wf1wvQ9{height:auto;display:grid;grid-template-columns:repeat(2, 1fr);grid-gap:16px;max-height:100%;overflow-y:auto;flex-wrap:wrap;margin-top:18px}.xsrgSqrPf1t7iLWfOotw{margin-top:50px;text-align:center;color:#a0a0a0;font-size:17px;font-weight:400}`, "",{"version":3,"sources":["webpack://./src/components/frontDataCard/_components/dataCardList.module.scss"],"names":[],"mappings":"AAAA,sBACC,WAAA,CACA,YAAA,CACA,oCAAA,CACA,aAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CAGD,sBACC,eAAA,CACA,iBAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".gallery_list {\r\n\theight: auto;\r\n\tdisplay: grid;\r\n\tgrid-template-columns: repeat(2, 1fr);\r\n\tgrid-gap: 16px;\r\n\tmax-height: 100%;\r\n\toverflow-y: auto;\r\n\tflex-wrap: wrap;\r\n\tmargin-top: 18px;\r\n}\r\n\r\n.noData {\r\n\tmargin-top: 50px;\r\n\ttext-align: center;\r\n\tcolor: #a0a0a0;\r\n\tfont-size: 17px;\r\n\tfont-weight: 400;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery_list": `omXu6JrKfAYT1wf1wvQ9`,
	"noData": `xsrgSqrPf1t7iLWfOotw`
};
export default ___CSS_LOADER_EXPORT___;
