import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Popover } from '@innogrid-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COL_DEF, STATUS_CODE } from '../constant';
import styles from '../ProcessedDataManagementPage.module.scss';
export const RenderProcessedDataColumns = ({ onOpenAlert, onDelete, setHistoryItemId, setApplyItemId, }) => {
    const navigate = useNavigate();
    const renderTitle = (item) => (_jsx("a", Object.assign({ onClick: () => navigate(`/my-data/processed-data-management/${item.id}`, { state: Object.assign({}, item) }), style: { cursor: 'pointer' } }, { children: _jsx("span", { children: item.displayTableName || item.schemaTitle }) })));
    const renderDeleteButton = (item) => (_jsx(Button, Object.assign({ color: "common", mode: "default", onClick: () => onOpenAlert({
            children: '테이블을 삭제하시겠습니까?',
            cancelButtonText: '취소',
            variant: 'common',
            onClickConfirm: () => onDelete(item),
        }), size: "small", variant: "outlined" }, { children: "\uC0AD\uC81C" })));
    const renderHistoryButton = ({ dataType, schemaName }) => {
        return (_jsx(Button, Object.assign({ color: "common", mode: "default", onClick: () => setHistoryItemId(schemaName), size: "small", variant: "outlined", disabled: dataType.toUpperCase() === 'DATASET' }, { children: "\uC774\uB825\uBCF4\uAE30" })));
    };
    const renderApplyButton = ({ schemaName, status }) => {
        return (_jsx(Button, Object.assign({ color: "common", mode: "default", onClick: () => setApplyItemId(schemaName), size: "small", variant: "outlined", disabled: status !== 'DATA_UN_REG_CD' }, { children: "\uC2E0\uCCAD" })));
    };
    const renderStatus = ({ status, reason }) => {
        if (status === 'DATA_REG_REJ_CD' && reason && reason.trim() !== '') {
            return (_jsxs("div", Object.assign({ className: `${styles.statusCell} ${styles.rejected}` }, { children: [_jsx("span", { children: STATUS_CODE[status] }), _jsx(Popover, Object.assign({ side: 'right', className: "popover", iconPath: "", variant: "blue", contentClassName: "popoverContent" }, { children: reason }))] })));
        }
        return _jsx("div", Object.assign({ className: styles.statusText }, { children: STATUS_CODE[status] || '-' }));
    };
    return COL_DEF.map((col) => {
        const customRender = {
            title: renderTitle,
            dataApply: renderApplyButton,
            dataDelete: renderDeleteButton,
            dataHistory: renderHistoryButton,
            status: renderStatus,
        }[col.key];
        return Object.assign(Object.assign({}, col), { render: customRender || col.render || ((item) => item[col.key]) });
    });
};
