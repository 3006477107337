export const COL_DEF = [
    { headerName: 'ID', field: 'routeTableNo', width: 120 },
    { headerName: '이름', field: 'routeTableName' },
    { headerName: 'VPC ID', field: 'vpcNo', width: 120 },
    { headerName: '상태', field: 'routeTableStatus', valueFormatter: (params) => params.value.codeName, width: 120 },
    {
        headerName: '서브넷 지원 유형',
        field: 'supportedSubnetType',
        valueFormatter: (params) => params.value.codeName,
        width: 150,
    },
    { headerName: '메모', field: 'routeTableDescription' },
];
