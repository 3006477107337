import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FrontDataCard } from '@/components';
import { PUBLIC_DATA_BASE_URL } from '@/constants';
import { useFrontTable, useState } from '@/hooks';
import { DefaultLayout } from '@/layouts';
import DataOptionTable from '@/pages/cmp/data/_components/DataOptionTable';
import { DATA_API_URL } from '@/pages/cmp/data/constant';
export default function DataPage() {
    const [searchOptions, setSearchOptions] = useState({});
    const tableProps = useFrontTable({
        apiUrl: DATA_API_URL,
        extraQueryKey: [searchOptions],
        dataSize: (searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.size) || 10,
        apiOptions: {
            baseURL: PUBLIC_DATA_BASE_URL,
            params: Object.assign({}, searchOptions),
        },
    });
    return (_jsxs(DefaultLayout, Object.assign({ breadcrumb: { Home: '/', Data: '' } }, { children: [_jsx(DataOptionTable, { setSearchOptions: setSearchOptions }), _jsx(FrontDataCard, Object.assign({}, tableProps))] })));
}
