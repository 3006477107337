import moment from "moment/moment";
export const COL_DEF = [
    { headerName: '이름', field: 'name' },
    { headerName: '위치', field: 'zoneName' },
    { headerName: '공유', field: 'aclType' },
    { headerName: 'VLAN', field: '' },
    { headerName: 'CIDR', field: 'cidr' },
    { headerName: '생성일시',
        field: 'created',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss')
    }
];
