import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, Select, useAlertDialog } from '@innogrid-ui/core';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useDataTable } from '@/hooks/useDataTable';
import { ConsolePageLayout } from '@/layouts';
import { DataTable } from '@/pages/console/myData/_components/DataTable';
import { DATA_API_URL } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/constant';
import { RenderProcessedDataColumns } from '@/pages/console/myData/processedDataManagement/_components/CellRenderer';
import DataApplyModal from '@/pages/console/myData/processedDataManagement/_components/DataApplyModal';
import { useAuthStore } from '@/stores';
import { requestFn, swal } from '@/utils';
import ScheduleHistoryModal from './_components/ScheduleHistoryModal';
import { COL_DEF, STATUS_TYPE } from './constant';
import styles from './ProcessedDataManagementPage.module.scss';
export default function ProcessedDataManagementPage() {
    const { open } = useAlertDialog();
    const { userSeq } = useAuthStore((state) => state.userInfo);
    const [historyItemId, setHistoryItemId] = useState(null);
    const [applyItemId, setApplyItemId] = useState(null);
    // IF_KBI_00004 스키마 목록 조회
    const { data, isFetching, pagination, searchParams, onChangePageSize, onChangePage, onChangeSearchParams, onSearch, refetch, } = useDataTable({
        apiUrl: `${DATA_API_URL}/${userSeq}/schema/list`,
        options: {
            columns: COL_DEF,
        },
        sortKey: 'updatedAt',
    });
    // IF_KBI_00009 스키마 삭제
    const { mutate: onDelete } = useMutation({
        mutationFn: (data) => requestFn(`${DATA_API_URL}/schema/delete`, {
            method: 'POST',
            params: {
                tableName: data.schemaName,
            },
        }),
        onSuccess: () => {
            swal('테이블이 삭제되었습니다.').then(() => refetch());
        },
    });
    const onKeyDoWn = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };
    const columns = RenderProcessedDataColumns({
        onOpenAlert: open,
        onDelete,
        setHistoryItemId,
        setApplyItemId,
    });
    return (_jsxs(ConsolePageLayout, Object.assign({ breadcrumb: ['My Data', '가공 데이터 관리'] }, { children: [_jsxs("div", Object.assign({ className: styles.boardListHeader }, { children: [_jsxs("div", Object.assign({ className: styles.headerInput }, { children: [_jsxs("div", Object.assign({ className: styles.flexBox }, { children: [_jsx("span", { children: "\uC0DD\uC131\uC77C" }), _jsxs("div", Object.assign({ className: styles.inputDateBox }, { children: [_jsx("input", { type: "date", name: "startDate", value: searchParams.startDate, max: searchParams.endDate, className: styles.startDateInput, onChange: (e) => onChangeSearchParams('startDate', e.target.value) }), _jsx("span", Object.assign({ className: styles.dateSeparator }, { children: " ~ " })), _jsx("input", { type: "date", name: "endDate", min: searchParams.startDate, className: styles.endDateInput, value: searchParams.endDate, onChange: (e) => onChangeSearchParams('endDate', e.target.value) })] }))] })), _jsxs("div", Object.assign({ className: styles.flexBox }, { children: [_jsx("span", { children: "\uB4F1\uB85D \uC2E0\uCCAD \uC0C1\uD0DC" }), _jsx("div", Object.assign({ className: styles.selectBox }, { children: _jsx(Select, { options: [{ label: '전체', value: null }, ...STATUS_TYPE], useEmptyOption: false, placeholder: "\uC804\uCCB4", rootProps: {
                                                onValueChange: (value) => {
                                                    onChangeSearchParams('status', value);
                                                },
                                                value: searchParams.status,
                                            } }) }))] })), _jsxs("div", Object.assign({ className: styles.flexBox }, { children: [_jsx("span", { children: "\uD14C\uC774\uBE14\uBA85" }), _jsx("div", Object.assign({ className: styles.inputBox }, { children: _jsx(Input, { type: "search", value: searchParams.displayTableName, placeholder: "\uD14C\uC774\uBE14\uBA85\uC744 \uC785\uB825\uD574 \uC8FC\uC138\uC694.", onChange: (e) => onChangeSearchParams('displayTableName', e.target.value), onKeyDown: onKeyDoWn }) }))] }))] })), _jsx("button", Object.assign({ type: "button", className: styles.btnSearch, onClick: onSearch }, { children: "\uC870\uD68C" }))] })), _jsxs("div", Object.assign({ className: styles.dataListWrapper }, { children: [_jsx("div", Object.assign({ className: styles.boardCountBox }, { children: _jsxs("div", Object.assign({ className: styles.boardCount }, { children: ["\uC804\uCCB4 \uB370\uC774\uD130", _jsx("strong", { children: data ? pagination.totalElements : 0 }), "\uAC74"] })) })), _jsx(DataTable, { data: data, columns: columns, pagination: pagination, isFetching: isFetching, onPageChange: onChangePage, onPageSizeChange: onChangePageSize })] })), historyItemId && (_jsx(ScheduleHistoryModal, { id: historyItemId, isOpen: !!historyItemId, onRequestClose: () => setHistoryItemId(null) })), applyItemId && (_jsx(DataApplyModal, { id: applyItemId, isOpen: !!applyItemId, refetch: refetch, onRequestClose: () => setApplyItemId(null) }))] })));
}
