export const COL_DEF = [
    { headerName: '이름', field: 'name' },
    { headerName: 'Mount Path', field: 'path' },
    { headerName: '위치', field: '' },
    { headerName: '용량', field: 'totalSize' },
    { headerName: '사용량', field: 'usedSize' },
    { headerName: '타입', field: 'volumeType' },
    { headerName: '프로토콜', field: '' },
    { headerName: '구분', field: '' }
];
