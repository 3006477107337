import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSearchParams } from 'react-router-dom';
import shortid from 'shortid';
import { Button, Input, SelectBox } from '@/components';
import { useCallback, useState } from '@/hooks';
import styles from './DataOptionTable.module.scss';
import { useQuery } from "@tanstack/react-query";
import axiosUtil from "@/utils/axios";
export default function DataOptionTable({ setSearchOptions }) {
    const [, setSearchParams] = useSearchParams();
    const [options, setOptions] = useState({ size: 10 });
    const [clicked, setClicked] = useState([]);
    const { data: catalogData } = useQuery([`/api/v1/public-data/data/all-categories`], () => {
        return axiosUtil({
            url: `/api/v1/public-data/data/all-categories`,
            method: 'GET',
            responseType: 'json'
        });
    }, {
        suspense: true,
    });
    const onClickCategory = useCallback((event) => {
        if (clicked.includes(event.target.name)) {
            const temp = clicked.filter((el) => {
                return el !== event.target.name;
            });
            setClicked([...temp]);
        }
        else {
            setClicked((prev) => { return [...prev, event.target.name]; });
        }
    }, [clicked]);
    const onChangeSize = useCallback(({ value }, { name }) => {
        setOptions((prev) => (Object.assign(Object.assign({}, prev), { [name]: value })));
    }, []);
    const onChangeInput = useCallback((event) => {
        setOptions((prev) => (Object.assign(Object.assign({}, prev), { [event.target.id]: event.target.value })));
    }, []);
    const onClickSearch = useCallback(() => {
        setSearchOptions(Object.assign({ category: [...clicked].toString() }, options));
        setSearchParams();
    }, [options, clicked]);
    const onClickRefresh = useCallback(() => {
        setClicked([]);
        setOptions({ size: 10 });
        onClickSearch();
    }, []);
    return (_jsxs("div", Object.assign({ className: styles.dataOptionTableContainer }, { children: [_jsx("div", Object.assign({ className: styles.filterContainer }, { children: _jsxs("table", { children: [_jsxs("colgroup", { children: [_jsx("col", { width: "220px" }), _jsx("col", {}), _jsx("col", { width: "220px" }), _jsx("col", {})] }), _jsxs("tbody", { children: [catalogData !== undefined
                                    ?
                                        catalogData.map((catalog) => {
                                            return (_jsxs("tr", { children: [_jsx("th", { children: catalog.catalogName }), _jsx("td", Object.assign({ colSpan: 3 }, { children: _jsx("div", Object.assign({ className: styles.dataType }, { children: catalog.categories.map((category) => {
                                                                return (_jsx(Button, Object.assign({ name: category.nodeId, style: { 'width': '12%' }, className: clicked.includes(category.nodeId.toString()) ? styles.active : '', onClick: (e) => {
                                                                        onClickCategory(e);
                                                                    } }, { children: category.title }), shortid.generate()));
                                                            }) })) }))] }, shortid.generate()));
                                        })
                                    :
                                        null, _jsxs("tr", { children: [_jsx("th", { children: "\uD0A4\uC6CC\uB4DC" }), _jsx("td", { children: _jsx("div", Object.assign({ className: styles.keywordInput }, { children: _jsx(Input, { id: "searchWord", onChange: onChangeInput, value: (options === null || options === void 0 ? void 0 : options.searchWord) || '', onKeyUp: (e) => e.key === 'Enter' && onClickSearch() }) })) }), _jsx("th", { children: "\uACB0\uACFC \uD45C\uC2DC \uAC1C\uC218" }), _jsx("td", { children: _jsx(SelectBox, { name: "size", options: [
                                                    { label: '10개', value: 10 },
                                                    { label: '20개', value: 20 },
                                                    { label: '50개', value: 50 },
                                                ], onChange: onChangeSize, value: options.size }) })] })] })] }) })), _jsxs("div", Object.assign({ className: styles.searchConditionContainer }, { children: [_jsx("div", Object.assign({ className: styles.refresh, onClick: onClickRefresh }, { children: "\uC804\uCCB4 \uD574\uC81C" })), _jsx("div", Object.assign({ className: styles.buttonWrapper }, { children: _jsx(Button, Object.assign({ onClick: onClickSearch }, { children: "\uAC80\uC0C9" })) }))] }))] })));
}
