import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAlertDialog } from '@innogrid-ui/core';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Loading } from '@/components';
import { useAuthStore, useDataProcessStore } from '@/stores';
import { validateError } from '@/utils';
import axiosUtil from '@/utils/axios';
import { DATA_API_URL } from './constant';
import styles from './DataCreate.module.scss';
export function DataCreate() {
    var _a, _b, _c, _d, _e;
    const { open } = useAlertDialog();
    const { userSeq } = useAuthStore((state) => state.userInfo);
    const id = useDataProcessStore((state) => state.id);
    const datasetId = (_c = (_b = (_a = useDataProcessStore((state) => state.processInfo)) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.processResult) === null || _c === void 0 ? void 0 : _c.datasetId;
    // IF_KBI_00014 (스키마 데이터 단건 조회)
    const { data: schemaData, isError: isSchemaDataError } = useQuery({
        queryKey: [`${DATA_API_URL}/${userSeq}/schemaDataInfo`, { tableName: datasetId, transferedDataYn: false }],
        queryFn: ({ queryKey }) => axiosUtil({ url: queryKey[0], method: 'GET', params: queryKey[1] }),
        enabled: !!userSeq,
    });
    // IF_KBI_00008 스키마 상세 설정 정보 조회
    const { data, isSuccess, isError, isFetching } = useQuery({
        queryKey: [
            `${DATA_API_URL}/${userSeq}/schema`,
            { tableName: (_d = schemaData === null || schemaData === void 0 ? void 0 : schemaData.result) === null || _d === void 0 ? void 0 : _d.schemaName, transferedDataYn: false },
        ],
        queryFn: ({ queryKey }) => axiosUtil({ url: queryKey[0], method: 'GET', params: queryKey[1] }),
        enabled: !!userSeq && !!((_e = schemaData === null || schemaData === void 0 ? void 0 : schemaData.result) === null || _e === void 0 ? void 0 : _e.schemaName),
    });
    useEffect(() => {
        if (isSuccess && validateError(data, open))
            return;
    }, [isSuccess]);
    const renderTableBody = () => {
        var _a;
        if ((isSuccess && isEmpty(data === null || data === void 0 ? void 0 : data.result)) || isSchemaDataError || isError) {
            return (_jsx("tr", Object.assign({ className: styles.noData }, { children: _jsx("td", Object.assign({ colSpan: 4 }, { children: "\uB370\uC774\uD130\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4." })) })));
        }
        return (_a = data === null || data === void 0 ? void 0 : data.result) === null || _a === void 0 ? void 0 : _a.map(({ columnName, dataType, nullable, columnDefault }, index) => (_jsxs("tr", { children: [_jsx("td", Object.assign({ className: styles.txLeft }, { children: columnName ? columnName.toUpperCase() : '-' })), _jsx("td", Object.assign({ className: styles.txLeft }, { children: dataType })), _jsx("td", Object.assign({ className: styles.txLeft }, { children: nullable ? 'N' : 'Y' })), _jsx("td", Object.assign({ className: styles.txLeft, title: columnDefault }, { children: columnDefault || '-' }))] }, `${columnName}-${index}`)));
    };
    return (_jsxs("div", { children: [_jsx("p", Object.assign({ className: styles.title }, { children: "\uD14C\uC774\uBE14 \uC815\uBCF4" })), _jsx("div", Object.assign({ className: styles.tableBox }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ style: { width: '25%' } }, { children: "\uCEEC\uB7FC\uBA85" })), _jsx("th", Object.assign({ style: { width: '25%' } }, { children: "\uD0C0\uC785" })), _jsx("th", Object.assign({ style: { width: '10%' } }, { children: "Not Null \uC5EC\uBD80" })), _jsx("th", Object.assign({ style: { width: '40%' } }, { children: "Default \uAC12" }))] }) }), _jsx("tbody", { children: isFetching ? _jsx(Loading, { color: "#268eff" }) : renderTableBody() })] }) }))] }));
}
