export const COL_DEF = [
    {
        key: 'created',
        title: '실행시간',
    },
    {
        key: 'status',
        title: '상태',
    },
    {
        key: 'total_count',
        title: '저장 건수',
        render: ({ total_count }) => {
            return total_count === 0 ? '-' : `${total_count}`;
        },
    },
];
