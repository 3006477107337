var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from '@/hooks';
import { useCommonStore } from '@/stores';
import axiosUtil from '@/utils/axios';
export const useClientPagingTable = (props) => {
    const { queryKey, queryFnOptions } = props, options = __rest(props, ["queryKey", "queryFnOptions"]);
    const setGlobalTableSize = useCommonStore((state) => state.setTableSize);
    const tableRef = useRef(null);
    const [currPage, setCurrPage] = useState(0);
    const [totPage, setTotPage] = useState(0);
    const { data: rowData, isError, isSuccess, isFetching, } = useQuery(Object.assign(Object.assign({}, options), { queryKey, queryFn: ({ queryKey }) => {
            var _a, _b;
            (_b = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.showLoadingOverlay();
            return axiosUtil.get(queryKey[0], Object.assign(Object.assign({}, queryFnOptions), { method: 'get' }));
        }, keepPreviousData: true }));
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (isFetching)
            (_b = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.showLoadingOverlay();
        else if (!isError && rowData === undefined)
            (_d = (_c = tableRef.current) === null || _c === void 0 ? void 0 : _c.api) === null || _d === void 0 ? void 0 : _d.showNoRowsOverlay();
        else if (isSuccess && isEmpty(rowData))
            (_f = (_e = tableRef.current) === null || _e === void 0 ? void 0 : _e.api) === null || _f === void 0 ? void 0 : _f.showNoRowsOverlay();
        else {
            (_h = (_g = tableRef.current) === null || _g === void 0 ? void 0 : _g.api) === null || _h === void 0 ? void 0 : _h.hideOverlay();
            (_k = (_j = tableRef.current) === null || _j === void 0 ? void 0 : _j.api) === null || _k === void 0 ? void 0 : _k.sizeColumnsToFit();
        }
    }, [rowData, isError, isSuccess, isFetching]);
    const onClickPreviousPage = useCallback(() => {
        var _a, _b;
        (_b = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.paginationGoToPreviousPage();
    }, []);
    const onClickNextPage = useCallback(() => {
        var _a, _b;
        (_b = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.paginationGoToNextPage();
    }, []);
    const onGridReady = useCallback((event) => {
        event.api.sizeColumnsToFit();
    }, []);
    const onGridSizeChanged = useCallback((event) => {
        event.api.sizeColumnsToFit();
    }, []);
    const onChangePageSize = useCallback((event) => {
        setGlobalTableSize(parseInt(event.target.value));
    }, [setGlobalTableSize]);
    const onPaginationChanged = useCallback((event) => {
        setCurrPage(event.api.paginationGetTotalPages() === 0 ? 0 : event.api.paginationGetCurrentPage() + 1);
        setTotPage(event.api.paginationGetTotalPages());
        event.api.sizeColumnsToFit();
    }, []);
    return {
        rowData,
        queryKey,
        tableRef,
        currPage,
        totPage,
        getClickedRow: () => { var _a; return (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.api.getSelectedRows()[0]; },
        onClickPreviousPage,
        onClickNextPage,
        onGridReady,
        onGridSizeChanged,
        onChangePageSize,
        onPaginationChanged,
    };
};
