// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.K2DrshRFhO1K3zr4m00y{position:relative;display:inline-flex;justify-content:center;align-items:center;width:28px;height:28px;border-radius:3px;border:0;background:rgba(0,0,0,0);cursor:pointer}.K2DrshRFhO1K3zr4m00y .EAA0Sc1rAQNBxp3XDGg2{position:absolute;top:50%;left:50%;width:21px;height:20px;margin:-10px 0 0 -10px;background:no-repeat center top}.K2DrshRFhO1K3zr4m00y:hover:not(:disabled) .EAA0Sc1rAQNBxp3XDGg2{background-position-y:-20px}.K2DrshRFhO1K3zr4m00y:disabled .EAA0Sc1rAQNBxp3XDGg2{opacity:.5}`, "",{"version":3,"sources":["webpack://./src/components/button/icon/iconBtn.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,mBAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,QAAA,CACA,wBAAA,CACA,cAAA,CAEA,4CACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,sBAAA,CACA,+BAAA,CAIA,iEACE,2BAAA,CAKF,qDACE,UAAA","sourcesContent":[".btnIcon {\r\n  position: relative;\r\n  display: inline-flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  width: 28px;\r\n  height: 28px;\r\n  border-radius: 3px;\r\n  border: 0;\r\n  background: transparent;\r\n  cursor: pointer;\r\n\r\n  .icon {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    width: 21px;\r\n    height: 20px;\r\n    margin: -10px 0 0 -10px;\r\n    background: no-repeat center top;\r\n  }\r\n\r\n  &:hover:not(:disabled) {\r\n    .icon {\r\n      background-position-y: -20px;\r\n    }\r\n  }\r\n\r\n  &:disabled {\r\n    .icon {\r\n      opacity: 0.5;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnIcon": `K2DrshRFhO1K3zr4m00y`,
	"icon": `EAA0Sc1rAQNBxp3XDGg2`
};
export default ___CSS_LOADER_EXPORT___;
