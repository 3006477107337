export const FORM_VALIDATE = {
    title: {
        notNull: true,
        length: 500,
        fieldName: '제목',
    },
    contents: {
        notNull: true,
        fieldName: '내용',
    },
    qnaStatus: {
        notNull: true,
        match: ['PRIVATE', 'PUBLIC'],
        fieldName: '공개여부',
    },
};
