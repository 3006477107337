// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ESiNJxmwhBbPfovZWSbQ li{position:relative}.ESiNJxmwhBbPfovZWSbQ li:not(:first-of-type){margin-top:20px}.ESiNJxmwhBbPfovZWSbQ input{width:100%;height:2.75rem;outline:none;border:1px solid #d9d9d9;border-radius:4px;padding-left:10px;background-color:#fff;color:#555;font-size:.875rem}.ESiNJxmwhBbPfovZWSbQ input::placeholder{color:#a9a9a9}.udjaBaM4dOnO1B5VvVPZ{display:block;margin-bottom:8px;font-size:15px}`, "",{"version":3,"sources":["webpack://./src/pages/console/myService/detail/_components/EditAppNameModal.module.scss"],"names":[],"mappings":"AACE,yBACE,iBAAA,CAEF,6CACE,eAAA,CAGF,4BACE,UAAA,CACA,cAAA,CACA,YAAA,CACA,wBAAA,CACA,iBAAA,CACA,iBAAA,CACA,qBAAA,CACA,UAAA,CACA,iBAAA,CAGF,yCACE,aAAA,CAIJ,sBACE,aAAA,CACA,iBAAA,CACA,cAAA","sourcesContent":[".listForm {\r\n  li {\r\n    position: relative;\r\n  }\r\n  li:not(:first-of-type) {\r\n    margin-top: 20px;\r\n  }\r\n\r\n  input {\r\n    width: 100%;\r\n    height: 2.75rem;\r\n    outline: none;\r\n    border: 1px solid #d9d9d9;\r\n    border-radius: 4px;\r\n    padding-left: 10px;\r\n    background-color: #fff;\r\n    color: #555;\r\n    font-size: 0.875rem;\r\n  }\r\n\r\n  input::placeholder {\r\n    color: #A9A9A9;\r\n  }\r\n}\r\n\r\n.labelTitle {\r\n  display: block;\r\n  margin-bottom: 8px;\r\n  font-size: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listForm": `ESiNJxmwhBbPfovZWSbQ`,
	"labelTitle": `udjaBaM4dOnO1B5VvVPZ`
};
export default ___CSS_LOADER_EXPORT___;
