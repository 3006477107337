import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Scrollbar } from '@/components';
import { ConsoleMenu } from '@/constants';
import { useLocation } from '@/hooks';
import { useCommonStore } from '@/stores';
import NavMenuList from './_components/NavMenuList';
import styles from './navMenu.module.scss';
export default function NavMenu() {
    const currentPath = useLocation().pathname.split('/')[1];
    const { isClose } = useCommonStore();
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: `${styles.sideNavRoot} ${currentPath}` }, { children: _jsx("div", Object.assign({ className: `${styles.sideNavMenu} ${isClose ? styles.closed : styles.opened}` }, { children: _jsx(Scrollbar, Object.assign({ className: "scrollbar__nav" }, { children: _jsx(NavMenuList, { menuList: ConsoleMenu }) })) })) })) }));
}
