import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from '@innogrid-ui/core';
import React from 'react';
import { DataTable } from '@/pages/console/myData/_components/DataTable';
import { DATA_API_URL } from '@/pages/console/myData/processedDataManagement/constant';
import { COL_DEF } from './constant';
import { useJobHistoryTable } from './useJobHistoryTable';
export default function JobHistoryModal({ id, isOpen, onRequestClose }) {
    const { data, currentPage, totalPage, isFetching, onChangePage } = useJobHistoryTable({
        apiUrl: `${DATA_API_URL}/job/history`,
        datasetId: id,
    });
    return (_jsx(Modal, Object.assign({ title: "\uC774\uB825 \uBCF4\uAE30", isOpen: isOpen, size: "medium", onRequestClose: onRequestClose }, { children: _jsx(DataTable, { data: data, columns: COL_DEF, pagination: {
                currentPage,
                totalPage,
            }, isFetching: isFetching, onPageChange: onChangePage, isShowPagingSelectBox: false, onPageSizeChange: () => { } }) })));
}
