import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, Textarea } from '@/components';
import { useCallback, useEffect, useMutation, useQuery, useQueryClient, useState } from '@/hooks';
import Modal from '@/pages/console/_components/Modal';
import { API_URL, FORM_VALIDATE } from '@/pages/console/myData/requestData/constant';
import { isFormValidate, requestFn } from '@/utils';
import axiosUtil from '@/utils/axios';
import styles from './RequestDataModal.module.scss';
export default function RequestDataModal({ isCreate, seq, isOpen, setIsOpen }) {
    const queryClient = useQueryClient();
    const [formData, setFormData] = useState({
        title: '',
        orgKorName: '',
        homepageUrl: '',
        requestDescription: '',
    });
    const { data } = useQuery({
        queryKey: [`${API_URL}/${seq}/detail`],
        queryFn: ({ queryKey }) => axiosUtil({
            url: queryKey[0],
            method: 'post',
        }),
        select: (data) => data.data,
        enabled: !!seq && !isCreate,
    });
    useEffect(() => {
        if (data) {
            setFormData({
                title: data.title,
                orgKorName: data.orgKorName,
                homepageUrl: data.homepageUrl,
                requestDescription: data.requestDescription,
            });
        }
    }, [data]);
    const { mutate } = useMutation({
        mutationFn: () => requestFn(isCreate ? API_URL : `${API_URL}/${seq}/update`, {
            method: isCreate ? 'POST' : 'PUT',
            data: Object.assign({}, formData),
        }),
        onSuccess: () => {
            setIsOpen(false);
            queryClient.invalidateQueries([API_URL + '/list']);
        },
    });
    const onClickSubmit = useCallback(() => {
        if (!isFormValidate(formData, FORM_VALIDATE))
            return;
        mutate();
    }, [formData]);
    const onChangeInput = useCallback((event) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { [event.target.id]: event.target.value })));
    }, []);
    return (_jsx(Modal, Object.assign({ title: isCreate ? '신규 데이터 등록 요청' : '등록 요청 수정', isOpen: isOpen, setIsOpen: setIsOpen, onClickSubmit: onClickSubmit, width: "600px" }, { children: _jsxs("div", Object.assign({ className: styles.formContainer }, { children: [_jsxs("div", { children: [_jsx("label", Object.assign({ htmlFor: "title" }, { children: "\uC81C\uBAA9" })), _jsx(Input, { id: "title", placeholder: "\uC694\uCCAD \uC81C\uBAA9\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694", onChange: onChangeInput, value: formData.title })] }), _jsxs("div", { children: [_jsx("label", Object.assign({ htmlFor: "orgKorName" }, { children: "\uAE30\uAD00\uBA85" })), _jsx(Input, { id: "orgKorName", placeholder: "\uB370\uC774\uD130 \uC18C\uC720 \uAE30\uAD00\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694", onChange: onChangeInput, value: formData.orgKorName })] }), _jsxs("div", { children: [_jsx("label", Object.assign({ htmlFor: "homepageUrl" }, { children: "\uD648\uD398\uC774\uC9C0 URL" })), _jsx(Input, { id: "homepageUrl", placeholder: "\uB370\uC774\uD130\uC758 \uC815\uBCF4\uB97C \uD655\uC778\uD560 \uC218 \uC788\uB294 \uC8FC\uC18C\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694", onChange: onChangeInput, value: formData.homepageUrl })] }), _jsxs("div", { children: [_jsx("label", Object.assign({ htmlFor: "requestDescription" }, { children: "\uC694\uCCAD \uBAA9\uC801" })), _jsx(Textarea, { id: "requestDescription", placeholder: "\uC694\uCCAD \uBAA9\uC801\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694", onChange: onChangeInput, value: formData.requestDescription })] })] })) })));
}
