import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@tanstack/react-query';
import { isEmpty, omit } from 'lodash';
import React from 'react';
import { Input } from '@/components';
import { useCallback, useMemo, useState } from '@/hooks';
import { CHANGE_PASSWORD_FORM_VALIDATE, PASSWORD_API_URL } from '@/pages/console/myInfo/constant';
import { useAuthStore } from '@/stores';
import { isFormValidate, requestFn, swal } from '@/utils';
import Modal from '../../_components/Modal';
import styles from './ChangePasswordModal.module.scss';
export default function ChangePasswordModal({ isOpen, setIsOpen }) {
    const userInfo = useAuthStore((state) => state.userInfo);
    const [data, setData] = useState({
        beforePassword: '',
        password: '',
        reEnterPassword: '',
    });
    const { mutate: checkEnabledMutate } = useMutation({
        mutationFn: () => requestFn(`${PASSWORD_API_URL}/finds/password`, {
            data: { userId: userInfo.userId, username: userInfo.username, phone: userInfo.phone },
            method: 'post',
        }),
        onSuccess: (res) => {
            if (res.data.content)
                changePasswordMutate();
            else
                swal('비밀번호를 변경할 수 없는 계정입니다.');
        },
    });
    const { mutate: changePasswordMutate } = useMutation({
        mutationFn: () => requestFn(`${PASSWORD_API_URL}/password/change`, {
            data: Object.assign({ userId: userInfo.userId }, omit(Object.assign({}, data), 'reEnterPassword')),
            method: 'post',
        }),
        onSuccess: () => setIsOpen(false),
    });
    const onClickSubmit = useCallback(() => {
        if (!isFormValidate(data, CHANGE_PASSWORD_FORM_VALIDATE))
            return;
        if (data.beforePassword === data.password)
            return swal('비밀번호가 이전과 동일합니다. 다른 비밀번호를 입력해주세요.');
        if (data.password !== data.reEnterPassword)
            return swal('새 비밀번호와 재입력한 비밀번호가 일치하지 않습니다.');
        checkEnabledMutate();
    }, [data]);
    const onChangeInput = useCallback((event) => {
        setData((prev) => (Object.assign(Object.assign({}, prev), { [event.target.id]: event.target.value })));
    }, []);
    const isCheckSamePassword = useMemo(() => {
        if (isEmpty(data.password) || isEmpty(data.reEnterPassword))
            return false;
        return data.password === data.reEnterPassword;
    }, [data.password, data.reEnterPassword]);
    return (_jsx(Modal, Object.assign({ title: "\uBE44\uBC00\uBC88\uD638 \uBCC0\uACBD", isOpen: isOpen, setIsOpen: setIsOpen, onClickSubmit: onClickSubmit }, { children: _jsxs("ul", Object.assign({ className: styles.listForm }, { children: [_jsxs("li", { children: [_jsx("label", Object.assign({ htmlFor: "beforePassword", className: styles.labelTitle }, { children: "\uAE30\uC874 \uBE44\uBC00\uBC88\uD638" })), _jsx(Input, { id: "beforePassword", type: "password", placeholder: "\uBE44\uBC00\uBC88\uD638\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.", autoComplete: "new-password", value: data.beforePassword, onChange: onChangeInput })] }), _jsxs("li", { children: [_jsx("label", Object.assign({ htmlFor: "password", className: styles.labelTitle }, { children: "\uC0C8 \uBE44\uBC00\uBC88\uD638" })), _jsx("small", Object.assign({ className: styles.smallMsg }, { children: "\uC601\uBB38,\uC22B\uC790,\uD2B9\uC218\uBB38\uC790\uD3EC\uD568 8~12\uC790\uC774\uB0B4" })), _jsx(Input, { className: isCheckSamePassword ? styles.confirm : styles.error, id: "password", type: "password", placeholder: "\uC0C8\uB85C\uC6B4 \uBE44\uBC00\uBC88\uD638\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.", autoComplete: "new-password", value: data.password, onChange: onChangeInput })] }), _jsxs("li", { children: [_jsx("label", Object.assign({ htmlFor: "reEnterPassword", className: styles.labelTitle }, { children: "\uC0C8 \uBE44\uBC00\uBC88\uD638 \uD655\uC778" })), _jsx(Input, { className: isCheckSamePassword ? styles.confirm : styles.error, id: "reEnterPassword", type: "password", placeholder: "\uC0C8 \uBE44\uBC00\uBC88\uD638\uB97C \uC7AC\uC785\uB825\uD574\uC8FC\uC138\uC694.", autoComplete: "new-password", value: data.reEnterPassword, onChange: onChangeInput })] })] })) })));
}
