import moment from 'moment/moment';
import { formatBytes } from '@/utils';
export const COL_DEF = [
    { headerName: '이름', field: 'blockStorageName' },
    {
        headerName: '종류',
        field: 'blockStorageVolumeType',
        valueFormatter: (params) => params.value.codeName,
        width: 150,
    },
    { headerName: '크기', field: 'blockStorageSize', valueFormatter: (params) => formatBytes(params.value), width: 150 },
    { headerName: 'Zone', field: 'zoneCode', width: 150 },
    { headerName: '상태', field: 'blockStorageInstanceStatusName', width: 150 },
    { headerName: '연결정보', field: 'deviceName', valueFormatter: (params) => params.value || '-' },
    {
        headerName: '생성일시',
        field: 'createDate',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    },
];
