import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Select, useAlertDialog } from '@innogrid-ui/core';
import { useMutation } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { memo, useMemo, useState } from 'react';
import { TEMPERATURE_LABEL } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/constant';
import { processUnitConversion } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/promise';
import { useDataProcessStore } from '@/stores';
import { validateError } from '@/utils';
const Component = ({ column, id, submenu }) => {
    const { open } = useAlertDialog();
    const { processResult } = useDataProcessStore((state) => state.processInfo)[id];
    const updateProcessInfo = useDataProcessStore((state) => state.updateProcessInfo);
    const [formData, setFormData] = useState({
        column_name: '',
        options: '',
        unit: '',
        target_unit: '',
    });
    const optionList = useMemo(() => {
        return submenu.reduce((acc, curr) => {
            var _a;
            if (curr.procs_kor_nm) {
                (_a = curr.options) === null || _a === void 0 ? void 0 : _a.forEach((option) => { var _a; return acc.push({ label: ((_a = curr.options_kor_map) === null || _a === void 0 ? void 0 : _a[option]) || '', value: option }); });
            }
            return acc;
        }, []);
    }, []);
    const { unitList, targetUnitList } = useMemo(() => {
        return submenu.reduce((acc, curr) => {
            if (curr.unit) {
                const target = curr.unit[formData.options];
                target === null || target === void 0 ? void 0 : target.forEach((el) => {
                    acc.unitList.push({
                        label: formData.options === 'temperature' ? TEMPERATURE_LABEL[el] : el,
                        value: el,
                    });
                });
            }
            if (curr.target_unit) {
                const target = curr.target_unit[formData.options];
                target === null || target === void 0 ? void 0 : target.forEach((el) => {
                    acc.targetUnitList.push({
                        label: formData.options === 'temperature' ? TEMPERATURE_LABEL[el] : el,
                        value: el,
                    });
                });
            }
            return acc;
        }, {
            unitList: [],
            targetUnitList: [],
        });
    }, [formData.options]);
    const onChange = (id, value) => {
        var _a;
        if (id === 'options') {
            const column_name = ((_a = optionList.find((option) => option.value === value)) === null || _a === void 0 ? void 0 : _a.label) || '';
            setFormData({ options: value, column_name, unit: '', target_unit: '' });
        }
        else {
            setFormData((prev) => (Object.assign(Object.assign({}, prev), { [id]: value })));
        }
    };
    const { mutate } = useMutation({
        mutationFn: processUnitConversion,
        onSuccess: ({ data }) => {
            if (!validateError(data, open)) {
                updateProcessInfo({ data: data.result, actionType: 'increment' });
            }
        },
    });
    const onClick = () => {
        if (!formData.options)
            return open({ children: '단위를 선택해 주세요.', variant: 'common' });
        mutate({
            defaultData: {
                datasetInfo: processResult,
                column,
            },
            contents: formData,
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "dataBlock" }, { children: [_jsxs("div", { children: [_jsx("p", { children: "\uB370\uC774\uD130 \uD3EC\uB9F7 \uC785\uB825" }), _jsx("div", Object.assign({ className: "dataSelect" }, { children: _jsx(Select, { placeholder: "\uC120\uD0DD", options: optionList, rootProps: { value: formData.options, onValueChange: (value) => onChange('options', value) }, useEmptyOption: false }) }))] }), _jsxs("div", { children: [_jsx("p", { children: "\uD604\uC7AC \uB2E8\uC704" }), _jsx("div", Object.assign({ className: "dataSelect" }, { children: _jsx(Select, { placeholder: "\uC120\uD0DD", options: unitList || [], rootProps: {
                                        value: formData.unit,
                                        onValueChange: (value) => onChange('unit', value),
                                        disabled: isEmpty(formData.options),
                                    }, useEmptyOption: false }) }))] }), _jsxs("div", { children: [_jsx("p", { children: "\uBCC0\uD658\uD560 \uB2E8\uC704" }), _jsx("div", Object.assign({ className: "dataSelect" }, { children: _jsx(Select, { placeholder: "\uC120\uD0DD", options: targetUnitList || [], rootProps: {
                                        value: formData.target_unit,
                                        onValueChange: (value) => onChange('target_unit', value),
                                        disabled: isEmpty(formData.options),
                                    }, useEmptyOption: false }) }))] })] })), _jsx("div", Object.assign({ className: "dataBtns" }, { children: _jsx(Button, Object.assign({ onClick: onClick, size: "small" }, { children: "\uC801\uC6A9" })) }))] }));
};
export const UnitConversion = memo(Component);
