import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import { ClientPagingTable, SelectBox } from '@/components';
import { useCallback, useClientPagingTable, useEffect, useReactSelect, useState } from '@/hooks';
import { ConsoleTablePageLayout } from '@/layouts';
import { API_URL, COL_DEF } from './constant';
import styles from './NcpNodePoolPage.module.scss';
export default function NcpNodePoolPage() {
    const [cluster, setCluster] = useState('');
    const { clusterNameList } = useReactSelect([
        {
            queryKey: [`${API_URL}/names`],
            key: 'clusterNameList',
            select: (data) => (data === null || data === void 0 ? void 0 : data.clusters) &&
                data.clusters.map((d) => ({
                    label: d.name,
                    value: d.uuid,
                })),
            labelValue: ['name', 'uuid'],
            noContentKey: true,
        },
    ]);
    const tableProps = useClientPagingTable({
        queryKey: [`${API_URL}/${cluster}/node-pool`],
        select: (data) => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.getBlockStorageInstanceListResponse) === null || _a === void 0 ? void 0 : _a.blockStorageInstanceList; },
        enabled: !!cluster,
    });
    useEffect(() => {
        var _a, _b, _c;
        if (isEmpty(clusterNameList.options))
            (_b = (_a = tableProps.tableRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.showNoRowsOverlay();
        else if (isEmpty(cluster) && clusterNameList.options)
            setCluster(((_c = clusterNameList === null || clusterNameList === void 0 ? void 0 : clusterNameList.options[0]) === null || _c === void 0 ? void 0 : _c.value) || '');
    }, [clusterNameList]);
    const onChangeSelect = useCallback(({ value }) => {
        setCluster(value);
    }, []);
    return (_jsx(_Fragment, { children: _jsx(ConsoleTablePageLayout, Object.assign({ breadcrumb: ['CSP 자원조회', 'NCP', 'Kubernetes Service', 'Node Pool'] }, { children: _jsx(ClientPagingTable, Object.assign({}, tableProps, { height: "100%", columnDefs: COL_DEF }, { children: _jsxs("div", Object.assign({ className: styles.selectWrapper }, { children: [_jsx("span", { children: "\uD074\uB7EC\uC2A4\uD130 \uC774\uB984" }), _jsx(SelectBox, Object.assign({}, clusterNameList, { className: styles.selectBox, value: cluster, onChange: onChangeSelect }))] })) })) })) }));
}
