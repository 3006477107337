// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l4x48nOkaOsBO75xkILa{display:flex}.kDl48bW8qyel0H3hdsb1{width:300px;height:100%}.lDIKjlhSdbCqoNhV9yGA{width:160px;height:100%;margin-top:40px}.lDIKjlhSdbCqoNhV9yGA .Qk_39FUCPm9q8kWk7Lks{font-size:18px;padding-top:20px;padding-bottom:20px;box-sizing:border-box;border-bottom:3px solid rgba(0,0,0,0)}.lDIKjlhSdbCqoNhV9yGA .Qk_39FUCPm9q8kWk7Lks.vxVafXO_h6baLRqFOLrs,.lDIKjlhSdbCqoNhV9yGA .Qk_39FUCPm9q8kWk7Lks:hover{color:#ff6701;border-color:#ff6701}.PvowDrf6lKYmPoLE_AA8{margin-top:40px;width:822px}`, "",{"version":3,"sources":["webpack://./src/pages/term/TermPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,WAAA,CACA,WAAA,CAGF,sBACE,WAAA,CACA,WAAA,CACA,eAAA,CAEA,4CACE,cAAA,CACA,gBAAA,CACA,mBAAA,CACA,qBAAA,CAEA,qCAAA,CAEA,mHACE,aAAA,CACA,oBAAA,CASN,sBACE,eAAA,CACA,WAAA","sourcesContent":[".container {\r\n  display: flex;\r\n}\r\n\r\n.sideContainer {\r\n  width: 300px;\r\n  height: 100%;\r\n}\r\n\r\n.nav {\r\n  width: 160px;\r\n  height: 100%;\r\n  margin-top: 40px;\r\n\r\n  .navItem {\r\n    font-size: 18px;\r\n    padding-top: 20px;\r\n    padding-bottom: 20px;\r\n    box-sizing: border-box;\r\n\r\n    border-bottom: 3px solid transparent;\r\n\r\n    &.selected {\r\n      color: #ff6701;\r\n      border-color: #ff6701;\r\n    }\r\n\r\n    &:hover {\r\n      @extend .selected;\r\n    }\r\n  }\r\n}\r\n\r\n.termContainer {\r\n  margin-top: 40px;\r\n  width: 822px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `l4x48nOkaOsBO75xkILa`,
	"sideContainer": `kDl48bW8qyel0H3hdsb1`,
	"nav": `lDIKjlhSdbCqoNhV9yGA`,
	"navItem": `Qk_39FUCPm9q8kWk7Lks`,
	"selected": `vxVafXO_h6baLRqFOLrs`,
	"termContainer": `PvowDrf6lKYmPoLE_AA8`
};
export default ___CSS_LOADER_EXPORT___;
