var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import axiosUtil from '@/utils/axios';
import styles from './dataCardItem.module.scss';
import shortid from "shortid";
export default function DataCardItem(props) {
    const { row, apiUrl, onNavigateRow } = props;
    const { data: imageData = null } = useQuery([`${apiUrl}/${row.no}`], () => __awaiter(this, void 0, void 0, function* () {
        return yield axiosUtil({
            url: `${apiUrl}/download/${row.no}`,
            method: 'GET',
            responseType: 'blob',
        });
    }), {
        select: (response) => window.URL.createObjectURL(response),
        enabled: !!row && !!row.files && row.files.length > 0,
    });
    return (_jsxs("article", Object.assign({ className: styles.gallery_item }, { children: [_jsx("div", Object.assign({ className: styles.title, onClick: () => onNavigateRow(row.id) }, { children: row.title })), _jsx("div", Object.assign({ className: styles.explain }, { children: row.description })), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsxs("div", { children: [_jsx("span", { children: "\uCE74\uD14C\uACE0\uB9AC : " }), row.category.map((el, index) => {
                                return index + 1 !== row.category.length ? _jsxs("span", { children: [el.title, ", "] }, shortid.generate()) : _jsx("span", { children: el.title }, shortid.generate());
                            })] }), _jsxs("div", { children: [_jsx("span", { children: "\uC81C\uACF5\uAE30\uAD00 : " }), _jsx("span", { children: row.agencyName })] }), _jsxs("div", { children: [_jsx("span", { children: "\uB4F1\uB85D\uC77C : " }), _jsx("span", Object.assign({ style: { marginRight: '10px' } }, { children: moment(row.createAt).format('YYYY-MM-DD') })), _jsx("span", Object.assign({ style: { marginRight: '10px' } }, { children: "/" })), _jsx("span", { children: "\uAC31\uC2E0\uC77C : " }), _jsx("span", { children: row.updatedAt !== null ? moment(row.updatedAt).format('YYYY-MM-DD') : '없음' })] })] }))] })));
}
