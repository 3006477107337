import '@/styles/swal-custom.scss';
import Swal from 'sweetalert2';
export const swal = (text) => {
    return Swal.fire({
        text,
        confirmButtonText: '확인',
    });
};
export const swalHtml = (text) => {
    return Swal.fire({
        html: text,
        confirmButtonText: '확인',
    });
};
export const swalConfirm = (text) => {
    return Swal.fire({
        html: text,
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
    });
};
