import produce from 'immer';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { useAuthStore } from '@/stores';
const initialState = {
    id: '',
    processInfo: {},
    stepState: {},
    fusionGroups: {},
    isPrivacy: false,
};
const createInitialStepState = () => ({
    currentStep: 0,
});
const useDataProcessStore = create()(devtools(persist((set, get) => (Object.assign(Object.assign({}, initialState), { setId: (id) => set({ id }), setInitDataset: (id, data) => {
        set(produce((state) => {
            const existingInfo = state.processInfo[id];
            state.processInfo[id] = Object.assign(Object.assign({}, existingInfo), { processResult: Object.assign(Object.assign({}, existingInfo === null || existingInfo === void 0 ? void 0 : existingInfo.processResult), data) });
            state.stepState[id] = state.stepState[id] || createInitialStepState();
        }));
    }, setInitFusionData: (items) => {
        const dataIds = items.map((item) => item.dataId);
        const groupHash = dataIds.slice().sort().join('-');
        const fusionId = `fusion-${groupHash}`;
        set(produce((state) => {
            var _a;
            const existingGroupId = (_a = Object.entries(state.fusionGroups).find(([, group]) => group.items
                .map((i) => i.dataId)
                .sort()
                .join('-') === groupHash)) === null || _a === void 0 ? void 0 : _a[0];
            const groupKey = existingGroupId || `${useAuthStore.getState().userInfo.userSeq}_${fusionId}`;
            state.fusionGroups[groupKey] = { items };
            state.stepState[groupKey] = state.stepState[groupKey] || createInitialStepState();
        }));
        return fusionId;
    }, updateProcessInfo: ({ data, actionType, count }) => {
        const id = get().id;
        set((state) => produce(state, (draft) => {
            var _a;
            const processInfo = draft.processInfo[id] || {};
            const isExistingCountInfo = (processInfo === null || processInfo === void 0 ? void 0 : processInfo.totalProcessCount) !== undefined && (processInfo === null || processInfo === void 0 ? void 0 : processInfo.currentProcessStep) !== undefined;
            if (!isExistingCountInfo) {
                draft.processInfo[id] = Object.assign(Object.assign({}, processInfo), { totalProcessCount: count || 0, currentProcessStep: count || 0 });
            }
            else {
                let { totalProcessCount, currentProcessStep } = processInfo;
                switch (actionType) {
                    case 'reset':
                        totalProcessCount = 0;
                        currentProcessStep = 0;
                        break;
                    case 'increment':
                        if (currentProcessStep < totalProcessCount) {
                            currentProcessStep++;
                            totalProcessCount = currentProcessStep;
                        }
                        else {
                            totalProcessCount++;
                            currentProcessStep = totalProcessCount;
                        }
                        break;
                    case 'undo':
                        currentProcessStep--;
                        break;
                    case 'redo':
                        currentProcessStep++;
                        break;
                }
                draft.processInfo[id] = Object.assign(Object.assign({}, processInfo), { totalProcessCount,
                    currentProcessStep, processResult: (_a = Object.assign(Object.assign({}, ((processInfo === null || processInfo === void 0 ? void 0 : processInfo.processResult) || {})), data)) !== null && _a !== void 0 ? _a : processInfo.processResult });
            }
        }));
    }, updateStep: ({ direction }) => {
        const id = get().id;
        set(produce((state) => {
            state.stepState[id] = state.stepState[id] || createInitialStepState();
            state.stepState[id].currentStep += direction === 'increment' ? 1 : -1;
        }));
    }, updateStepState: (step, data) => {
        const id = get().id;
        set(produce((state) => {
            var _a;
            state.stepState[id] = Object.assign(Object.assign({}, state.stepState[id]), { [step]: Object.assign(Object.assign({}, (_a = state.stepState[id]) === null || _a === void 0 ? void 0 : _a[step]), data) });
        }));
    }, setIsPrivacy: (value) => {
        set({ isPrivacy: value });
    }, resetIsPrivacy: () => {
        set({ isPrivacy: false });
    }, clearData: (id) => {
        set((state) => produce(state, (draft) => {
            delete draft.processInfo[id];
            delete draft.stepState[id];
            draft.id = '';
            draft.isPrivacy = false;
        }));
    } })), { name: 'data-process' }), { name: 'data-process' }));
export default useDataProcessStore;
