import moment from 'moment/moment';
import { formatBytes } from '@/utils';
export const API_URL = '/api/v1/ncp/nas/volume';
export const COL_DEF = [
    { headerName: '이름', field: 'nasVolumeSnapshotName' },
    { headerName: '사이즈', field: 'snapshotSize', valueFormatter: (params) => formatBytes(params.value) },
    {
        headerName: '생성일시',
        field: 'createDate',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    },
];
