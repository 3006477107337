import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ClientPagingTable } from '@/components';
import { useClientPagingTable } from '@/hooks';
import { ConsoleTablePageLayout } from '@/layouts';
import { COL_DEF } from './constant';
export default function KtVolumePage() {
    const tableProps = useClientPagingTable({
        queryKey: ['/api/v1/kt/computing/volume'],
        select: (data) => {
            var _a;
            return (_a = data === null || data === void 0 ? void 0 : data.listVolumesResponse) === null || _a === void 0 ? void 0 : _a.volume;
        },
    });
    return (_jsx(_Fragment, { children: _jsx(ConsoleTablePageLayout, Object.assign({ breadcrumb: ['CSP 자원조회', 'KT', 'Computing', 'Volume'] }, { children: _jsx(ClientPagingTable, Object.assign({}, tableProps, { height: "100%", columnDefs: COL_DEF })) })) }));
}
