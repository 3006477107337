export const FORM_VALIDATE = {
    organizationName: {
        notNull: true,
        length: 30,
        fieldName: '신청 조직명',
    },
    summary: {
        notNull: true,
        length: 500,
        fieldName: '프로젝트 개요',
    },
    commonSubCodeSeq: {
        notNull: true,
        fieldName: '신청기관 유형',
    },
    purpose: {
        notNull: true,
        length: 500,
        fieldName: '플랫폼 이용목적',
    },
};
export const SUPPORT_FORM_VALIDATE = {
    organizationName: {
        notNull: true,
        length: 30,
        fieldName: '신청 기관명',
    },
    contactPerson: {
        notNull: true,
        length: 30,
        fieldName: '담당자',
    },
    contact: {
        notNull: true,
        length: 30,
        fieldName: '전화번호',
    },
    email: {
        notNull: true,
        length: 30,
        fieldName: '이메일',
    },
    summary: {
        notNull: true,
        length: 500,
        fieldName: '배경',
    },
    purpose: {
        notNull: true,
        length: 500,
        fieldName: '조치 필요 사항',
    }
};
