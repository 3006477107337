import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@/components';
import { useCallback, useMemo, useState } from '@/hooks';
import { swalConfirm } from '@/utils';
import styles from './AppResourcePanel.module.scss';
export default function AppResourceEditBox({ title, val = 0, maxVal = 0, unit, isEdit, setIsEdit, isEditable = false, onClickUpdateApp, }) {
    // GB 단위 ->  MB 변환 (Memory 값만 GB 단위로 넘어옴)
    const [value, setValue] = useState(unit.toUpperCase().includes('G') && title === 'Memory' ? val * 1024 : val);
    const [maxValue] = useState(title === 'Memory' ? maxVal * 1024 : maxVal);
    const isByteValue = useMemo(() => {
        const upperCaseUnit = unit.toUpperCase();
        return upperCaseUnit.includes('M') || upperCaseUnit.includes('G');
    }, [unit]);
    const formatBytes = useCallback((formatValue) => {
        if (formatValue >= 1024)
            return { value: (formatValue / 1024).toFixed(formatValue % 1024 === 0 ? 0 : 2), unit: 'G' };
        return { value: formatValue, unit: 'M' };
    }, []);
    const onClickUpDownButton = useCallback((event) => {
        const name = event.currentTarget.name;
        if (name === 'up') {
            setValue((prev) => {
                if (!isByteValue && prev + 1 <= maxValue)
                    return prev + 1;
                else if (prev + 128 <= maxValue)
                    return prev + 128;
                return prev;
            });
        }
        else {
            setValue((prev) => {
                if (!isByteValue && prev - 1 >= 1)
                    return prev - 1;
                else if (prev - 128 >= 128)
                    return prev - 128;
                return prev;
            });
        }
    }, [maxValue, isByteValue]);
    const onClickCancel = useCallback(() => {
        setValue(unit.toUpperCase().includes('G') && title === 'Memory' ? val * 1024 : val);
        setIsEdit && setIsEdit(false);
    }, [val, unit]);
    const onClickSave = useCallback(() => {
        swalConfirm('변경사항을 저장하시겠습니까?').then((res) => {
            if (res.isConfirmed)
                onClickUpdateApp && onClickUpdateApp(value);
        });
    }, [onClickUpdateApp, value]);
    return (_jsxs("div", Object.assign({ className: `${styles.panel} ${isEdit ? styles.edit : ''}` }, { children: [_jsxs("div", Object.assign({ className: styles.titBar }, { children: [_jsx("span", Object.assign({ className: styles.title }, { children: title })), isEditable && !isEdit && _jsx(Button, Object.assign({ onClick: () => setIsEdit && setIsEdit(true) }, { children: "\uC218\uC815" })), isEdit && _jsx(Button, Object.assign({ onClick: onClickCancel }, { children: "\uCDE8\uC18C" })), isEdit && (_jsx(Button, Object.assign({ className: styles.saveBtn, onClick: onClickSave }, { children: "\uC800\uC7A5" })))] })), _jsx("div", Object.assign({ className: styles.contentBox }, { children: _jsxs("div", Object.assign({ className: styles.appData }, { children: [_jsxs("div", Object.assign({ className: styles.valueContainer }, { children: [_jsx("span", Object.assign({ className: styles.value }, { children: isByteValue ? formatBytes(value).value : value })), _jsx("span", Object.assign({ className: styles.unit }, { children: isByteValue ? formatBytes(value).unit : unit })), _jsxs("span", Object.assign({ className: styles.max }, { children: ["/ \uCD5C\uB300 ", isByteValue ? formatBytes(maxValue).value : maxValue, isByteValue ? formatBytes(maxValue).unit : unit] }))] })), isEdit && (_jsxs("div", Object.assign({ className: styles.btnGroup }, { children: [_jsx(Button, Object.assign({ name: "up", onClick: onClickUpDownButton }, { children: _jsx("span", { className: styles.up }) })), _jsx(Button, Object.assign({ name: "down", onClick: onClickUpDownButton }, { children: _jsx("span", { className: styles.down }) }))] })))] })) }))] })));
}
