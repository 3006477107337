import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CmpMenu } from '@/constants';
import styles from './breadcrumb.module.scss';
export function Breadcrumb({ pathArr }) {
    const navigate = useNavigate();
    const keys = Object.keys(pathArr);
    const currentUrl = window.location.pathname;
    const description = useMemo(() => {
        for (const menu of CmpMenu) {
            if (menu.children) {
                const matchedChild = menu.children.find((child) => child.link === currentUrl);
                if (matchedChild)
                    return matchedChild.description;
            }
        }
    }, []);
    return (_jsx("div", Object.assign({ className: styles.breadcrumbsArea }, { children: _jsxs("div", Object.assign({ className: styles.box }, { children: [_jsx("h2", Object.assign({ className: styles.subTitle }, { children: keys[keys.length - 1] })), _jsx("span", { children: description })] })) })));
}
export function ConsoleBreadcrumb({ pathArr }) {
    return (_jsx("div", Object.assign({ className: styles.consoleBreadcrumbsArea }, { children: pathArr.map((name) => (_jsx("span", { children: name }, name))) })));
}
export function TermBreadcrumb({ pathArr }) {
    const navigate = useNavigate();
    const keys = Object.keys(pathArr);
    return (_jsx("div", Object.assign({ className: styles.termBreadcrumbsArea }, { children: _jsx("div", Object.assign({ className: styles.box }, { children: _jsx("h2", Object.assign({ className: styles.subTitle }, { children: keys[keys.length - 1] })) })) })));
}
