import create from './ico_create_add.png';
import menu from './ico_menu.png';
import refresh from './ico_refresh.png';
import search from './ico_search2.png';
export const ICON = {
    create,
    refresh,
    menu,
    search,
};
