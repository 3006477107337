import moment from 'moment';
export const DATA_API_URL = '/api/v1/public-data/data';
export const COL_DEF = [
    {
        field: 'title',
        headerName: '제목',
    },
    {
        field: 'agencyName',
        headerName: '기관명',
    },
    {
        field: 'createdAt',
        headerName: '작성일',
        format: (date) => moment(date).format('YYYY-MM-DD'),
    },
];
export const DATA_TYPE = {
    total: '전체',
    api: '오픈 API',
    dataset: '파일데이터',
};
