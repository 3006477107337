import { jsx as _jsx } from "react/jsx-runtime";
import { FrontTable } from '@/components';
import { useFrontTable } from '@/hooks';
import { DefaultLayout } from '@/layouts';
import { FaqColDef } from './constant';
import { BASE_URL } from "@/constants";
export default function FaqPage() {
    const tableProps = useFrontTable({ apiUrl: `${BASE_URL}/api/v1/cmp/community/faq` });
    return (_jsx(DefaultLayout, Object.assign({ breadcrumb: { Home: '/', FAQ: '' } }, { children: _jsx(FrontTable, Object.assign({}, tableProps, { columns: FaqColDef })) })));
}
