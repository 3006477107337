import { jsx as _jsx } from "react/jsx-runtime";
import { FrontTable } from '@/components';
import { useFrontTable } from '@/hooks';
import { DefaultLayout } from '@/layouts';
import { NoticeColDef } from './constant';
import { BASE_URL } from "@/constants";
export default function NoticePage() {
    const tableProps = useFrontTable({ apiUrl: `${BASE_URL}/api/v1/cmp/community/notices` });
    return (_jsx(DefaultLayout, Object.assign({ breadcrumb: { Home: '/', 공지사항: '' } }, { children: _jsx(FrontTable, Object.assign({}, tableProps, { columns: NoticeColDef })) })));
}
