import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ClientPagingTable } from '@/components';
import { useClientPagingTable } from '@/hooks';
import { ConsoleTablePageLayout } from '@/layouts';
import { COL_DEF } from './constant';
export default function NhnAclPage() {
    const tableProps = useClientPagingTable({
        queryKey: [`/api/v1/nhn/network/acl`],
        select: (data) => {
            return data.acls.map((el) => {
                return {
                    name: el === null || el === void 0 ? void 0 : el.name,
                    description: el === null || el === void 0 ? void 0 : el.description,
                };
            });
        },
    });
    return (_jsx(_Fragment, { children: _jsx(ConsoleTablePageLayout, Object.assign({ breadcrumb: ['CSP 자원조회', 'NHN', 'Network', 'Network ACL', 'ACL'] }, { children: _jsx(ClientPagingTable, Object.assign({}, tableProps, { height: "100%", columnDefs: COL_DEF })) })) }));
}
