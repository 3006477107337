// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hKq2WNok4Y1PQVXm5tNQ{display:flex;align-items:center;height:100%}.hKq2WNok4Y1PQVXm5tNQ span{display:inline-block;margin:0 10px 0 4px}.hKq2WNok4Y1PQVXm5tNQ .JVQNFC9URnAS08DmPfQU [class\$=-control]{width:250px;height:38px;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/pages/console/ncp/nas/snapshot/NcpNasSnapshotPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CAEA,2BACE,oBAAA,CACA,mBAAA,CAIA,8DACE,WAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".selectWrapper {\r\n  display: flex;\r\n  align-items: center;\r\n  height: 100%;\r\n\r\n  span {\r\n    display: inline-block;\r\n    margin: 0 10px 0 4px;\r\n  }\r\n\r\n  .selectBox {\r\n    [class$='-control'] {\r\n      width: 250px;\r\n      height: 38px;\r\n      overflow: hidden;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectWrapper": `hKq2WNok4Y1PQVXm5tNQ`,
	"selectBox": `JVQNFC9URnAS08DmPfQU`
};
export default ___CSS_LOADER_EXPORT___;
