import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from '@innogrid-ui/core';
import { omit } from 'lodash';
import React from 'react';
import { Input } from '@/components';
import { useCallback, useMutation, useState } from '@/hooks';
import { CHANGE_EMAIL_FORM_VALIDATE, EMAIL_API_URL } from '@/pages/console/myInfo/constant';
import { useAuthStore } from '@/stores';
import { getCookie, isFormValidate, parseJwt, requestFn } from '@/utils';
import Modal from '../../_components/Modal';
import styles from './ChangeEmailModal.module.scss';
export default function ChangeEmailModal({ isOpen, setIsOpen }) {
    const setUserInfo = useAuthStore((state) => state.setUserInfo);
    const [data, setData] = useState({
        seq: parseJwt(getCookie('portalToken')).userseq,
        password: '',
        email: '',
        domain: '',
        domainSelect: 'self',
    });
    const { mutate: checkEnabledMutate } = useMutation({
        mutationFn: () => requestFn(EMAIL_API_URL, {
            data: Object.assign({ email: `${data.email}@${data.domain}` }, omit(Object.assign({}, data), ['email', 'domain', 'domainSelect'])),
            method: 'post',
        }),
        onSuccess: (res) => {
            if (res.data.content)
                changeEmailMutate();
        },
    });
    const { mutate: changeEmailMutate } = useMutation({
        mutationFn: () => requestFn(`${EMAIL_API_URL}/change`, {
            data: Object.assign({ email: `${data.email}@${data.domain}` }, omit(Object.assign({}, data), ['email', 'domain', 'domainSelect'])),
            method: 'post',
        }),
        onSuccess: () => {
            setUserInfo(getCookie('portalToken'));
            setIsOpen(false);
        },
    });
    const onClickSubmit = useCallback(() => {
        if (!isFormValidate(data, CHANGE_EMAIL_FORM_VALIDATE))
            return;
        checkEnabledMutate();
    }, [data]);
    const onChangeInput = useCallback((event) => {
        if (event.target.id === 'domain')
            setData((prev) => (Object.assign(Object.assign({}, prev), { domain: event.target.value, domainSelect: 'self' })));
        else
            setData((prev) => (Object.assign(Object.assign({}, prev), { [event.target.id]: event.target.value })));
    }, []);
    const onChange = (value) => {
        if (value === 'self') {
            setData((prev) => (Object.assign(Object.assign({}, prev), { domain: '', domainSelect: value })));
        }
        else
            setData((prev) => (Object.assign(Object.assign({}, prev), { domain: value, domainSelect: value })));
    };
    return (_jsx(Modal, Object.assign({ title: "\uC774\uBA54\uC77C \uBCC0\uACBD", isOpen: isOpen, setIsOpen: setIsOpen, onClickSubmit: onClickSubmit }, { children: _jsxs("ul", Object.assign({ className: styles.listForm }, { children: [_jsxs("li", { children: [_jsx("label", Object.assign({ htmlFor: "password", className: styles.labelTitle }, { children: "\uBE44\uBC00\uBC88\uD638" })), _jsx(Input, { className: styles.passwordInput, id: "password", type: "password", placeholder: "\uBE44\uBC00\uBC88\uD638\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.", autoComplete: "new-password", value: data.password, onChange: onChangeInput })] }), _jsx("li", { children: _jsxs("div", Object.assign({ className: styles.emailWrapper }, { children: [_jsx(Input, { className: styles.emailInput, id: "email", type: "text", autoComplete: "new-password", placeholder: "\uC774\uBA54\uC77C\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694.", value: data.email, onChange: onChangeInput }), _jsx("span", Object.assign({ className: styles.at }, { children: "@" })), _jsx(Input, { className: styles.emailInput, id: "domain", type: "text", value: data.domain, onChange: onChangeInput }), _jsx(Select, { options: [
                                    { label: '직접 입력', value: 'self' },
                                    { label: 'naver.com', value: 'naver.com' },
                                    { label: 'google.com', value: 'google.com' },
                                ], placeholder: "\uC9C1\uC811 \uC785\uB825", useEmptyOption: false, rootProps: {
                                    value: data.domainSelect,
                                    onValueChange: onChange,
                                }, triggerProps: { className: styles.selectTrigger } })] })) })] })) })));
}
