import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React from 'react';
import { ToastViewer } from '@/components';
import { useCallback, useCustomQuery, useMutation, useNavigate, useParams } from '@/hooks';
import { DetailLayout } from '@/layouts';
import { formatBytes, requestFn } from '@/utils';
import styles from './NoticeDetail.module.scss';
export default function NoticeDetailPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isFetching } = useCustomQuery({
        queryKey: [`/api/v1/cmp/community/notices/${id}`],
    });
    const { mutate: onClickFileDownload } = useMutation({
        mutationFn: (fileOrder) => requestFn('/api/v1/cmp/community/notices/download', {
            method: 'POST',
            data: { postSeq: id, fileOrder },
            responseType: 'blob',
        }),
        onSuccess: (response) => {
            var _a;
            const name = response.headers['content-disposition'];
            const regex = /name="([^"]+)"/;
            const fileName = decodeURIComponent(name.match(regex)[1]);
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            (_a = link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
        },
    });
    const onClickListBtn = useCallback(() => {
        navigate(`/notice`);
        window.scrollTo({ top: 0 });
    }, [navigate]);
    return (_jsx(DetailLayout, Object.assign({ breadcrumb: { Home: '/', 공지사항: '/notice' } }, { children: _jsx("section", Object.assign({ id: "contetns" }, { children: _jsx("div", Object.assign({ className: styles.layout_inner }, { children: _jsxs("div", Object.assign({ className: styles.board_wrap }, { children: [_jsx("table", Object.assign({ className: styles.board_list_board_view }, { children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", Object.assign({ className: styles.td_subject }, { children: data === null || data === void 0 ? void 0 : data.title })), _jsx("td", Object.assign({ className: styles.td_date }, { children: moment(data === null || data === void 0 ? void 0 : data.createdAt)
                                                .add(9, 'h')
                                                .format('YYYY-MM-DD HH:mm:ss') }))] }) }) })), _jsx("div", Object.assign({ className: styles.view_contents }, { children: !isFetching && _jsx(ToastViewer, { initialValue: data === null || data === void 0 ? void 0 : data.contents }) })), _jsx("div", Object.assign({ className: styles.file_wrap }, { children: (data === null || data === void 0 ? void 0 : data.files) && data.files.length > 0 && (_jsxs("div", Object.assign({ className: styles.file }, { children: [_jsx("div", Object.assign({ className: styles.indicatorText }, { children: _jsxs("span", { children: ["\uCCA8\uBD80\uD30C\uC77C (", data.files.length, ")"] }) })), _jsx("div", Object.assign({ className: styles.fileList }, { children: data === null || data === void 0 ? void 0 : data.files.map((file) => (_jsx("div", Object.assign({ className: styles.fileWrapper }, { children: _jsxs("span", Object.assign({ className: styles.fileText, onClick: () => onClickFileDownload(file.fileOrder) }, { children: [file.originName, " ", `(${formatBytes(file.byteSize)})`] })) }), file.id))) }))] }))) })), _jsx("div", Object.assign({ className: styles.board_btn_wrap }, { children: _jsx("button", Object.assign({ type: "button", onClick: onClickListBtn }, { children: "\uBAA9\uB85D" })) }))] })) })) })) })));
}
