import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { deleteTokenCookies, getCookie, requestFn, swal } from '@/utils';
export default function useIdle() {
    const [isIdle, setIsIdle] = useState(false);
    useIdleTimer({
        timeout: 30 * 60 * 1000,
        onActive: () => setIsIdle(false),
        onIdle: () => setIsIdle(true),
    });
    useEffect(() => {
        if (isIdle && getCookie('portalToken')) {
            requestFn('/api/v1/auth/userauth/logout', {
                data: { access_token: getCookie('portalToken'), refresh_token: getCookie('portalRefreshToken') },
                method: 'POST',
            }).then(() => {
                swal('세션이 만료되었습니다.');
                deleteTokenCookies();
            });
        }
    }, [isIdle]);
}
