import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Select from 'react-select';
export default function SelectBox(props) {
    const { value, options, className, menuPlacement = 'bottom', placeholder = '선택해주세요', isSearchable = false, isMulti = false, isDisabled, } = props;
    const customValue = isMulti
        ? (options === null || options === void 0 ? void 0 : options.filter((opt) => value === null || value === void 0 ? void 0 : value.includes(opt.value))) || null
        : (options === null || options === void 0 ? void 0 : options.find((opt) => opt.value === value)) || null;
    return (_jsx(Select, Object.assign({}, props, { options: options, className: className || 'basic-single', classNamePrefix: "select", placeholder: placeholder, value: customValue, menuPlacement: menuPlacement, isSearchable: isSearchable, isDisabled: isDisabled })));
}
