var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import imgCont from '@/assets/images/img_cont_2.png';
import ktImg from '@/assets/images/logo/etc/logo_ktCloud.png';
import ncpImg from '@/assets/images/logo/etc/logo_naverCloud.png';
import nhnImg from '@/assets/images/logo/etc/logo_nhnCloud.png';
import { Button } from '@/components';
import { useCallback } from '@/hooks';
import { Default2Layout } from '@/layouts';
//import { CLOUD_SERVICE_LIST } from '@/pages/cmp/cloud/constants';
import { useAuthStore } from '@/stores';
import { getCookie, requestFn, swal } from '@/utils';
import styles from './CloudPage.module.scss';
const SSOURL = {
    ncp: 'https://sso.ncloud.com/tenants/digitalsolveup/saml/login',
    kt: 'https://auth.digitalsolveup.kr/realms/ssoRealm/protocol/openid-connect/auth?response_type=code&scope=profile email phone openid&client_id=ktcloud_SSO&redirect_uri=https://cloud.kt.com/sso/response',
    nhn: 'https://digitalsolveup.console.nhncloud.com',
};
const URL = {
    ncp: 'https://www.ncloud.com/',
    kt: 'https://cloud.kt.com',
    nhn: 'https://www.nhncloud.com/kr',
};
export default function CloudPage() {
    const { userSeq } = useAuthStore((state) => state.userInfo);
    const userInfo = useAuthStore((state) => state.userInfo);
    const onClickMoveButton = useCallback((event) => __awaiter(this, void 0, void 0, function* () {
        if (!getCookie('portalToken'))
            return swal('로그인이 필요한 서비스입니다.');
        const name = event.currentTarget.name;
        const expireDate = moment(userInfo === null || userInfo === void 0 ? void 0 : userInfo.expiresAt);
        if (userSeq && moment().isBefore(expireDate)) {
            const cspStatus = yield requestFn(`/api/v1/auth/users/${userSeq}/csp-account`, {});
            if (cspStatus.cloudId && cspStatus[`${name}Status`] === 'SUCCESS') {
                window.open(SSOURL[name], '_blank', 'noreferrer');
            }
            else {
                window.open(URL[name], '_blank', 'noreferrer');
            }
        }
        else {
            window.open(URL[name], '_blank', 'noreferrer');
        }
    }), [userSeq]);
    return (_jsx(Default2Layout, Object.assign({ breadcrumb: { Home: '/', '민간 클라우드': '' } }, { children: _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.headerWrapper }, { children: [_jsx("h3", { children: "\uBBFC\uAC04 \uD074\uB77C\uC6B0\uB4DC \uC11C\uBE44\uC2A4\uB97C \uC774\uC6A9\uD558\uB294 \uC808\uCC28\uB97C \uC548\uB0B4\uD569\uB2C8\uB2E4." }), _jsx("img", { src: imgCont, alt: "service step" })] })), _jsx("div", Object.assign({ className: styles.navigateBtnGroup }, { children: _jsxs("div", Object.assign({ className: styles.naviBox }, { children: [_jsx("div", Object.assign({ className: styles.cloudCategory }, { children: _jsxs(Button, Object.assign({ name: "ncp", onClick: onClickMoveButton }, { children: [_jsx("img", { src: ncpImg, alt: "ncp home" }), _jsx("i", {})] })) })), _jsx("div", Object.assign({ className: styles.cloudCategory }, { children: _jsxs(Button, Object.assign({ name: "kt", onClick: onClickMoveButton }, { children: [_jsx("img", { src: ktImg, alt: "kt home" }), _jsx("i", {})] })) })), _jsx("div", Object.assign({ className: styles.cloudCategory }, { children: _jsxs(Button, Object.assign({ name: "nhn", onClick: onClickMoveButton }, { children: [_jsx("img", { src: nhnImg, alt: "nhn home" }), _jsx("i", {})] })) }))] })) }))] })) })));
}
