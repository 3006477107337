export const API_URL = '/api/v1/public-data/data/my-data';
export const DATA_API_URL = '/api/v1/public-data/data/combine';
export const DATA_TYPE = [
    { label: 'API', value: 'api' },
    { label: 'DATASET', value: 'dataset' },
];
export const COL_DEF = [
    { key: 'checkbox', title: '선택', width: '5%' },
    { key: 'title', title: '데이터명', width: '55%', className: 'txLeft' },
    { key: 'datatype', title: '데이터 형식' },
    { key: 'applyDate', title: '승인일' },
    { key: 'dataInfo', title: '데이터 정보' },
    { key: 'dataUsage', title: '데이터 활용' },
];
