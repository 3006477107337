import moment from 'moment';
export const COL_DEF = [
    { headerName: 'ID', field: 'serverInstanceNo', width: 150 },
    { headerName: '이름', field: 'serverName' },
    { headerName: '상태', field: 'serverInstanceStatusName' },
    { headerName: 'Region', field: 'regionCode', width: 100 },
    { headerName: 'Zone', field: 'zoneCode', width: 100 },
    { headerName: 'Vpc ID', field: 'vpcNo', width: 150 },
    { headerName: '서브넷 ID', field: 'subnetNo', width: 150 },
    { headerName: '공인 IP', field: 'publicIp', valueFormatter: (params) => params.value || '-' },
    { headerName: '메모', field: 'serverDescription', valueFormatter: (params) => params.value || '-' },
    {
        headerName: '생성일시',
        field: 'createDate',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    },
];
