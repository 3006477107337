import { isEmpty } from 'lodash';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
const extractMenuIdsWithChildren = (menus) => {
    return menus.flatMap((item) => item.children && item.children.length > 0 ? [item.menuId, ...extractMenuIdsWithChildren(item.children)] : []);
};
const useCommonStore = create()(devtools(persist((set, get) => ({
    size: 10,
    isClose: false,
    selectedNavMenu: [],
    openedMenus: [],
    setTableSize: (size) => {
        set({
            size,
        });
    },
    setIsClose(payload) {
        set({ isClose: payload });
    },
    setOpenedMenus: (parentMenuId) => {
        set({ openedMenus: parentMenuId });
    },
    onClickTotalOpen: (menus) => {
        set((state) => {
            if (isEmpty(state.openedMenus)) {
                return Object.assign(Object.assign({}, state), { openedMenus: extractMenuIdsWithChildren(menus) });
            }
            else {
                return Object.assign(Object.assign({}, state), { openedMenus: [], selectedNavMenu: state.selectedNavMenu.slice(0, 1) });
            }
        });
    },
    setSelectedNavMenu: (targetDepth, menuId) => {
        const { selectedNavMenu } = get();
        if (targetDepth === 1) {
            set({ selectedNavMenu: [menuId] });
        }
        else if (targetDepth !== 1 && selectedNavMenu[targetDepth - 1] === menuId) {
            const result = selectedNavMenu.slice(0, targetDepth - 1);
            set({ selectedNavMenu: result });
        }
        else {
            const prevSelectedNavMenu = selectedNavMenu.slice(0, targetDepth - 1);
            prevSelectedNavMenu[targetDepth - 1] = menuId;
            set({ selectedNavMenu: prevSelectedNavMenu });
        }
    },
    setDepthByParsedUrl: (parsedDepth) => set({ selectedNavMenu: parsedDepth }),
}), { name: 'common' }), { name: 'common' }));
export default useCommonStore;
