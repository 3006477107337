import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import shortid from 'shortid';
import { Button, ConsoleBreadcrumb, Loading } from '@/components';
import { useCallback, useNavigate, useParams, useQuery, useQueryClient, useState } from '@/hooks';
import { MY_SERVICE_APP_API_URL } from '@/pages/console/myService/constant';
import AppResourcePanel from '@/pages/console/myService/detail/_components/AppResourcePanel';
import AppServiceTable from '@/pages/console/myService/detail/_components/AppServiceTable';
import AppSummaryPanel from '@/pages/console/myService/detail/_components/AppSummaryPanel';
import { swal, swalConfirm } from '@/utils';
import axiosUtil from '@/utils/axios';
import styles from './MyServiceDetailPage.module.scss';
export default function MyServiceDetailPage() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { orgid, appid } = useParams();
    const [expandTable, setExpandTable] = useState('');
    const { data, isFetching } = useQuery({
        queryKey: [`${MY_SERVICE_APP_API_URL}/getAppDetail`],
        queryFn: ({ queryKey }) => axiosUtil.get(queryKey[0], {
            params: { orgid, appid },
        }),
    });
    const { data: eventData, isFetching: isEventFetching } = useQuery({
        queryKey: [`${MY_SERVICE_APP_API_URL}/getAppEvent`],
        queryFn: ({ queryKey }) => axiosUtil.get(queryKey[0], {
            params: { appid },
        }),
    });
    const { data: stateData, isFetching: isStateFetching } = useQuery({
        queryKey: [`${MY_SERVICE_APP_API_URL}/getAppStats`],
        queryFn: ({ queryKey }) => axiosUtil.get(queryKey[0], {
            params: { appid },
        }),
    });
    const { data: logData, isFetching: isLogFetching } = useQuery({
        queryKey: [`${MY_SERVICE_APP_API_URL}/getAppLog`],
        queryFn: ({ queryKey }) => axiosUtil.get(queryKey[0], {
            params: { appid },
        }),
    });
    const { mutate } = useMutation({
        mutationFn: (index) => axiosUtil.get(`${MY_SERVICE_APP_API_URL}/restartInstance`, {
            params: { appid, index },
        }),
        onSuccess: (response) => {
            swal(response === null || response === void 0 ? void 0 : response.msg);
            queryClient.invalidateQueries([`${MY_SERVICE_APP_API_URL}/getAppStats`]);
        },
    });
    const onClickRestart = useCallback((instanceIndex) => {
        swalConfirm('인스턴스를 재시작 하시겠습니까?').then((res) => {
            if (res.isConfirmed)
                mutate(instanceIndex);
        });
    }, []);
    const onClickExpand = useCallback((type) => {
        if (expandTable === type)
            setExpandTable('');
        else
            setExpandTable(type);
    }, [expandTable]);
    const handleStateColor = useCallback((state) => {
        if (state === 'DOWN')
            return '#b3b3b3';
        else if (state === 'RUNNING')
            return '#1e6dbd';
        else if (state === 'STARTING')
            return '#2dbd1e';
        return '#b3b3b3';
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(ConsoleBreadcrumb, { pathArr: ['My Service', 'Service'] }), _jsx("div", Object.assign({ className: styles.container }, { children: isFetching ? (_jsx(Loading, { color: "#268eff" })) : (_jsxs(_Fragment, { children: [_jsxs("a", Object.assign({ className: styles.back, onClick: () => navigate('/my-service') }, { children: [_jsx("i", { className: styles.backIcon }), "\uB4A4\uB85C \uAC00\uAE30"] })), _jsxs("section", Object.assign({ className: styles.appDashboard }, { children: [_jsx(AppSummaryPanel, Object.assign({}, data)), _jsx(AppResourcePanel, Object.assign({}, data))] })), _jsx("hr", { className: styles.lineSection }), _jsxs("section", Object.assign({ className: isEmpty(expandTable) ? styles.appBoard : styles.appBoardExpand }, { children: [_jsx("div", Object.assign({ className: `${styles.panel} ${expandTable === 'Event' ? styles.expand : ''}` }, { children: _jsx(AppServiceTable, Object.assign({ title: "Event", columns: ['Date', 'Type', 'Actor', 'Report'], isFetching: isEventFetching, onClickRefresh: () => queryClient.invalidateQueries([`${MY_SERVICE_APP_API_URL}/getAppEvent`]), onClickExpand: () => onClickExpand('Event') }, { children: eventData &&
                                            eventData.map((event) => (_jsxs("tr", { children: [_jsx("td", { children: event.createAt }), _jsx("td", { children: event.type }), _jsx("td", { children: event.actor }), _jsx("td", { children: event.report })] }, shortid.generate()))) })) })), _jsx("div", Object.assign({ className: `${styles.panel} ${expandTable === 'State' ? styles.expand : ''}` }, { children: _jsx(AppServiceTable, Object.assign({ title: "State", columns: ['State', 'CPU', 'Memory', 'Disk', 'Uptime', 'Restart'], isFetching: isStateFetching, onClickRefresh: () => queryClient.invalidateQueries([`${MY_SERVICE_APP_API_URL}/getAppStats`]), onClickExpand: () => onClickExpand('State') }, { children: stateData &&
                                            stateData.map((state) => (_jsxs("tr", { children: [_jsx("td", { children: _jsx("span", Object.assign({ className: `${styles.state}`, style: { backgroundColor: handleStateColor(state.state) } }, { children: state.state })) }), _jsx("td", { children: state.cpu }), _jsx("td", { children: state.memory }), _jsx("td", { children: state.disk }), _jsx("td", { children: state.uptime }), _jsx("td", { children: state.state !== 'DOWN' && (_jsx(Button, Object.assign({ className: styles.restartBtn, onClick: () => onClickRestart(state.instanceIndex) }, { children: "RESTART" }))) })] }, shortid.generate()))) })) })), _jsx("div", Object.assign({ className: `${styles.panel} ${expandTable === 'Log' ? styles.expand : ''}` }, { children: _jsx(AppServiceTable, Object.assign({ title: "Log", columns: ['Name'], isFetching: isLogFetching, onClickRefresh: () => queryClient.invalidateQueries([`${MY_SERVICE_APP_API_URL}/getAppLog`]), onClickExpand: () => onClickExpand('Log') }, { children: _jsx("tr", { children: _jsx("td", { children: _jsx("pre", { children: (logData === null || logData === void 0 ? void 0 : logData.logStr) && logData.logStr }) }) }) })) }))] }))] })) }))] }));
}
