import moment from "moment/moment";
export const COL_DEF = [
    { headerName: 'ID', field: 'id' },
    { headerName: 'Acl ID', field: 'aclId' },
    { headerName: 'Project ID', field: 'projectId' },
    { headerName: 'Dst IP', field: 'dstIp' },
    { headerName: 'Src IP', field: 'srcIp' },
    { headerName: 'Ethertype', field: 'ethertype' },
    { headerName: 'Description', field: 'description' },
    { headerName: '생성일시',
        field: 'createdAt',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss')
    },
    { headerName: '수정일시',
        field: 'updatedAt',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss') }
];
