import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Input, useAlertDialog } from '@innogrid-ui/core';
import { useMutation } from '@tanstack/react-query';
import React, { memo, useState } from 'react';
import { processDateConversion } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/promise';
import { useDataProcessStore } from '@/stores';
import { validateError } from '@/utils';
import styles from '../DataProcess.module.scss';
const Component = ({ column, id, submenu }) => {
    const [{ procs_submenu_kor_nm }] = submenu;
    const { open } = useAlertDialog();
    const { processResult } = useDataProcessStore((state) => state.processInfo)[id];
    const updateProcessInfo = useDataProcessStore((state) => state.updateProcessInfo);
    const [format, setFormat] = useState('');
    const { mutate } = useMutation({
        mutationFn: processDateConversion,
        onSuccess: ({ data }) => {
            if (!validateError(data, open)) {
                updateProcessInfo({ data: data.result, actionType: 'increment' });
            }
        },
    });
    const onClick = () => {
        mutate({
            defaultData: { datasetInfo: processResult, column },
            contents: { datetime_format: format },
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx("p", { children: procs_submenu_kor_nm || '' }), _jsx("div", Object.assign({ className: `tableBox ${styles.tableBox}` }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ style: { width: '42%' } }, { children: "\uB0B4\uC6A9" })), _jsx("th", Object.assign({ style: { width: '23%' } }, { children: "\uD3EC\uB9F7" })), _jsx("th", Object.assign({ style: { width: '35%' } }, { children: "\uC608\uC2DC" }))] }) }), _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { children: "\uC5F0\uB3C4(4\uC790\uB9AC)" }), _jsx("td", { children: "?Y" }), _jsx("td", { children: "2023" })] }), _jsxs("tr", { children: [_jsx("td", { children: "\uC5F0\uB3C4(2\uC790\uB9AC)" }), _jsx("td", { children: "?y" }), _jsx("td", { children: "23" })] }), _jsxs("tr", { children: [_jsx("td", { children: "\uC6D4" }), _jsx("td", { children: "?m" }), _jsx("td", { children: "6" })] }), _jsxs("tr", { children: [_jsx("td", { children: "\uC77C" }), _jsx("td", { children: "?d" }), _jsx("td", { children: "01, 31" })] }), _jsxs("tr", { children: [_jsx("td", { children: "\uC2DC\uAC04" }), _jsx("td", { children: "?H" }), _jsx("td", { children: "00 ~ 23" })] }), _jsxs("tr", { children: [_jsx("td", { children: "\uBD84" }), _jsx("td", { children: "?M" }), _jsx("td", { children: "00 ~ 59" })] }), _jsxs("tr", { children: [_jsx("td", { children: "\uCD08" }), _jsx("td", { children: "?s" }), _jsx("td", { children: "00 ~ 59" })] })] })] }) })), _jsx("p", Object.assign({ className: "dataText" }, { children: "\uC608\uC2DC) 20240820 : ?Y-?m-?d" })), _jsx("div", Object.assign({ className: "dataInput" }, { children: _jsx(Input, { onChange: (e) => setFormat(e.currentTarget.value), value: format }) })), _jsx("div", Object.assign({ className: "dataBtns" }, { children: _jsx(Button, Object.assign({ onClick: onClick, size: "small" }, { children: "\uC801\uC6A9" })) }))] }));
};
export const DateConversion = memo(Component);
