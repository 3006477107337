export const DATA_API_URL = '/api/v1/public-data/data/combine';
export const TEMPERATURE_LABEL = { celsius: '섭씨', fahrenheit: '화씨' };
export const STATUS_OPTIONS = [
    { label: '활성', value: true },
    { label: '비활성', value: false },
];
export const REGISTRATION_OPTION = [
    { label: '등록', value: 'Y' },
    { label: '미동록', value: 'N' },
];
export const COLUMN_TYPE_OPTION = [
    { label: 'VARCHAR', value: 'varchar' },
    { label: 'NUMBER', value: 'number' },
];
export const NULLABLE_OPTION = [
    { label: 'Y', value: true },
    { label: 'N', value: false },
];
export const SCHEDULER = {
    EVERY_MINUTE: 'every_minute',
    HOURLY: 'hourly',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
};
export const CYCLE_OPTIONS = [
    { label: '분 배치', value: SCHEDULER.EVERY_MINUTE },
    { label: '시간 배치', value: SCHEDULER.HOURLY },
    { label: '일 배치', value: SCHEDULER.DAILY },
    { label: '주 배치', value: SCHEDULER.WEEKLY },
    { label: '월 배치', value: SCHEDULER.MONTHLY },
];
export const DEFAULT_INTERVALS = {
    [SCHEDULER.EVERY_MINUTE]: { minute: undefined },
    [SCHEDULER.HOURLY]: { hour: undefined },
    [SCHEDULER.DAILY]: { hour: undefined },
    [SCHEDULER.WEEKLY]: { dayOfWeek: undefined },
    [SCHEDULER.MONTHLY]: { day: undefined },
};
