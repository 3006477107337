var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import axiosUtil from '@/utils/axios';
import styles from './CardItem.module.scss';
export default function CardItem(props) {
    const { row, apiUrl, onNavigateRow, kind } = props;
    const { data: imageData = null } = useQuery([`${apiUrl}/${row.seq}`], () => __awaiter(this, void 0, void 0, function* () {
        return yield axiosUtil({
            url: `${apiUrl}/download/${row.seq}`,
            method: 'GET',
            responseType: 'blob',
        }).catch((error) => {
            return error;
        });
    }), {
        select: (response) => {
            if (response instanceof Blob) {
                return window.URL.createObjectURL(response);
            }
        },
        enabled: !!row && !!row.files && row.files.length > 0,
        onError: (err) => {
            console.log(err);
        }
    });
    const showStatus = (statusCode) => {
        if (kind === 'project') {
            if (statusCode === 'OPEN') {
                return (_jsx("div", Object.assign({ className: styles.showStatus, style: { backgroundColor: '#fff0f2', color: '#ff5b5b' } }, { children: "\uC9C4\uD589\uC911" })));
            }
            else if (statusCode === 'CLOSED') {
                return (_jsx("div", Object.assign({ className: styles.showStatus, style: { backgroundColor: '#cecece', color: '#8d8d8d' } }, { children: "\uC885\uB8CC" })));
            }
            else if (statusCode === 'PRE_OPEN') {
                return (_jsx("div", Object.assign({ className: styles.showStatus, style: { backgroundColor: '#bad0ff', color: '#719cfd' } }, { children: "\uB300\uAE30" })));
            }
            else {
                return (_jsx(_Fragment, {}));
            }
        }
        else {
            if (statusCode === 'PRE_OPEN') {
                return (_jsx("div", Object.assign({ className: styles.showStatus, style: { backgroundColor: '#bad0ff', color: '#719cfd' } }, { children: "\uB300\uAE30" })));
            }
            else {
                return (_jsx(_Fragment, {}));
            }
        }
    };
    return (_jsx("article", Object.assign({ className: styles.gallery_item }, { children: _jsxs("div", Object.assign({ className: styles.link, onClick: () => onNavigateRow(row.seq) }, { children: [_jsx("div", Object.assign({ className: styles.imgContainer }, { children: imageData && _jsx("img", { className: styles.img, src: imageData, alt: row.files[0].originName }) })), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx("h3", Object.assign({ className: styles.title }, { children: row.title })), kind === 'project'
                            ?
                                _jsxs("div", Object.assign({ className: styles.underBox }, { children: [_jsx("div", Object.assign({ className: styles.date }, { children: `${moment(row.startDate).add(9, 'h').format('YYYY-MM-DD')} ~ ` })), _jsx("div", Object.assign({ className: styles.date }, { children: moment(row.endDate).add(9, 'h').format('YYYY-MM-DD') })), showStatus(row.statusCode)] }))
                            :
                                _jsxs("div", Object.assign({ className: styles.underBox }, { children: [_jsx("div", Object.assign({ className: styles.date }, { children: `${moment(row.createdAt).add(9, 'h').format('YYYY-MM-DD')}` })), showStatus(row.statusCode)] }))] }))] })) })));
}
