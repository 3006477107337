export const APPLY_STATUS = {
    Y: { label: '승인완료', value: 'Y', color: 'blue' },
    N: { label: '승인대기', value: 'N', color: 'orange' },
    X: { label: '승인거절', value: 'N', color: 'red' },
};
export const DATA_TYPE = {
    api: { label: 'API', value: 'api' },
    dataset: { label: 'DATASET', value: 'dataset' },
};
export const API_URL = '/api/v1/public-data/data/my-data';
