import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Popover, useAlertDialog, useToast } from '@innogrid-ui/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useClickAway, useEvent } from 'react-use';
import close from '@/assets/images/icon/ico_close.svg';
import { resetProcess, } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/promise';
import { useDataProcessStore } from '@/stores';
import { validateError } from '@/utils';
import styles from '../DataProcess.module.scss';
export function ProcessRulePopup({ setIsOpen }) {
    var _a, _b, _c;
    const queryClient = useQueryClient();
    const { open } = useAlertDialog();
    const { open: toastOpen } = useToast();
    const popupRef = useRef(null);
    const id = useDataProcessStore((state) => state.id);
    const datasetId = ((_c = (_b = (_a = useDataProcessStore((state) => state.processInfo)) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.processResult) === null || _c === void 0 ? void 0 : _c.datasetId) || '';
    const updateProcessInfo = useDataProcessStore((state) => state.updateProcessInfo);
    useEvent('keydown', (event) => {
        if (event.key === 'Escape') {
            setIsOpen(false);
        }
    });
    useClickAway(popupRef, () => setIsOpen(false));
    const historyData = queryClient.getQueryData([
        'getProcessWorkHistoryList',
        datasetId,
    ]);
    const [list, setList] = useState([]);
    useEffect(() => {
        queryClient.invalidateQueries(['getProcessWorkHistoryList']);
    }, []);
    useEffect(() => {
        var _a;
        if (!validateError(historyData, open)) {
            setList((_a = historyData === null || historyData === void 0 ? void 0 : historyData.result) === null || _a === void 0 ? void 0 : _a.workHistoryList);
        }
    }, [historyData]);
    const { mutate } = useMutation({
        mutationFn: resetProcess,
        onSuccess: ({ data }) => {
            if (validateError(data, open))
                return;
            toastOpen({ children: '초기화되었습니다.', status: 'success' });
            queryClient.invalidateQueries(['getProcessWorkHistoryList']);
            updateProcessInfo({ data: data.result, actionType: 'reset' });
        },
    });
    const onClickReset = () => {
        open({
            children: '초기화 하시겠습니까?',
            onClickConfirm: () => mutate({ dataset_id: datasetId }),
            variant: 'common',
            cancelButtonText: '취소',
        });
    };
    return (_jsxs("div", Object.assign({ className: styles.popupInner, ref: popupRef }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("button", Object.assign({ type: "button", className: styles.btnClose, onClick: () => setIsOpen(false) }, { children: _jsx("img", { src: close, alt: "\uB2EB\uAE30" }) })), _jsxs("div", Object.assign({ className: styles.popupTitBox }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: "\uAC00\uACF5\uADDC\uCE59" })), _jsxs(Popover, Object.assign({ className: "popover", iconPath: "", variant: "blue", contentClassName: "popoverContent" }, { children: ["\uC801\uC6A9\uD55C \uAC00\uACF5\uC0C1\uD0DC\uB97C \uC774\uC804 \uC0C1\uD0DC\uB85C", _jsx("br", {}), "\uB418\uB3CC\uB9AC\uAC70\uB098 \uB418\uB3CC\uB838\uB358 \uAC00\uACF5\uC0C1\uD0DC\uB97C", _jsx("br", {}), "\uC7AC\uC801\uC6A9\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4."] }), "false bottom")] }))] })), _jsx("div", Object.assign({ className: styles.body }, { children: isEmpty(list) ? (_jsx("p", Object.assign({ className: styles.noData }, { children: "\uAC00\uACF5\uADDC\uCE59\uC744 \uC120\uD0DD\uD574 \uC8FC\uC138\uC694." }))) : (_jsx("ul", Object.assign({ className: styles.listBox }, { children: list.map((data, index) => {
                        var _a;
                        return (_jsxs("li", { children: [_jsxs("div", { children: ["\uCEEC\uB7FC\uBA85 : ", (_a = data === null || data === void 0 ? void 0 : data.column) === null || _a === void 0 ? void 0 : _a.toUpperCase()] }), _jsxs("div", { children: ["\uAC00\uACF5 : ", (data === null || data === void 0 ? void 0 : data.name) || '-'] }), (data === null || data === void 0 ? void 0 : data.option) ? _jsxs("div", { children: ["\uC18D\uC131 : ", data.option] }) : null] }, index));
                    }) }))) })), _jsx("div", Object.assign({ className: styles.popupBtns }, { children: _jsx(Button, Object.assign({ color: "common", onClick: onClickReset, size: "small", variant: "outlined", disabled: isEmpty(list) }, { children: "\uCD08\uAE30\uD654" })) }))] })));
}
