import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Button, IconButton } from '@/components';
import { useCallback, useCustomQuery, useMutation, useQueryClient, useState } from '@/hooks';
import { useAuthStore } from '@/stores';
import { getCookie, parseJwt, requestFn, swal } from '@/utils';
import styles from './QnaCommnetForm.module.scss';
export default function QnaCommentForm() {
    const { id } = useParams();
    const isAuth = !!getCookie('portalToken');
    const queryClient = useQueryClient();
    const { userId: currentUserId, username } = useAuthStore((state) => state.userInfo);
    const commentApiKey = `/api/v1/cmp/community/qna/${id}/comments`;
    const [createCommentValue, setCreateCommentValue] = useState('');
    const [updateCommentInput, setUpdateCommentInput] = useState('');
    const [clickedCommentId, setClickedCommentId] = useState(-1);
    const [updateCommentId, setUpdateCommentId] = useState(-1);
    const { data: comments } = useCustomQuery({
        queryKey: [`/api/v1/cmp/community/qna/${id}/comments`],
    });
    const onClickCommentActionBtn = useCallback((commentId) => {
        if (commentId === clickedCommentId)
            setClickedCommentId(-1);
        else
            setClickedCommentId(commentId);
    }, [clickedCommentId]);
    const onClickUpdateCommentBtn = useCallback(({ id, contents }) => {
        setUpdateCommentId(id);
        setClickedCommentId(-1);
        setUpdateCommentInput(contents);
    }, []);
    const onChangeUpdateCommentValue = useCallback((event) => {
        setUpdateCommentInput(event.target.value);
    }, []);
    const onChangeCreateCommentValue = useCallback((event) => {
        setCreateCommentValue(event.target.value);
    }, []);
    const { mutate: createMutate } = useMutation({
        mutationFn: () => requestFn(commentApiKey, { method: 'POST', data: { contents: createCommentValue } }),
        onSuccess: () => {
            setCreateCommentValue('');
            queryClient.invalidateQueries([commentApiKey]);
        },
    });
    const onSubmitCreateComment = useCallback(() => {
        if (!createCommentValue)
            return swal('답글을 입력해주세요');
        createMutate();
    }, [createMutate, createCommentValue]);
    const { mutate: updateMutate } = useMutation({
        mutationFn: () => requestFn(commentApiKey, {
            method: 'PUT',
            data: { seq: updateCommentId, contents: updateCommentInput },
        }),
        onSuccess: () => {
            setClickedCommentId(-1);
            setUpdateCommentId(-1);
            setUpdateCommentInput('');
            queryClient.invalidateQueries([commentApiKey]);
        },
    });
    const onSubmitUpdateComment = useCallback(() => {
        if (!updateCommentInput)
            return swal('수정할 답글을 입력해주세요.');
        updateMutate();
    }, [updateMutate, updateCommentInput]);
    const { mutate: deleteMutate } = useMutation({
        mutationFn: (commentId) => requestFn(commentApiKey, { method: 'DELETE', data: { seq: commentId } }),
        onSuccess: () => {
            setClickedCommentId(-1);
            queryClient.invalidateQueries([commentApiKey]);
        },
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.commentContainer }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: ["\uB2F5\uAE00 ", `${(comments === null || comments === void 0 ? void 0 : comments.length) || '0'}`, " \uAC1C"] })), _jsx("div", Object.assign({ className: styles.contents }, { children: comments === null || comments === void 0 ? void 0 : comments.map((comment) => {
                            var _a;
                            return (_jsxs("div", Object.assign({ className: styles.commentWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.commentHeader }, { children: [_jsxs("div", { children: [_jsx("span", Object.assign({ className: styles.commentCreator }, { children: comment.creatorUsername })), _jsx("span", Object.assign({ className: styles.commentCreatedAt }, { children: moment(comment.createdAt).add(9, 'h').format('YYYY-MM-DD HH:mm:ss') }))] }), _jsx("div", Object.assign({ className: styles.commentActionBtn }, { children: comment.creatorSeq ===
                                                    (getCookie('portalToken') ? parseInt((_a = parseJwt(getCookie('portalToken'))) === null || _a === void 0 ? void 0 : _a.userseq) : -1) &&
                                                    !comment.isDeleted && (_jsxs(_Fragment, { children: [_jsx(IconButton, { icon: 'menu', onClick: () => onClickCommentActionBtn(comment.seq) }), clickedCommentId === comment.seq && (_jsxs("div", Object.assign({ className: styles.dropDownBtnWrapper }, { children: [_jsx("button", Object.assign({ onClick: () => onClickUpdateCommentBtn({ id: comment.seq, contents: comment.contents }) }, { children: "\uC218\uC815" })), _jsx("button", Object.assign({ onClick: () => deleteMutate(comment.seq) }, { children: "\uC0AD\uC81C" }))] })))] })) }))] })), updateCommentId === comment.seq ? (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.textArea }, { children: _jsx("textarea", { onChange: onChangeUpdateCommentValue, placeholder: "\uB2F5\uAE00\uC744 \uC785\uB825\uD558\uC138\uC694", value: updateCommentInput }) })), _jsxs("div", Object.assign({ className: `${styles.submitBtnWrapper} ${styles.update}` }, { children: [_jsx(Button, Object.assign({ onClick: () => setUpdateCommentId(-1) }, { children: "\uCDE8\uC18C" })), _jsx(Button, Object.assign({ onClick: onSubmitUpdateComment }, { children: "\uC218\uC815" }))] }))] })) : (_jsx("div", Object.assign({ className: styles.commentContents }, { children: comment.isDeleted ? '삭제된 답글입니다.' : comment.contents })))] }), comment.creatorSeq));
                        }) }))] })), isAuth && (_jsxs("div", Object.assign({ className: styles.inputArea }, { children: [_jsx("div", Object.assign({ className: styles.inputHeader }, { children: username })), _jsx("div", Object.assign({ className: styles.textArea }, { children: _jsx("textarea", { onChange: onChangeCreateCommentValue, placeholder: "\uB2F5\uAE00\uC744 \uC785\uB825\uD558\uC138\uC694", value: createCommentValue }) })), _jsx("div", Object.assign({ className: styles.submitBtnWrapper }, { children: _jsx(Button, Object.assign({ onClick: onSubmitCreateComment }, { children: "\uB4F1\uB85D" })) }))] })))] }));
}
