export const CmpMenu = [
    {
        name: '데이터',
        menuId: 'data',
        link: '/data',
        description: '서비스 개발 시 민간 · 공공 데이터를 포털에서 신청하여 즉시 활용할 수 있습니다',
    },
    {
        name: 'API',
        menuId: 'service-api',
        link: '/service-api',
        description: '디지털사회혁신 서비스 개발 시 자주 사용하는 API 카테고리를 제공합니다',
    },
    {
        name: 'Open S/W',
        menuId: 'open-sw',
        link: '/open-sw',
        description: '다양한 개발자의 디지털사회혁신 SW를 참고해 빠르게 개발할 수 있습니다',
    },
    {
        name: '클라우드',
        menuId: 'cloud',
        link: '/cloud',
        description: '민간 클라우드사에서 제공하는 첨단 서비스를 무상 활용할수 있습니다',
    },
    {
        name: '플랫폼 서비스',
        menuId: 'platform-service',
        link: '/platform-service',
        description: '개발된 서비스 소스코드를 즉시 배포 · 운영할 수 있는 환경을 제공합니다',
    },
    {
        name: '아이디어',
        menuId: 'idea',
        depth: 2,
        link: '/idea',
        description: '다양한 시민 참여자의 사회현안 해결 아이디어를 참고할 수 있습니다',
    },
    {
        name: '프로젝트',
        menuId: 'project',
        depth: 2,
        link: '/project',
        description: '민관협력 지원 플랫폼을 활용한 주요 프로젝트를 확인할 수 있습니다',
    },
    // {
    // 	name: '공지사항',
    // 	menuId: 'notice',
    // 	link: '/notice',
    // 	description: '공지사항을 확인할 수 있습니다',
    // },
    // {
    // 	name: 'FAQ',
    // 	menuId: 'faq',
    // 	link: '/faq',
    // 	description: '자주 묻는 질문을 확인할 수 있습니다',
    // },
    // {
    // 	name: 'Q&A',
    // 	menuId: 'qna',
    // 	link: '/qna',
    // 	description: '민관협력 지원 플랫폼에 대한 질문을 할 수 있습니다',
    // },
];
