import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import { ClientPagingTable, SelectBox } from '@/components';
import { useCallback, useClientPagingTable, useEffect, useReactSelect, useState } from '@/hooks';
import { ConsoleTablePageLayout } from '@/layouts';
import { API_URL, COL_DEF } from './constant';
import styles from './NcpNasSnapshotPage.module.scss';
export default function NcpNasSnapshotPage() {
    const [volume, setVolume] = useState('');
    const { volumeNameList } = useReactSelect([
        {
            queryKey: [`${API_URL}/names`],
            key: 'volumeNameList',
            select: (data) => {
                var _a;
                return ((_a = data === null || data === void 0 ? void 0 : data.getNasVolumeInstanceListResponse) === null || _a === void 0 ? void 0 : _a.nasVolumeInstanceList) &&
                    data.getNasVolumeInstanceListResponse.nasVolumeInstanceList.map((d) => ({
                        label: d.volumeName,
                        value: d.nasVolumeInstanceNo,
                    }));
            },
            labelValue: ['name', 'uuid'],
            noContentKey: true,
        },
    ]);
    const tableProps = useClientPagingTable({
        queryKey: [`${API_URL}/${volume}/snapshot`],
        select: (data) => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.getNasVolumeSnapshotListResponse) === null || _a === void 0 ? void 0 : _a.nasVolumeSnapshot; },
        enabled: !!volume,
    });
    useEffect(() => {
        var _a, _b, _c;
        if (isEmpty(volumeNameList.options))
            (_b = (_a = tableProps.tableRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.showNoRowsOverlay();
        else if (isEmpty(volume) && volumeNameList.options)
            setVolume(((_c = volumeNameList === null || volumeNameList === void 0 ? void 0 : volumeNameList.options[0]) === null || _c === void 0 ? void 0 : _c.value) || '');
    }, [volumeNameList]);
    const onChangeSelect = useCallback(({ value }) => {
        setVolume(value);
    }, []);
    return (_jsx(_Fragment, { children: _jsx(ConsoleTablePageLayout, Object.assign({ breadcrumb: ['CSP 자원조회', 'NAS', 'Snapshot'] }, { children: _jsx(ClientPagingTable, Object.assign({}, tableProps, { height: "100%", columnDefs: COL_DEF }, { children: _jsxs("div", Object.assign({ className: styles.selectWrapper }, { children: [_jsx("span", { children: "\uD074\uB7EC\uC2A4\uD130 \uC774\uB984" }), _jsx(SelectBox, Object.assign({}, volumeNameList, { className: styles.selectBox, value: volume, onChange: onChangeSelect }))] })) })) })) }));
}
