import moment from "moment/moment";
export const COL_DEF = [
    { headerName: '이름', field: 'name' },
    { headerName: '위치', field: 'zoneId' },
    { headerName: '상태', field: 'state' },
    { headerName: '타입', field: 'snapshotType' },
    { headerName: '디스크 이름', field: 'volumeName' },
    { headerName: '디스크 크기', field: 'virtualSize' },
    { headerName: '생성일시',
        field: 'created',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss')
    }
];
