import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ClientPagingTable, SelectBox } from '@/components';
import { useCallback, useClientPagingTable, useState } from '@/hooks';
import { ConsoleTablePageLayout } from '@/layouts';
import { COL_DEF } from './constant';
import styles from "@/pages/console/nhn/compute/server/NhnServerPage.module.scss";
export default function KtImagePage() {
    const [imageType, setImageType] = useState('SELF');
    const tableProps = useClientPagingTable({
        queryKey: [`/api/v1/kt/computing/image?templateFilter=${imageType}`],
        select: (data) => {
            var _a;
            return (_a = data === null || data === void 0 ? void 0 : data.listTemplatesResponse) === null || _a === void 0 ? void 0 : _a.template;
        },
    });
    const onChangeSelect = useCallback(({ value }) => {
        console.log(value);
        setImageType(value);
    }, []);
    return (_jsx(_Fragment, { children: _jsx(ConsoleTablePageLayout, Object.assign({ breadcrumb: ['CSP 자원조회', 'KT', 'Computing', 'Image'] }, { children: _jsx(ClientPagingTable, Object.assign({}, tableProps, { height: "100%", columnDefs: COL_DEF }, { children: _jsxs("div", Object.assign({ className: styles.selectWrapper }, { children: [_jsx("span", { children: "\uD15C\uD50C\uB9BF" }), _jsx(SelectBox, { options: [
                                { label: 'SELF', value: 'SELF' },
                                { label: 'SELF_EXECUTABLE', value: 'SELF_EXECUTABLE' }
                            ], className: styles.selectBox, value: imageType, onChange: onChangeSelect })] })) })) })) }));
}
