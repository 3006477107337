export const FORM_STATUS = {
    ASSOCIATE: '승인 대기',
    APPROVAL: '승인 완료',
    REJECT: '반려',
};
export const PASSWORD_API_URL = '/api/v1/auth/users';
export const PHONE_API_URL = '/api/v1/auth/users/contact';
export const EMAIL_API_URL = '/api/v1/auth/users/email';
export const CHANGE_PASSWORD_FORM_VALIDATE = {
    beforePassword: {
        notNull: true,
        fieldName: '기존 비밀번호',
    },
    password: {
        notNull: true,
        fieldName: '새 비밀번호',
    },
    reEnterPassword: {
        notNull: true,
        fieldName: '새 비밀번호 확인',
    },
};
export const CHANGE_EMAIL_FORM_VALIDATE = {
    password: {
        notNull: true,
        fieldName: '비밀번호',
    },
    email: {
        notNull: true,
        fieldName: '이메일',
    },
    domain: {
        notNull: true,
        fieldName: '이메일',
    },
};
export const CHANGE_CONTACT_FORM_VALIDATE = {
    password: {
        notNull: true,
        fieldName: '비밀번호',
    },
    phone: {
        notNull: true,
        fieldName: '연락처',
    },
};
