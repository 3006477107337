// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eB0y8oyCrUitggGgxzI5{display:flex;justify-content:space-between;align-items:center;min-width:148px;height:30px;font:inherit;color:var(--default-dark);border:1px solid #bec3ca;border-radius:3px;background:linear-gradient(#fdfdfd, #f6f6f9);cursor:pointer}.eB0y8oyCrUitggGgxzI5:hover .ps629wySAy4E_lACVziA{background-position-y:-10px}.PiWZDzeH3YkvQ9AZ2scD{text-align:left;padding-left:6px;width:92px}.IaK62O6kHRiXA07hhcMt{border-left:1px solid #bec3ca;height:28px}.ps629wySAy4E_lACVziA{width:21px;height:10px;margin:0 0 0 -10px;background:no-repeat center top;background-position-y:0}.ps629wySAy4E_lACVziA:hover{background-position-y:-10px}`, "",{"version":3,"sources":["webpack://./src/components/button/textIcon/textIconBtn.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,yBAAA,CACA,wBAAA,CACA,iBAAA,CACA,4CAAA,CACA,cAAA,CAGE,kDACE,2BAAA,CAKN,sBACE,eAAA,CACA,gBAAA,CACA,UAAA,CAGF,sBACE,6BAAA,CACA,WAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,+BAAA,CACA,uBAAA,CAEA,4BACE,2BAAA","sourcesContent":[".buttonWithIcon {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  min-width: 148px;\r\n  height: 30px;\r\n  font: inherit;\r\n  color: var(--default-dark);\r\n  border: 1px solid #bec3ca;\r\n  border-radius: 3px;\r\n  background: linear-gradient(#fdfdfd, #f6f6f9);\r\n  cursor: pointer;\r\n\r\n  &:hover {\r\n    .textBtnIcon {\r\n      background-position-y: -10px;\r\n    }\r\n  }\r\n}\r\n\r\n.btnText {\r\n  text-align: left;\r\n  padding-left: 6px;\r\n  width: 92px;\r\n}\r\n\r\n.divider {\r\n  border-left: 1px solid #bec3ca;\r\n  height: 28px;\r\n}\r\n\r\n.textBtnIcon {\r\n  width: 21px;\r\n  height: 10px;\r\n  margin: 0 0 0 -10px;\r\n  background: no-repeat center top;\r\n  background-position-y: 0;\r\n\r\n  &:hover {\r\n    background-position-y: -10px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWithIcon": `eB0y8oyCrUitggGgxzI5`,
	"textBtnIcon": `ps629wySAy4E_lACVziA`,
	"btnText": `PiWZDzeH3YkvQ9AZ2scD`,
	"divider": `IaK62O6kHRiXA07hhcMt`
};
export default ___CSS_LOADER_EXPORT___;
