var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback, useMemo } from 'react';
import { DEFAULT_INTERVALS, SCHEDULER, } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/constant';
export const useScheduleSetup = ({ formData, onUpdateForm }) => {
    const onChangeSchedulerRadio = useCallback((type) => {
        onUpdateForm({
            cycle: Object.assign({ type }, DEFAULT_INTERVALS[type]),
        });
    }, [onUpdateForm]);
    const scheduleOptions = useMemo(() => {
        switch (formData.cycle.type) {
            case SCHEDULER.EVERY_MINUTE:
                return Array.from({ length: 60 }, (_, i) => ({ label: `${i}분`, value: i, type: 'minute' }));
            case SCHEDULER.HOURLY:
                return Array.from({ length: 24 }, (_, i) => ({ label: `${i}시간`, value: i, type: 'hour' }));
            case SCHEDULER.DAILY:
                return Array.from({ length: 24 }, (_, i) => ({ label: `${i}시`, value: i, type: 'hour' }));
            case SCHEDULER.WEEKLY:
                return ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => ({
                    label: ['월', '화', '수', '목', '금', '토', '일'][i],
                    value: day,
                    type: 'dayOfWeek',
                }));
            case SCHEDULER.MONTHLY:
                return Array.from({ length: 30 }, (_, i) => ({ label: `${i + 1}일`, value: i + 1, type: 'day' }));
            default:
                return [];
        }
    }, [formData.cycle.type]);
    const onChangeSelect = useCallback((option) => {
        if (!option) {
            onUpdateForm({
                cycle: Object.assign({ type: formData.cycle.type }, DEFAULT_INTERVALS[formData.cycle.type]),
            });
            return;
        }
        onUpdateForm({
            cycle: Object.assign(Object.assign({}, formData.cycle), { [option.type]: option.value }),
        });
    }, [formData.cycle.type, onUpdateForm]);
    const cycleValue = useMemo(() => {
        const _a = formData.cycle, { type } = _a, values = __rest(_a, ["type"]);
        const key = Object.keys(values)[0];
        const value = values[key];
        if (value === undefined)
            return null;
        return (scheduleOptions.find((option) => (type === 'weekly' ? value === option.value : Number(value) === option.value)) ||
            null);
    }, [scheduleOptions, formData.cycle]);
    return {
        scheduleOptions,
        cycleValue,
        onChangeSchedulerRadio,
        onChangeSelect,
    };
};
