export const API_URL = '/api/v1/public-data/data/data-request';
export const APPLY_STATUS = {
    WAITING: { label: '승인대기', value: 'WAITING', color: 'orange' },
    APPROVED: { label: '승인완료', value: 'APPROVED', color: 'blue' },
    REJECTED: { label: '승인거절', value: 'REJECTED', color: 'red' },
};
export const FORM_VALIDATE = {
    title: {
        notNull: true,
        length: 100,
        fieldName: '제목',
    },
    orgKorName: {
        notNull: true,
        fieldName: '기관명',
    },
    homepageUrl: {
        notNull: true,
        fieldName: '홈페이지 URL',
    },
    requestDescription: {
        notNull: true,
        fieldName: '신청 사유',
    },
};
