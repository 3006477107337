// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N5w_3gCqvhUpdjzdy1c_{width:100%;border-collapse:collapse}.N5w_3gCqvhUpdjzdy1c_ .pkhXqjmJENCNZjIMlTd_{position:sticky;top:0;height:50px;font-size:15px;background-color:#fff}.N5w_3gCqvhUpdjzdy1c_ .pkhXqjmJENCNZjIMlTd_ .DqD2DvDWeTy5BKiwuR8i :nth-child(1){width:70%}.N5w_3gCqvhUpdjzdy1c_ tr:last-of-type{border-bottom:0}.Czo9M0ljQkjXHAcj3NeR{height:50px;border-bottom:1px solid #ededed;cursor:pointer;text-align:center}.Czo9M0ljQkjXHAcj3NeR :nth-child(1){width:70%;text-align:left}.Czo9M0ljQkjXHAcj3NeR :nth-child(1):hover{color:#0f7fff;transition:all .2s ease-out}.Czo9M0ljQkjXHAcj3NeR :last-child{width:15%}.Czo9M0ljQkjXHAcj3NeR .XZwfwKDEeJq1kPAxWYcz{font-size:15px}`, "",{"version":3,"sources":["webpack://./src/components/frontTable/components/table.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,wBAAA,CAEA,4CACE,eAAA,CACA,KAAA,CACA,WAAA,CACA,cAAA,CACA,qBAAA,CAGE,gFACE,SAAA,CAKN,sCACE,eAAA,CAIJ,sBACE,WAAA,CACA,+BAAA,CACA,cAAA,CACA,iBAAA,CAEA,oCACE,SAAA,CACA,eAAA,CAEE,0CACE,aAAA,CACA,2BAAA,CAIN,kCACE,SAAA,CAGF,4CACE,cAAA","sourcesContent":[".table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n\r\n  .tableHeader {\r\n    position: sticky;\r\n    top: 0;\r\n    height: 50px;\r\n    font-size: 15px;\r\n    background-color: #fff;\r\n\r\n    .headerStyle {\r\n      :nth-child(1) {\r\n        width: 70%;\r\n      }\r\n    }\r\n  }\r\n\r\n  tr:last-of-type {\r\n    border-bottom: 0;\r\n  }\r\n}\r\n\r\n.rowStyle {\r\n  height: 50px;\r\n  border-bottom: 1px solid #ededed;\r\n  cursor: pointer;\r\n  text-align: center;\r\n\r\n  :nth-child(1) {\r\n    width: 70%;\r\n    text-align: left;\r\n\r\n      &:hover {\r\n        color: #0f7fff;\r\n        transition: all 0.2s ease-out;\r\n      }\r\n  }\r\n\r\n  :last-child {\r\n    width: 15%;\r\n  }\r\n\r\n  .tdStyle {\r\n    font-size: 15px;\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `N5w_3gCqvhUpdjzdy1c_`,
	"tableHeader": `pkhXqjmJENCNZjIMlTd_`,
	"headerStyle": `DqD2DvDWeTy5BKiwuR8i`,
	"rowStyle": `Czo9M0ljQkjXHAcj3NeR`,
	"tdStyle": `XZwfwKDEeJq1kPAxWYcz`
};
export default ___CSS_LOADER_EXPORT___;
