import moment from "moment/moment";
export const COL_DEF = [
    { headerName: '이름', field: 'name' },
    { headerName: '상태', field: 'state' },
    { headerName: '위치', field: 'zoneName' },
    { headerName: '운영체제', field: 'templateName' },
    { headerName: '사양', field: 'serviceOfferingName' },
    { headerName: '사설 IP', field: 'ipAddress' },
    { headerName: '추가 사설 IP', field: 'secondaryIp' },
    { headerName: '생성일시',
        field: 'created',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss')
    }
];
