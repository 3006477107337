// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WE3ZhuJkCXBmv9jyTMjq{border-top:1px solid #d8d3d2}.WE3ZhuJkCXBmv9jyTMjq .v0sqngn51TuT1pbm9jVz{display:flex;justify-content:space-between;padding:50px 24px 50px 24px;width:100%;max-width:1488px;margin:0 auto}.WE3ZhuJkCXBmv9jyTMjq .v0sqngn51TuT1pbm9jVz a,.WE3ZhuJkCXBmv9jyTMjq .v0sqngn51TuT1pbm9jVz p{color:#7e7e7e}.WE3ZhuJkCXBmv9jyTMjq .v0sqngn51TuT1pbm9jVz .EL4Qq82EMW81MnRGWE9i img{width:240px}.WE3ZhuJkCXBmv9jyTMjq .v0sqngn51TuT1pbm9jVz .D8yTsklri2aNlRLdhibS{margin-top:20px}.WE3ZhuJkCXBmv9jyTMjq .v0sqngn51TuT1pbm9jVz .D8yTsklri2aNlRLdhibS a:first-child{padding-left:0}.WE3ZhuJkCXBmv9jyTMjq .v0sqngn51TuT1pbm9jVz .D8yTsklri2aNlRLdhibS a{display:inline-block;padding:10px 25px;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/layouts/main/_components/footer/Footer.module.scss"],"names":[],"mappings":"AAAA,sBACE,4BAAA,CAEA,4CACE,YAAA,CACA,6BAAA,CACA,2BAAA,CACA,UAAA,CACA,gBAAA,CACA,aAAA,CAEA,4FACE,aAAA,CAIA,sEACE,WAAA,CAKJ,kEACE,eAAA,CAEA,gFACE,cAAA,CAGF,oEACE,oBAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":[".footer {\r\n  border-top: 1px solid #d8d3d2;\r\n\r\n  .contentWrapper {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    padding: 50px 24px 50px 24px;\r\n    width: 100%;\r\n    max-width: 1488px;\r\n    margin: 0 auto;\r\n\r\n    a, p {\r\n      color: #7e7e7e;\r\n    }\r\n\r\n    .footerLogo {\r\n      img {\r\n        width: 240px;\r\n      }\r\n\r\n    }\r\n\r\n    .footerMenu {\r\n      margin-top: 20px;\r\n\r\n      a:first-child {\r\n        padding-left: 0;\r\n      }\r\n\r\n      a {\r\n        display: inline-block;\r\n        padding: 10px 25px;\r\n        white-space: nowrap;\r\n      }\r\n    }\r\n\r\n\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `WE3ZhuJkCXBmv9jyTMjq`,
	"contentWrapper": `v0sqngn51TuT1pbm9jVz`,
	"footerLogo": `EL4Qq82EMW81MnRGWE9i`,
	"footerMenu": `D8yTsklri2aNlRLdhibS`
};
export default ___CSS_LOADER_EXPORT___;
