var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { PUBLIC_DATA_BASE_URL } from '@/constants';
import { DATA_API_URL } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/constant';
import axiosUtil, { requestFn } from '@/utils/axios';
export const getInitProcessData = (params) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosUtil({
        url: `${DATA_API_URL}/dataset/init`,
        method: 'POST',
        baseURL: PUBLIC_DATA_BASE_URL,
        data: params.data,
        signal: params.signal,
    });
    return response.data;
});
export const getDataSet = (data) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosUtil({
        url: `${DATA_API_URL}/dataset/read`,
        method: 'POST',
        baseURL: PUBLIC_DATA_BASE_URL,
        data,
    });
    return response.data;
});
export const getProcessSelectionList = ({ signal }) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosUtil({
        url: `${DATA_API_URL}/work/name/list`,
        method: 'GET',
        baseURL: PUBLIC_DATA_BASE_URL,
        signal,
    });
    return response;
});
export const processDelete = ({ defaultData: { datasetInfo, column }, contents, }) => {
    const { dataset, datasetDtypes, datasetIndex, datasetId } = datasetInfo;
    const bodyData = {
        dataset: JSON.stringify(dataset),
        dataset_dtypes: datasetDtypes,
        dataset_index: datasetIndex,
        dataset_id: datasetId,
        contents: { column, options: contents.type === 'duplicated' ? 'drop' : undefined },
    };
    return requestFn(`${DATA_API_URL}/column/${contents.type}`, { method: 'POST', data: bodyData });
};
export const processNull = ({ defaultData: { datasetInfo, column }, contents, }) => {
    const { dataset, datasetDtypes, datasetIndex, datasetId } = datasetInfo;
    const bodyData = {
        dataset: JSON.stringify(dataset),
        dataset_dtypes: datasetDtypes,
        dataset_index: datasetIndex,
        dataset_id: datasetId,
        contents: Object.assign({ column }, contents),
    };
    return requestFn(`${DATA_API_URL}/column/null`, { method: 'POST', data: bodyData });
};
export const processUnitConversion = (_a) => {
    var { defaultData: { datasetInfo, column } } = _a, _b = _a.contents, { options } = _b, contentsRest = __rest(_b, ["options"]);
    const { dataset, datasetDtypes, datasetIndex, datasetId } = datasetInfo;
    const bodyData = {
        dataset: JSON.stringify(dataset),
        dataset_dtypes: datasetDtypes,
        dataset_index: datasetIndex,
        dataset_id: datasetId,
        contents: Object.assign({ column, options }, contentsRest),
    };
    return requestFn(`${DATA_API_URL}/column/unit`, { method: 'POST', data: bodyData });
};
export const processDateConversion = ({ defaultData: { datasetInfo, column }, contents, }) => {
    const { dataset, datasetDtypes, datasetIndex, datasetId } = datasetInfo;
    const bodyData = {
        dataset: JSON.stringify(dataset),
        dataset_dtypes: datasetDtypes,
        dataset_index: datasetIndex,
        dataset_id: datasetId,
        contents: Object.assign({ column, data_type: 'datetime' }, contents),
    };
    return requestFn(`${DATA_API_URL}/column/astype`, { method: 'POST', data: bodyData });
};
export const processColumnSplitter = ({ defaultData: { datasetInfo, column }, contents, }) => {
    const { dataset, datasetDtypes, datasetIndex, datasetId } = datasetInfo;
    const bodyData = {
        dataset: JSON.stringify(dataset),
        dataset_dtypes: datasetDtypes,
        dataset_index: datasetIndex,
        dataset_id: datasetId,
        contents: Object.assign({ column }, contents),
    };
    return requestFn(`${DATA_API_URL}/column/split`, { method: 'POST', data: bodyData });
};
export const processTextConversion = ({ defaultData: { datasetInfo, column }, contents, }) => {
    const { dataset, datasetDtypes, datasetIndex, datasetId } = datasetInfo;
    const bodyData = {
        dataset: JSON.stringify(dataset),
        dataset_dtypes: datasetDtypes,
        dataset_index: datasetIndex,
        dataset_id: datasetId,
        contents: Object.assign({ column }, contents),
    };
    return requestFn(`${DATA_API_URL}/column/replace`, { method: 'POST', data: bodyData });
};
export const undoProcess = (data) => {
    return requestFn(`${DATA_API_URL}/undo`, { method: 'POST', data });
};
export const redoProcess = (data) => {
    return requestFn(`${DATA_API_URL}/redo`, { method: 'POST', data });
};
export const getProcessWorkHistoryList = (params) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosUtil({
        url: `${DATA_API_URL}/work/history/detail`,
        params,
        method: 'GET',
        baseURL: PUBLIC_DATA_BASE_URL,
    });
    return response;
});
export const resetProcess = (data) => {
    return requestFn(`${DATA_API_URL}/reset`, { method: 'POST', data });
};
