export const COL_DEF = [
    { headerName: '이름', field: 'name' },
    { headerName: '설명', field: 'description' },
    {
        headerName: '크기',
        field: 'size',
        valueFormatter: (params) => `${params.value.size} ${params.value.sizeName}`
    },
    { headerName: '상태', field: 'status' },
    { headerName: '타입', field: 'volumeType' },
    { headerName: '연결 정보', field: '모름' },
    { headerName: '가용성 영역', field: 'availabilityZone' },
];
