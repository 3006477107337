export const COL_DEF = [
    { headerName: '이름', field: 'name' },
    { headerName: '타입', field: 'loadbalancerType' },
    { headerName: 'IP 주소', field: 'vipAddress' },
    { headerName: '로드 밸런서 포인트, 인스턴스 포트', field: '' },
    { headerName: '프로토콜', field: '' },
    { headerName: 'IP 접근제어 타입', field: '' },
    { headerName: '네트워크', field: '' },
    { headerName: '상태', field: 'operatingStatus' },
];
