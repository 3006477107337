import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loginImage from '@/assets/images/logo/console3.png';
import { useCallback, useNavigate } from '@/hooks';
import { useCommonStore } from '@/stores';
import { NavMenu, NavUserProfile } from './_components';
import styles from './navbar.module.scss';
export default function NavigationBar() {
    const isClose = useCommonStore((state) => state.isClose);
    const navigate = useNavigate();
    const onClickGoToMainPage = useCallback(() => {
        navigate('/');
    }, [navigate]);
    return (_jsxs("div", Object.assign({ className: `${styles.sideNavbar} ${isClose ? styles.isClose : ''}` }, { children: [_jsx("div", Object.assign({ className: styles.logoWrapper }, { children: !isClose ? (_jsx("div", Object.assign({ className: `${styles.logo} ${isClose ? styles.closed : styles.opened}`, onClick: onClickGoToMainPage }, { children: _jsx("img", { src: loginImage, alt: "tabcloudit_logo" }) }))) : (_jsx("div", Object.assign({ className: styles.closedLogo, onClick: () => navigate('/') }, { children: _jsx("h1", { children: "T" }) }))) })), _jsx(NavUserProfile, {}), _jsx(NavMenu, {})] })));
}
