var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, RadioGroupButton } from '@innogrid-ui/core';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { useScheduleSetup } from '@/hooks/useScheduleSetup';
import { DATA_API_URL } from '@/pages/console/myData/applicationDataManagement/constant';
import { CYCLE_OPTIONS, DEFAULT_INTERVALS, SCHEDULER, STATUS_OPTIONS, } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/constant';
import { requestFn, swal } from '@/utils';
import styles from './ScheduleEditModal.module.scss';
const selectStyles = {
    menuPortal: (base) => (Object.assign(Object.assign({}, base), { zIndex: 99999 })),
    control: (base) => (Object.assign(Object.assign({}, base), { zIndex: 1 })),
    menu: (base) => (Object.assign(Object.assign({}, base), { zIndex: 99999 })),
};
export default function ScheduleEditModal({ isOpen, schemaName, onRequestClose, displayTableName, scheduleData, }) {
    const [formData, setFormData] = useState(() => {
        var _a, _b;
        const scheduleInfo = scheduleData[0];
        const schedulerKey = (_b = (_a = scheduleInfo === null || scheduleInfo === void 0 ? void 0 : scheduleInfo.cycle) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.toUpperCase();
        const cycleType = SCHEDULER[schedulerKey] || SCHEDULER.HOURLY;
        const _c = (scheduleInfo === null || scheduleInfo === void 0 ? void 0 : scheduleInfo.cycle) || {}, { type } = _c, cycleValues = __rest(_c, ["type"]);
        return {
            name: (scheduleInfo === null || scheduleInfo === void 0 ? void 0 : scheduleInfo.name) || displayTableName,
            cycle: Object.assign(Object.assign({ type: cycleType }, (schedulerKey ? DEFAULT_INTERVALS[cycleType] : DEFAULT_INTERVALS[SCHEDULER.HOURLY])), cycleValues),
            dataset_id: (scheduleInfo === null || scheduleInfo === void 0 ? void 0 : scheduleInfo.datasetId) || schemaName,
            is_active: (scheduleInfo === null || scheduleInfo === void 0 ? void 0 : scheduleInfo.isActive) === 'true',
        };
    });
    const onUpdateForm = useCallback((updates) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), updates)));
    }, []);
    const { scheduleOptions, cycleValue, onChangeSchedulerRadio, onChangeSelect } = useScheduleSetup({
        formData,
        onUpdateForm,
    });
    const onMutationSuccess = useCallback((response) => {
        var _a, _b, _c;
        if ((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.code) === null || _b === void 0 ? void 0 : _b.startsWith('F-')) {
            swal(((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.message) || '스케줄 수정에 실패했습니다.');
            return;
        }
        swal('스케줄이 수정되었습니다.').then(onRequestClose);
    }, [onRequestClose]);
    const onMutationError = useCallback((error) => {
        var _a;
        swal(((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.message) || '스케줄 수정에 실패했습니다.');
    }, []);
    const { mutate: updateSchedule } = useMutation({
        mutationFn: () => requestFn(`${DATA_API_URL}/job/insert`, {
            method: 'POST',
            data: formData,
        }),
        onSuccess: onMutationSuccess,
        onError: onMutationError,
    });
    const onSave = useCallback(() => {
        if (formData.is_active && !cycleValue) {
            swal('주기를 선택해주세요.');
            return;
        }
        updateSchedule();
    }, [formData.is_active, cycleValue, updateSchedule]);
    const onChangeStatus = useCallback((value) => {
        onUpdateForm({ is_active: value });
    }, [onUpdateForm]);
    return (_jsx(Modal, Object.assign({ title: "\uC2A4\uCF00\uC904 \uC218\uC815", isOpen: isOpen, action: onSave, onRequestClose: onRequestClose, className: styles.scheduleEditModal }, { children: _jsx("div", Object.assign({ className: styles.setupBox }, { children: _jsxs("ul", { children: [_jsx("li", { children: _jsxs("div", Object.assign({ className: styles.dataBox }, { children: [_jsx("p", Object.assign({ className: styles.dataSetTit }, { children: "\uC2A4\uCF00\uC904 \uBC30\uCE58 \uC124\uC815" })), _jsx("div", Object.assign({ className: styles.radioBox }, { children: _jsx(RadioGroupButton, { id: "cycle", options: CYCLE_OPTIONS, onValueChange: onChangeSchedulerRadio, value: formData.cycle.type }) })), _jsx("div", Object.assign({ className: styles.boxWrapper }, { children: _jsx("div", Object.assign({ className: styles.selectBox }, { children: _jsx(Select, { options: scheduleOptions, placeholder: "\uC120\uD0DD", value: cycleValue, onChange: onChangeSelect, isClearable: true, menuPortalTarget: document.body, classNamePrefix: "react-select", styles: selectStyles }) })) }))] })) }), _jsx("li", { children: _jsxs("div", Object.assign({ className: styles.dataBox }, { children: [_jsx("p", Object.assign({ className: styles.dataTitle }, { children: "\uC0C1\uD0DC" })), _jsx("div", Object.assign({ className: styles.dataBox }, { children: _jsx("div", Object.assign({ className: styles.radioBox }, { children: _jsx(RadioGroupButton, { id: "is_active", options: STATUS_OPTIONS, onValueChange: onChangeStatus, value: formData.is_active }) })) }))] })) })] }) })) })));
}
