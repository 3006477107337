// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V3DCpal4vYn1_VNv1iaW{display:flex;justify-content:space-between;align-items:center;height:4em;font-size:18px}.V3DCpal4vYn1_VNv1iaW button{width:150px;height:45px;font-size:14px}.eUzYYf0qVmOGJALtWYXo{color:#444}.eUzYYf0qVmOGJALtWYXo strong{color:#0084ff;margin:0 4px}._mn7cDxikeddWUFBxWLe{width:100%;border-top:2px solid #222}._mn7cDxikeddWUFBxWLe .qYDxpJR0SWpS8DWaVGgD{display:grid;grid-template-columns:1fr 1fr;grid-gap:5px;margin-top:5px}.zM89QURm7T2xmAUsPO2j{display:flex;justify-content:center;align-items:center;margin-top:30px;color:#a0a0a0;font-size:17px;font-weight:400}`, "",{"version":3,"sources":["webpack://./src/pages/console/myData/requestData/RequestDataPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CACA,cAAA,CAEA,6BACE,WAAA,CACA,WAAA,CACA,cAAA,CAIJ,sBACE,UAAA,CAEA,6BACE,aAAA,CACA,YAAA,CAIJ,sBACE,UAAA,CACA,yBAAA,CAEA,4CACE,YAAA,CACA,6BAAA,CACA,YAAA,CACA,cAAA,CAIJ,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".boardListHeader {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  height: 4em;\r\n  font-size: 18px;\r\n\r\n  button {\r\n    width: 150px;\r\n    height: 45px;\r\n    font-size: 14px;\r\n  }\r\n}\r\n\r\n.boardCount {\r\n  color: #444;\r\n\r\n  strong {\r\n    color: #0084ff;\r\n    margin: 0 4px;\r\n  }\r\n}\r\n\r\n.dataListWrapper {\r\n  width: 100%;\r\n  border-top: 2px solid #222;\r\n\r\n  .dataList {\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr;\r\n    grid-gap: 5px;\r\n    margin-top: 5px;\r\n  }\r\n}\r\n\r\n.noData {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin-top: 30px;\r\n  color: #a0a0a0;\r\n  font-size: 17px;\r\n  font-weight: 400;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boardListHeader": `V3DCpal4vYn1_VNv1iaW`,
	"boardCount": `eUzYYf0qVmOGJALtWYXo`,
	"dataListWrapper": `_mn7cDxikeddWUFBxWLe`,
	"dataList": `qYDxpJR0SWpS8DWaVGgD`,
	"noData": `zM89QURm7T2xmAUsPO2j`
};
export default ___CSS_LOADER_EXPORT___;
