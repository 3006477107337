// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ynp39Q1H19pz3GOIjz1K{display:flex;justify-content:space-between;align-items:center;height:4em;font-size:18px}.kFS7fc8kq96s_HlDfpJk{color:#444}.kFS7fc8kq96s_HlDfpJk strong{color:#0084ff;margin:0 4px}.MHlFYCvFSDlpaZ2mtVIK{color:#222;border-top:2px solid #222;margin-bottom:4px}.MHlFYCvFSDlpaZ2mtVIK ul{display:flex;flex-wrap:wrap;justify-content:space-between}._VzAW9A8dwTRLWTYQh39{display:flex;justify-content:center;align-items:center;margin-top:30px}`, "",{"version":3,"sources":["webpack://./src/pages/console/myData/applicationDataManagement/ApplicationDataManagementPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CACA,cAAA,CAGF,sBACE,UAAA,CAEA,6BACE,aAAA,CACA,YAAA,CAIJ,sBACE,UAAA,CACA,yBAAA,CACA,iBAAA,CAEA,yBACE,YAAA,CACA,cAAA,CACA,6BAAA,CAIJ,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".boardListHeader {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  height: 4em;\r\n  font-size: 18px;\r\n}\r\n\r\n.boardCount {\r\n  color: #444;\r\n\r\n  strong {\r\n    color: #0084ff;\r\n    margin: 0 4px;\r\n  }\r\n}\r\n\r\n.dataListWrapper {\r\n  color: #222;\r\n  border-top: 2px solid #222;\r\n  margin-bottom: 4px;\r\n\r\n  ul {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: space-between;\r\n  }\r\n}\r\n\r\n.noData {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin-top: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boardListHeader": `Ynp39Q1H19pz3GOIjz1K`,
	"boardCount": `kFS7fc8kq96s_HlDfpJk`,
	"dataListWrapper": `MHlFYCvFSDlpaZ2mtVIK`,
	"noData": `_VzAW9A8dwTRLWTYQh39`
};
export default ___CSS_LOADER_EXPORT___;
