export const getCookie = (name) => {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2)
        return parts.pop().split(';').shift();
};
export const setCookie = (name, value, options = {}) => {
    options = Object.assign({ path: '/' }, options);
    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }
    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    for (const optionKey in options) {
        updatedCookie += '; ' + optionKey;
        const optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += '=' + optionValue;
        }
    }
    document.cookie = updatedCookie;
};
export const deleteCookie = (name) => {
    setCookie(name, '', { 'max-age': -1 });
};
export const clearAllCookies = () => {
    document.cookie.split(';').forEach((cookie) => {
        const [name] = cookie.split('=');
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
};
export const deleteTokenCookies = () => {
    deleteCookie('portalToken');
    deleteCookie('portalRefreshToken');
    deleteCookie('portalPaasToken');
    deleteCookie('portalPaasRefreshToken');
};
