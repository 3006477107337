import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import { ClientPagingTable, SelectBox } from '@/components';
import { useCallback, useClientPagingTable, useEffect, useReactSelect, useState } from '@/hooks';
import { ConsoleTablePageLayout } from '@/layouts';
import { COL_DEF } from './constant';
import styles from './NhnFloatingIpPage.module.scss';
export default function NhnFloatingIpPage() {
    const [cluster, setCluster] = useState('');
    const { regionEnumList } = useReactSelect([
        {
            queryKey: [`/api/v1/nhn/region`],
            key: 'regionEnumList',
            select: (data) => (data === null || data === void 0 ? void 0 : data.regionEnumList) &&
                data.regionEnumList.map((d) => ({
                    label: d,
                    value: d,
                })),
            labelValue: ['name', 'uuid'],
            noContentKey: true,
            isSort: false
        },
    ]);
    const tableProps = useClientPagingTable({
        queryKey: [`/api/v1/nhn/network/floatingip?region=${cluster}`],
        select: (data) => {
            return data.floatingIps.map((el) => {
                var _a;
                return {
                    floatingIpAddress: el === null || el === void 0 ? void 0 : el.floatingIpAddress,
                    status: (_a = el === null || el === void 0 ? void 0 : el.metadata) === null || _a === void 0 ? void 0 : _a.status,
                };
            });
        },
        enabled: !!cluster,
    });
    useEffect(() => {
        var _a, _b, _c;
        if (isEmpty(regionEnumList.options))
            (_b = (_a = tableProps.tableRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.showNoRowsOverlay();
        else if (isEmpty(cluster) && regionEnumList.options)
            setCluster(((_c = regionEnumList === null || regionEnumList === void 0 ? void 0 : regionEnumList.options[0]) === null || _c === void 0 ? void 0 : _c.value) || '');
    }, [regionEnumList]);
    const onChangeSelect = useCallback(({ value }) => {
        setCluster(value);
    }, []);
    return (_jsx(_Fragment, { children: _jsx(ConsoleTablePageLayout, Object.assign({ breadcrumb: ['CSP 자원조회', 'NHN', 'Network', 'Floating IP'] }, { children: _jsx(ClientPagingTable, Object.assign({}, tableProps, { height: "100%", columnDefs: COL_DEF }, { children: _jsxs("div", Object.assign({ className: styles.selectWrapper }, { children: [_jsx("span", { children: "\uB9AC\uC804" }), _jsx(SelectBox, Object.assign({}, regionEnumList, { className: styles.selectBox, value: cluster, onChange: onChangeSelect }))] })) })) })) }));
}
