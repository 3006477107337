import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import { Button, Input } from '@/components';
import { useCallback, useMutation, useNiceVerification, useState } from '@/hooks';
import { CHANGE_CONTACT_FORM_VALIDATE, PHONE_API_URL } from '@/pages/console/myInfo/constant';
import { useAuthStore } from '@/stores';
import { getCookie, isFormValidate, parseJwt, requestFn } from '@/utils';
import Modal from '../../_components/Modal';
import styles from './ChangePhoneModal.module.scss';
export default function ChangePhoneModal({ isOpen, setIsOpen }) {
    const setUserInfo = useAuthStore((state) => state.setUserInfo);
    const { onClickNiceVerification } = useNiceVerification({
        onAuthDone: (data) => setData((prev) => (Object.assign(Object.assign({}, prev), data))),
    });
    const [data, setData] = useState({
        seq: parseJwt(getCookie('portalToken')).userseq,
        password: '',
        mobile: '',
        name: '',
        username: '',
        passwordConfirm: '',
        userId: '',
        dateOfBirth: '',
        mobileco: '',
    });
    const { mutate: checkEnabledMutate } = useMutation({
        mutationFn: () => requestFn(PHONE_API_URL, {
            data: Object.assign({ contact: data.mobile }, data),
            method: 'post',
        }),
        onSuccess: (res) => {
            if (res.data.content)
                changeContactMutate();
        },
    });
    const { mutate: changeContactMutate } = useMutation({
        mutationFn: () => requestFn(`${PHONE_API_URL}/change`, {
            data: Object.assign({ contact: data.mobile }, data),
            method: 'post',
        }),
        onSuccess: () => {
            setUserInfo(getCookie('portalToken'));
            setIsOpen(false);
        },
    });
    const onClickSubmit = useCallback(() => {
        if (!isFormValidate(data, CHANGE_CONTACT_FORM_VALIDATE))
            return;
        checkEnabledMutate();
    }, [data]);
    const onChangeInput = useCallback((event) => {
        setData((prev) => (Object.assign(Object.assign({}, prev), { [event.target.id]: event.target.value })));
    }, []);
    return (_jsx(Modal, Object.assign({ title: "\uC5F0\uB77D\uCC98 \uBCC0\uACBD", isOpen: isOpen, setIsOpen: setIsOpen, onClickSubmit: onClickSubmit }, { children: _jsxs("ul", Object.assign({ className: styles.listForm }, { children: [_jsxs("li", { children: [_jsx("label", Object.assign({ htmlFor: "password", className: styles.labelTitle }, { children: "\uBE44\uBC00\uBC88\uD638" })), _jsx(Input, { id: "password", type: "password", placeholder: "\uBE44\uBC00\uBC88\uD638\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.", autoComplete: "off", onChange: onChangeInput })] }), _jsxs("li", { children: [_jsx("label", Object.assign({ htmlFor: "password", className: styles.labelTitle }, { children: "\uBCC0\uACBD\uD560 \uC5F0\uB77D\uCC98" })), _jsxs("div", Object.assign({ className: styles.authorizedWrapper }, { children: [_jsx(Input, { className: isEmpty(data.mobileco) ? styles.error : '', id: "password", type: "text", placeholder: "\uD1B5\uC2E0\uC0AC", autoComplete: "off", disabled: true, value: data.mobileco, onChange: onChangeInput }), _jsx(Input, { className: isEmpty(data.mobile) ? styles.error : '', id: "password", type: "text", placeholder: "\uD734\uB300\uD3F0 \uBC88\uD638", autoComplete: "off", disabled: true, value: data.mobile, onChange: onChangeInput }), _jsx(Button, Object.assign({ onClick: onClickNiceVerification }, { children: "\uC778\uC99D\uD558\uAE30" }))] })), _jsx("div", { children: _jsx(Input, { className: isEmpty(data.username) ? styles.error : '', id: "text", placeholder: "\uC774\uB984", autoComplete: "off", disabled: true, value: data.username, onChange: onChangeInput }) })] })] })) })));
}
