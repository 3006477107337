import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMutation, useState } from '@/hooks';
import { MY_SERVICE_APP_API_URL } from '@/pages/console/myService/constant';
import AppResourceEditBox from '@/pages/console/myService/detail/_components/AppResourceEditBox';
import { swal } from '@/utils';
import { requestFn } from '@/utils/axios';
import styles from './AppResourcePanel.module.scss';
export default function AppResourcePanel(props) {
    const queryClient = useQueryClient();
    const [isInstanceEdit, setIsInstanceEdit] = useState(false);
    const [isMemoryEdit, setIsMemoryEdit] = useState(false);
    const [isDiskEdit, setIsDiskEdit] = useState(false);
    const { mutate } = useMutation({
        mutationFn: (appUpdateDTO) => requestFn(`${MY_SERVICE_APP_API_URL}/updateApp`, {
            method: 'PUT',
            data: appUpdateDTO,
        }),
        onSuccess: (response) => {
            var _a, _b;
            swal((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.msg);
            setIsInstanceEdit(false);
            setIsMemoryEdit(false);
            setIsDiskEdit(false);
            if (((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.result) === 'SUCCESS') {
                queryClient.invalidateQueries([`${MY_SERVICE_APP_API_URL}/getAppDetail`]);
            }
        },
    });
    const onClickUpdateApp = useCallback((editValue) => {
        mutate({
            guid: props === null || props === void 0 ? void 0 : props.appid,
            name: props === null || props === void 0 ? void 0 : props.appName,
            instances: isInstanceEdit ? editValue : props === null || props === void 0 ? void 0 : props.appInstance,
            memory: isMemoryEdit ? editValue : props === null || props === void 0 ? void 0 : props.appMemory,
            diskQuota: isDiskEdit ? editValue : props === null || props === void 0 ? void 0 : props.appDisk,
        });
    }, [props, isInstanceEdit, isMemoryEdit, isDiskEdit]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(AppResourceEditBox, { title: "Instance", val: props === null || props === void 0 ? void 0 : props.appInstance, maxVal: props === null || props === void 0 ? void 0 : props.appInstanceMax, unit: '개', isEditable: !(isMemoryEdit || isDiskEdit), isEdit: isInstanceEdit, setIsEdit: setIsInstanceEdit, onClickUpdateApp: onClickUpdateApp }), _jsx(AppResourceEditBox, { title: "CPU", val: props === null || props === void 0 ? void 0 : props.appCpu, maxVal: 100, unit: '%', isEdit: false }), _jsx(AppResourceEditBox, { title: "Memory", val: props === null || props === void 0 ? void 0 : props.appMemory, maxVal: props.appMemoryMax, unit: (props === null || props === void 0 ? void 0 : props.appMemoryUnit) || '', isEditable: !(isInstanceEdit || isDiskEdit), isEdit: isMemoryEdit, setIsEdit: setIsMemoryEdit, onClickUpdateApp: onClickUpdateApp }), _jsx(AppResourceEditBox, { title: "Disk", val: props.appDisk, maxVal: props === null || props === void 0 ? void 0 : props.appDiskMax, unit: (props === null || props === void 0 ? void 0 : props.appDiskUnit) || '', isEditable: !(isInstanceEdit || isMemoryEdit), isEdit: isDiskEdit, setIsEdit: setIsDiskEdit, onClickUpdateApp: onClickUpdateApp })] })));
}
