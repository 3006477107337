import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@/components';
import { useCallback, useMutation, useQueryClient, useState } from '@/hooks';
import RequestDataModal from '@/pages/console/myData/requestData/_components/RequestDataModal';
import { API_URL, APPLY_STATUS } from '@/pages/console/myData/requestData/constant';
import { requestFn, swalConfirm } from '@/utils';
import styles from './DataBox.module.scss';
export default function DataBox({ data }) {
    const queryClient = useQueryClient();
    const [isOpen, setIsOpen] = useState(false);
    const { mutate } = useMutation({
        mutationFn: (seq) => requestFn(`${API_URL}/${seq}/delete`, {
            method: 'DELETE',
        }),
        onSuccess: () => queryClient.invalidateQueries([API_URL + '/list']),
    });
    const onClickDelete = useCallback(() => {
        swalConfirm('삭제하시겠습니까?').then((res) => {
            if (res.isConfirmed)
                mutate(data.seq);
        });
    }, [data]);
    return (_jsxs("div", Object.assign({ className: styles.dataBox }, { children: [data.status === APPLY_STATUS.WAITING.value && (_jsxs("div", Object.assign({ className: styles.buttonWrapper }, { children: [_jsx(Button, Object.assign({ className: styles.update, onClick: () => setIsOpen(true) }, { children: "\uC218\uC815" })), _jsx(Button, Object.assign({ className: styles.delete, onClick: onClickDelete }, { children: "\uC0AD\uC81C" }))] }))), _jsx("h4", { children: data.title }), _jsxs("p", Object.assign({ className: styles.text }, { children: ["\uC2B9\uC778\uC0C1\uD0DC:", _jsx("span", Object.assign({ style: { color: APPLY_STATUS[data.status].color } }, { children: _jsx("strong", { children: APPLY_STATUS[data.status].label }) }))] })), _jsxs("p", Object.assign({ className: styles.text }, { children: ["\uC694\uCCAD \uC77C\uC790:", _jsx("span", { children: data.createdAt }), data.status === APPLY_STATUS.APPROVED.value && (_jsxs(_Fragment, { children: [_jsx("span", { className: styles.line }), "\uC2B9\uC778 \uC77C\uC790:", _jsx("span", { children: data.updatedAt })] }))] })), data.status === APPLY_STATUS.REJECTED.value ? (_jsxs("p", Object.assign({ className: styles.text }, { children: ["\uAC70\uC808 \uC0AC\uC720:", _jsx("span", { children: data.rejectionReason })] }))) : (_jsxs("p", Object.assign({ className: styles.text }, { children: ["\uC694\uCCAD \uC0AC\uC720:", _jsx("span", { children: data.requestDescription })] }))), _jsxs("p", Object.assign({ className: styles.text }, { children: ["\uAE30\uAD00\uBA85:", _jsx("span", { children: data.orgKorName })] })), _jsxs("p", Object.assign({ className: styles.text }, { children: ["\uD648\uD398\uC774\uC9C0 URL:", _jsx("span", { children: _jsx("a", Object.assign({ href: data.homepageUrl, target: "_blank", rel: "noreferrer" }, { children: data.homepageUrl })) })] })), isOpen && _jsx(RequestDataModal, { isCreate: false, isOpen: isOpen, setIsOpen: setIsOpen, seq: data.seq })] })));
}
