import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FrontTable } from '@/components';
import { useCallback, useFrontTable, useNavigate } from '@/hooks';
import { DefaultLayout } from '@/layouts';
import { getCookie, swal } from '@/utils';
import { QnaColDef } from './constant';
import { BASE_URL } from "@/constants";
export default function QnaPage() {
    const navigate = useNavigate();
    const tableProps = useFrontTable({
        apiUrl: `${BASE_URL}/api/v1/cmp/community/qna`,
    });
    const onClickCreate = useCallback(() => {
        if (!getCookie('portalToken'))
            return swal('로그인이 필요한 서비스입니다.');
        navigate('/qna/create');
        window.scrollTo({ top: 0 });
    }, [navigate]);
    return (_jsx(_Fragment, { children: _jsx(DefaultLayout, Object.assign({ breadcrumb: { Home: '/', 'Q&A': '' } }, { children: _jsx(FrontTable, Object.assign({}, tableProps, { columns: QnaColDef, onClickCreate: onClickCreate })) })) }));
}
