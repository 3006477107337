import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, Modal, Textarea } from '@innogrid-ui/core';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { DATA_API_URL } from '@/pages/console/myData/applicationDataManagement/constant';
import { requestFn, swal } from '@/utils';
import styles from './DataApplyModal.module.scss';
const INITIAL_FORM_DATA = {
    title: '',
    status: 'DATA_REG_REQ_CD',
    public_yn: 'N',
    requestDescription: '',
};
export default function DataApplyModal({ id, isOpen, refetch, onRequestClose }) {
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    // IF_KBI_00013 테이블 스키마 수정
    const { mutate } = useMutation({
        mutationFn: () => requestFn(`${DATA_API_URL}/schema/update?tableName=${id}`, {
            method: 'POST',
            data: formData,
        }),
        onSuccess: (response) => {
            var _a, _b, _c, _d;
            if ((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.code) === null || _b === void 0 ? void 0 : _b.startsWith('F-')) {
                swal((_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : '상태 변경에 실패했습니다.');
                setFormData((prev) => (Object.assign(Object.assign({}, prev), { publicYn: false })));
                return;
            }
            swal('등록 신청여부 상태가 변경되었습니다.').then(() => refetch());
            onRequestClose();
        },
        onError: () => swal('오류가 발생했습니다. 다시 시도해주세요.'),
    });
    const onValidate = useCallback(() => {
        if (!formData.title.trim()) {
            swal('제목을 입력해주세요.');
            return false;
        }
        if (!formData.requestDescription.trim()) {
            swal('목적을 입력해주세요.');
            return false;
        }
        return true;
    }, [formData]);
    const onChange = useCallback((event) => {
        const { id, value } = event.target;
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { [id]: value })));
    }, []);
    const onSubmit = useCallback(() => {
        if (onValidate())
            mutate();
    }, [mutate, onValidate]);
    return (_jsx(Modal, Object.assign({ title: "\uB4F1\uB85D \uC2E0\uCCAD", isOpen: isOpen, action: onSubmit, onRequestClose: onRequestClose, className: styles.dataApplyModal, size: "small" }, { children: _jsxs("div", Object.assign({ className: styles.formContainer }, { children: [_jsxs("div", { children: [_jsx("label", Object.assign({ htmlFor: "title" }, { children: "\uB4F1\uB85D \uC694\uCCAD \uC81C\uBAA9" })), _jsx(Input, { id: "title", value: formData.title, onChange: onChange, placeholder: "\uB4F1\uB85D \uC694\uCCAD \uC81C\uBAA9\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694" })] }), _jsxs("div", { children: [_jsx("label", Object.assign({ htmlFor: "requestDescription" }, { children: "\uB4F1\uB85D \uC694\uCCAD \uBAA9\uC801" })), _jsx(Textarea, { id: "requestDescription", rows: 10, value: formData.requestDescription, onChange: onChange, placeholder: "\uB4F1\uB85D \uC694\uCCAD \uBAA9\uC801\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694", className: styles.description })] })] })) })));
}
