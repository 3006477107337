import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import { ToastViewer } from '@/components';
import PageNavigation from '@/components/pageNavigation/PageNavigation';
import { useCustomQuery, useEffect, useNavigate, useParams, useState } from '@/hooks';
import { DetailLayout } from '@/layouts';
import styles from './ideaDetail.module.scss';
export default function IdeaDetailPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [navigation, setNavigation] = useState({
        prev: {
            title: '',
            link: '',
        },
        next: {
            title: '',
            link: '',
        },
    });
    const { data, isFetching } = useCustomQuery({
        queryKey: [`/api/v1/cmp/community/idea/${id}`],
    });
    useEffect(() => {
        if (data) {
            const { prevIdeaTitle, prevIdeaSeq, nextIdeaTitle, nextIdeaSeq } = data;
            setNavigation({
                prev: {
                    title: prevIdeaTitle,
                    link: `/idea/${prevIdeaSeq}`,
                },
                next: {
                    title: nextIdeaTitle,
                    link: `/idea/${nextIdeaSeq}`,
                },
            });
        }
    }, [data]);
    return (_jsx(_Fragment, { children: _jsx(DetailLayout, Object.assign({ breadcrumb: { Home: '/', 아이디어: '/idea' } }, { children: _jsxs("section", Object.assign({ id: "contetns" }, { children: [_jsx("table", Object.assign({ className: styles.board_list_board_view }, { children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", Object.assign({ className: styles.td_subject }, { children: data === null || data === void 0 ? void 0 : data.title })), _jsx("td", Object.assign({ className: styles.td_date }, { children: moment(data === null || data === void 0 ? void 0 : data.createdAt)
                                            .add(9, 'h')
                                            .format('YYYY-MM-DD HH:mm:ss') }))] }) }) })), _jsx("div", Object.assign({ className: styles.view_contents }, { children: !isFetching && _jsx(ToastViewer, { initialValue: data === null || data === void 0 ? void 0 : data.contents }) })), navigation && _jsx(PageNavigation, { navigation: navigation }), _jsx("div", Object.assign({ className: styles.board_btn_wrap }, { children: _jsx("button", Object.assign({ type: "button", onClick: () => navigate(`/idea`) }, { children: "\uBAA9\uB85D" })) }))] })) })) }));
}
