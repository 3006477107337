import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { Loading } from '@/components';
import { PUBLIC_DATA_BASE_URL } from '@/constants';
import { ConsolePageLayout } from '@/layouts';
import { API_URL } from '@/pages/console/myData/applicationDataManagement/constant';
import axiosUtil from '@/utils/axios';
import DataBox from './_components/DataBox';
import styles from './ApplicationDataManagementPage.module.scss';
export default function ApplicationDataManagementPage() {
    const { data, isFetching } = useQuery({
        queryKey: [`${API_URL}/list`],
        queryFn: ({ queryKey }) => axiosUtil.post(queryKey[0], { baseURL: PUBLIC_DATA_BASE_URL }).then((res) => res.data.content),
    });
    let temp;
    if (isFetching)
        temp = _jsx(Loading, { color: "#268eff" });
    else if (data && data.length === 0)
        temp = _jsx("div", Object.assign({ className: styles.noData }, { children: "\uB370\uC774\uD130\uAC00 \uC874\uC7AC\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4." }));
    return (_jsxs(ConsolePageLayout, Object.assign({ breadcrumb: ['My Data', '사용 신청 데이터 관리'] }, { children: [_jsx("div", Object.assign({ className: styles.boardListHeader }, { children: _jsxs("div", Object.assign({ className: styles.boardCount }, { children: ["\uCD1D", _jsx("strong", { children: data ? data === null || data === void 0 ? void 0 : data.length : 0 }), "\uAC74\uC758 \uC2E0\uCCAD\uB0B4\uC5ED\uC774 \uC788\uC2B5\uB2C8\uB2E4."] })) })), _jsx("div", Object.assign({ className: styles.dataListWrapper }, { children: temp || _jsx("ul", { children: data && (data === null || data === void 0 ? void 0 : data.map((d) => _jsx(DataBox, { data: d }, d.dataId))) }) }))] })));
}
