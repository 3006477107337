import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Input } from '@/components';
import { useCallback, useState } from '@/hooks';
import Modal from '@/pages/console/_components/Modal';
import { FORM_VALIDATE, MY_SERVICE_APP_API_URL } from '@/pages/console/myService/constant';
import { isFormValidate, requestFn, swal, swalConfirm } from '@/utils';
import styles from './EditAppNameModal.module.scss';
export default function EditAppNameModal({ isOpen, setIsOpen, data }) {
    const queryClient = useQueryClient();
    const [name, setName] = useState('');
    const onChangeInput = useCallback((event) => {
        setName(event.target.value.replace(/[^a-zA-Z0-9]/g, ''));
    }, []);
    const { mutate } = useMutation({
        mutationFn: () => requestFn(`${MY_SERVICE_APP_API_URL}/updateApp`, {
            method: 'PUT',
            data: {
                guid: data === null || data === void 0 ? void 0 : data.appid,
                name: name,
                instances: data === null || data === void 0 ? void 0 : data.appInstance,
                memory: data === null || data === void 0 ? void 0 : data.appMemory,
                diskQuota: data === null || data === void 0 ? void 0 : data.appDisk,
            },
        }),
        onSuccess: (response) => {
            var _a, _b;
            swal((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.msg);
            setIsOpen(false);
            if (((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.result) === 'SUCCESS') {
                queryClient.invalidateQueries([`${MY_SERVICE_APP_API_URL}/getAppDetail`]);
            }
        },
    });
    const onClickSubmit = useCallback(() => {
        if (!isFormValidate(name, FORM_VALIDATE))
            return;
        swalConfirm('변경사항을 저장하시겠습니까?').then((res) => {
            if (res.isConfirmed)
                mutate();
        });
    }, [name]);
    return (_jsx(Modal, Object.assign({ title: "\uC571 \uC774\uB984 \uBCC0\uACBD", isOpen: isOpen, setIsOpen: setIsOpen, onClickSubmit: onClickSubmit }, { children: _jsx("ul", Object.assign({ className: styles.listForm }, { children: _jsxs("li", { children: [_jsx("label", Object.assign({ htmlFor: "name", className: styles.labelTitle }, { children: "\uC0C8\uB85C\uC6B4 \uC571 \uC774\uB984" })), _jsx(Input, { id: "name", placeholder: "\uC0C8\uB85C\uC6B4 \uC774\uB984\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694.", value: name, onChange: onChangeInput })] }) })) })));
}
