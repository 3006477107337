var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { swal } from '@/utils';
import axiosUtil from '@/utils/axios';
export function useDataTable({ apiUrl, method = 'GET', options, defaultParams = {}, sortKey, }) {
    const storageKey = `tablePageSize_${apiUrl}`;
    const initialPageSize = Number(localStorage.getItem(storageKey) || options.initialPageSize || '10');
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState(defaultParams);
    const [activeSearchParams, setActiveSearchParams] = useState(defaultParams);
    const extractData = (response) => {
        var _a, _b;
        if (!response)
            return [];
        const data = response.data || response;
        if ((_a = data.code) === null || _a === void 0 ? void 0 : _a.startsWith('F')) {
            swal(data.message || '오류가 발생했습니다.');
            return [];
        }
        const candidateArrays = [
            data.code === 'S-200' ? data.result : null,
            (_b = data.result) === null || _b === void 0 ? void 0 : _b.content,
            data.result,
            data.data,
            Array.isArray(data) ? data : null,
        ];
        const firstValidArray = candidateArrays.find((arr) => Array.isArray(arr));
        if (sortKey && firstValidArray)
            return firstValidArray.sort((a, b) => +new Date(b[sortKey]) - +new Date(a[sortKey]));
        return firstValidArray || [];
    };
    const { data: rawData = [], isFetching, refetch, } = useQuery({
        queryKey: [`dataTable_${apiUrl}`, defaultParams],
        queryFn: ({ signal }) => __awaiter(this, void 0, void 0, function* () {
            if (method === 'GET') {
                return axiosUtil.get(apiUrl, { params: defaultParams, signal });
            }
            return axiosUtil.post(apiUrl, defaultParams, { signal });
        }),
        select: extractData,
    });
    const matchDateRange = useCallback((dateValue, startDate, endDate) => {
        if (!dateValue || (!startDate && !endDate))
            return true;
        try {
            const itemDate = new Date(dateValue);
            const start = startDate && new Date(startDate);
            const end = endDate && new Date(endDate);
            if (start) {
                start.setHours(0, 0, 0, 0);
                if (itemDate < start)
                    return false;
            }
            if (end) {
                end.setHours(23, 59, 59, 999);
                if (itemDate > end)
                    return false;
            }
            return true;
        }
        catch (_a) {
            return true;
        }
    }, []);
    const filteredData = useMemo(() => {
        if (!Array.isArray(rawData))
            return [];
        const { startDate, endDate } = activeSearchParams, otherParams = __rest(activeSearchParams, ["startDate", "endDate"]);
        const hasSearchConditions = Object.values(activeSearchParams).some((value) => value !== undefined && value !== null && value !== '');
        if (!hasSearchConditions)
            return rawData;
        return rawData.filter((item) => {
            const dateField = item.applyDate || item.created || item.createdAt;
            if (!matchDateRange(dateField, startDate, endDate))
                return false;
            return Object.entries(otherParams).every(([key, value]) => {
                if (!value)
                    return true;
                const snakeKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
                const itemValue = item[snakeKey] || item[key];
                return itemValue != null && String(itemValue).toLowerCase().includes(String(value).toLowerCase());
            });
        });
    }, [rawData, activeSearchParams, matchDateRange]);
    const paginatedData = useMemo(() => {
        const start = (currentPage - 1) * pageSize;
        return filteredData.slice(start, start + pageSize);
    }, [filteredData, currentPage, pageSize]);
    const totalPageCount = Math.ceil(filteredData.length / pageSize);
    const handlers = {
        onChangePageSize: useCallback((newSize) => {
            localStorage.setItem(storageKey, newSize);
            setPageSize(Number(newSize));
            setCurrentPage(1);
        }, [storageKey]),
        onChangePage: useCallback((page) => setCurrentPage(page), []),
        onChangeSearchParams: useCallback((name, value) => {
            setSearchParams((prev) => (Object.assign(Object.assign({}, prev), { [name]: value })));
        }, []),
        onSearch: useCallback(() => {
            setActiveSearchParams(searchParams);
            setCurrentPage(1);
        }, [searchParams]),
    };
    return Object.assign({ data: paginatedData, pagination: {
            currentPage,
            totalPage: totalPageCount,
            pageSize,
            totalElements: filteredData.length,
        }, refetch,
        isFetching,
        searchParams }, handlers);
}
