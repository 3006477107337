import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@tanstack/react-query';
import avatar from '@/assets/images/icon/ico_avata.png';
import { useCallback, useClickAway, useNavigate, useRef, useState } from '@/hooks';
import { useAuthStore, useCommonStore } from '@/stores';
import { deleteTokenCookies, getCookie, requestFn } from '@/utils';
import styles from './navProfile.module.scss';
export default function NavUserProfile() {
    const navigate = useNavigate();
    const isClose = useCommonStore((state) => state.isClose);
    const [showDropDownMenu, setShowDropDownMenu] = useState(false);
    const clickAwayRef = useRef(null);
    const { username } = useAuthStore((state) => state.userInfo);
    useClickAway(clickAwayRef, () => setShowDropDownMenu(false));
    const onClickMenuBtn = useCallback(() => {
        setShowDropDownMenu((prev) => !prev);
    }, []);
    const { mutate: onClickLogout } = useMutation({
        mutationFn: (_) => requestFn('/api/v1/auth/userauth/logout', {
            data: { access_token: getCookie('portalToken'), refresh_token: getCookie('portalRefreshToken') },
            method: 'POST',
        }),
        onSuccess: () => {
            if (window.opener) {
                window.opener.postMessage('logout', '*');
            }
            window.close();
            deleteTokenCookies();
        },
    });
    return (_jsx("div", Object.assign({ className: styles.userArea, ref: clickAwayRef, style: isClose ? { padding: 'unset' } : {} }, { children: !isClose ? (_jsxs("div", Object.assign({ className: `${styles.userConts} ${styles.opened}` }, { children: [_jsxs("button", Object.assign({ type: "button", className: styles.userBtn, onClick: onClickMenuBtn }, { children: [_jsxs("span", Object.assign({ className: styles.userName }, { children: [_jsx("span", { className: styles.avatar }), _jsx("span", Object.assign({ className: styles.name }, { children: username })), _jsx("span", Object.assign({ className: styles.team }, { children: _jsx("span", { children: username }) }))] })), _jsx("span", { className: styles.btn })] })), _jsx("div", Object.assign({ className: styles.userMenu, style: showDropDownMenu ? { opacity: 1, height: 160 } : { opacity: 0, height: 0 } }, { children: _jsx("ul", { children: _jsx("li", Object.assign({ className: styles.btns, id: "my-setting" }, { children: _jsx("button", Object.assign({ type: 'button', id: "my-setting", onClick: onClickLogout }, { children: "\uB85C\uADF8\uC544\uC6C3" })) })) }) }))] }))) : (_jsx("div", Object.assign({ className: styles.closed, onClick: () => navigate('/myinfo') }, { children: _jsx("img", { src: avatar, alt: 'avatar' }) }))) })));
}
