import moment from 'moment';
export const COL_DEF = [
    { headerName: 'ID', field: 'vpcPeeringInstanceNo' },
    { headerName: '이름', field: 'vpcPeeringName' },
    { headerName: 'Region', field: 'regionCode' },
    { headerName: '상태', field: 'vpcPeeringInstanceStatusName' },
    { headerName: 'Source VPC ID', field: 'sourceVpcNo' },
    { headerName: 'Source VPC 이름', field: 'sourceVpcName' },
    { headerName: 'Target VPC ID', field: 'targetVpcNo' },
    { headerName: 'Target VPC 이름', field: 'targetVpcName' },
    { headerName: '메모', field: 'routeTableDescription' },
    {
        headerName: '생성일시',
        field: 'createDate',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    },
];
