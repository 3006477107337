import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { FrontTable } from '@/components';
import { useFrontTable } from '@/hooks';
import { DefaultLayout } from '@/layouts';
import { OswColDef } from '@/pages/catalog/openSoftWare/constant';
import { BASE_URL } from "@/constants";
function OpenSoftWarePage() {
    const tableProps = useFrontTable({ apiUrl: `${BASE_URL}/api/v1/cmp/catalog/open-sw` });
    return (_jsx(DefaultLayout, Object.assign({ breadcrumb: { Home: '/', 'Open S/W': '' } }, { children: _jsx(FrontTable, Object.assign({}, tableProps, { columns: OswColDef })) })));
}
export default OpenSoftWarePage;
