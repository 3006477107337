// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BVjMmzEI4TfzH2MIsAUA{height:100vh}.JFMSRC5YZmmqhO0mXYnV{display:flex;flex-direction:column;position:relative;justify-content:center;height:100%;width:600px;margin:0 auto}.iAPwdeOmC7acAqqowFK2{height:40px}.tZz52MzNXTZHWZ_w8KFn{border:1px solid #c5cad0;border-radius:5px;padding:20px;font-size:16px;margin-bottom:20px}.Bwku4NdUmCdYS3TY752Z{width:100px;font-weight:bold}._taBDIh3m4SWVoNSjm7w{display:flex}._taBDIh3m4SWVoNSjm7w:not(:last-child){margin-bottom:10px}.CqbndvvVEae_5Qp01eqd{margin-top:20px;display:flex;height:40px;justify-content:space-between}.CqbndvvVEae_5Qp01eqd button{background:unset;color:#000;flex:.3}`, "",{"version":3,"sources":["webpack://./src/pages/join/findId/JoinFindIdPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,sBAAA,CACA,WAAA,CACA,WAAA,CACA,aAAA,CAGF,sBACE,WAAA,CAGF,sBACE,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CAEA,uCACE,kBAAA,CAKJ,sBACE,eAAA,CACA,YAAA,CACA,WAAA,CACA,6BAAA,CAGA,6BACE,gBAAA,CACA,UAAA,CACA,OAAA","sourcesContent":[".container {\r\n  height: 100vh;\r\n}\r\n\r\n.inner {\r\n  display: flex;\r\n  flex-direction: column;\r\n  position: relative;\r\n  justify-content: center;\r\n  height: 100%;\r\n  width: 600px;\r\n  margin: 0 auto;\r\n}\r\n\r\n.verBtn {\r\n  height: 40px;\r\n}\r\n\r\n.findResultContainer {\r\n  border: 1px solid #c5cad0;\r\n  border-radius: 5px;\r\n  padding: 20px;\r\n  font-size: 16px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.indicatorWrapper {\r\n  width: 100px;\r\n  font-weight: bold;\r\n}\r\n\r\n.eachRow {\r\n  display: flex;\r\n\r\n  &:not(:last-child) {\r\n    margin-bottom: 10px;\r\n  }\r\n}\r\n\r\n\r\n.navBtnGroups {\r\n  margin-top: 20px;\r\n  display: flex;\r\n  height: 40px;\r\n  justify-content: space-between;\r\n\r\n\r\n  button {\r\n    background: unset;\r\n    color: black;\r\n    flex: 0.3;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BVjMmzEI4TfzH2MIsAUA`,
	"inner": `JFMSRC5YZmmqhO0mXYnV`,
	"verBtn": `iAPwdeOmC7acAqqowFK2`,
	"findResultContainer": `tZz52MzNXTZHWZ_w8KFn`,
	"indicatorWrapper": `Bwku4NdUmCdYS3TY752Z`,
	"eachRow": `_taBDIh3m4SWVoNSjm7w`,
	"navBtnGroups": `CqbndvvVEae_5Qp01eqd`
};
export default ___CSS_LOADER_EXPORT___;
