import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DataCardList from '@/components/frontDataCard/_components/DataCardList';
import { useSearchParams } from '@/hooks';
import styles from './frontDataCard.module.scss';
export default function FrontDataCard(props) {
    const { pagingInfo, apiUrl, rowData, inputValue, inputPageValue, onNavigateRow, onClickPreviousPage, onClickNextPage, onChangeInputValue, onSearch, onClickRefresh, onKeyPress, onChangePageValue, onKeyUpPageValue, } = props;
    const [searchParams] = useSearchParams();
    const page = parseInt(searchParams.get('page') || '1');
    return (_jsxs("div", Object.assign({ className: styles.box }, { children: [_jsx("div", Object.assign({ className: styles.secondTitle }, { children: _jsxs("div", Object.assign({ className: styles.explain }, { children: [_jsx("div", { children: "\uCD1D" }), _jsx("div", { children: (pagingInfo === null || pagingInfo === void 0 ? void 0 : pagingInfo.totalElements) !== undefined ? pagingInfo.totalElements : 0 }), _jsx("div", { children: "\uAC1C\uC758 \uAC8C\uC2DC\uBB3C\uC774 \uC788\uC2B5\uB2C8\uB2E4" })] })) })), _jsx("div", Object.assign({ className: styles.cardWrapper }, { children: _jsx(DataCardList, { rowData: rowData, onNavigateRow: onNavigateRow, apiUrl: apiUrl }) })), _jsxs("div", Object.assign({ className: styles.footer }, { children: [_jsx("button", Object.assign({ onClick: onClickPreviousPage, disabled: page === 1 }, { children: _jsx("span", { className: styles.prevBtn }) })), _jsxs("div", Object.assign({ className: styles.pagingNum }, { children: [_jsx("input", { value: (pagingInfo === null || pagingInfo === void 0 ? void 0 : pagingInfo.totalElements) === 0 ? 0 : inputPageValue, onKeyUp: onKeyUpPageValue, onChange: onChangePageValue }), _jsx("span", Object.assign({ className: styles.pagingTotal }, { children: pagingInfo === null || pagingInfo === void 0 ? void 0 : pagingInfo.totalPage }))] })), _jsx("button", Object.assign({ onClick: onClickNextPage, disabled: page === (pagingInfo === null || pagingInfo === void 0 ? void 0 : pagingInfo.totalPage) }, { children: _jsx("span", { className: styles.nextBtn }) }))] }))] })));
}
