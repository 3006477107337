export const ConsoleMenu = [
    { name: '내 정보 관리', menuId: 'account', depth: 1, link: '/my-info', isCheckAuthorized: false },
    { name: 'My Service', menuId: 'my-service', depth: 1, link: '/my-service', isCheckAuthorized: true },
    {
        name: 'My Data',
        menuId: 'my-data',
        depth: 1,
        isCheckAuthorized: true,
        children: [
            // {
            // 	name: '데이터 즐겨찾기 관리',
            // 	menuId: 'favorite-data-management',
            // 	depth: 2,
            // 	link: '/my-data/favorite-data-management',
            // },
            {
                name: '사용 신청 데이터 관리',
                menuId: 'application-data-management',
                depth: 2,
                link: '/my-data/application-data-management',
            },
            {
                name: '가공 데이터 관리',
                menuId: 'processed-data-management',
                depth: 2,
                link: '/my-data/processed-data-management',
            },
            {
                name: '데이터 등록 요청',
                menuId: 'request-data',
                depth: 2,
                link: '/my-data/request-data',
            },
        ],
    },
    {
        name: 'CSP 자원조회',
        menuId: 'csp-resource',
        depth: 1,
        isCheckAuthorized: true,
        children: [
            {
                name: 'NCP',
                menuId: 'ncp',
                depth: 2,
                children: [
                    {
                        name: 'Server',
                        menuId: 'server',
                        depth: 3,
                        children: [
                            { name: 'Server', menuId: 'server', depth: 4, link: '/csp-resource/ncp/server/server' },
                            { name: 'Server Image', menuId: 'image', depth: 4, link: '/csp-resource/ncp/server/image' },
                            { name: 'Storage', menuId: 'storage', depth: 4, link: '/csp-resource/ncp/server/storage' },
                            { name: 'Snapshot', menuId: 'snapshot', depth: 4, link: '/csp-resource/ncp/server/snapshot' },
                            { name: 'Public Ip', menuId: 'public-ip', depth: 4, link: '/csp-resource/ncp/server/public-ip' },
                            { name: 'ACG', menuId: 'acg', depth: 4, link: '/csp-resource/ncp/server/acg' },
                        ],
                    },
                    {
                        name: 'Container Registry',
                        menuId: 'container-registry',
                        depth: 3,
                        link: '/csp-resource/ncp/container-registry',
                    },
                    {
                        name: 'Kubernetes Service',
                        menuId: 'kubernetes-service',
                        depth: 3,
                        children: [
                            { name: 'Cluster', menuId: 'cluster', depth: 4, link: '/csp-resource/ncp/kubernetes-service/cluster' },
                            {
                                name: 'Node Pool',
                                menuId: 'pool',
                                depth: 4,
                                link: '/csp-resource/ncp/kubernetes-service/pool',
                            },
                            {
                                name: 'Node',
                                menuId: 'node',
                                depth: 4,
                                link: '/csp-resource/ncp/kubernetes-service/node',
                            },
                        ],
                    },
                    {
                        name: 'NAS',
                        menuId: 'nas',
                        depth: 3,
                        children: [
                            { name: 'Volume', menuId: 'volume', depth: 4, link: '/csp-resource/ncp/nas/volume' },
                            { name: 'Snapshot', menuId: 'snapshot', depth: 4, link: '/csp-resource/ncp/nas/snapshot' },
                        ],
                    },
                    {
                        name: 'VPC',
                        menuId: 'vpc',
                        depth: 3,
                        children: [
                            { name: 'VPC Management', menuId: 'management', depth: 4, link: '/csp-resource/ncp/vpc/management' },
                            { name: 'Subnet', menuId: 'subnet', depth: 4, link: '/csp-resource/ncp/vpc/subnet' },
                            { name: 'Network ACL', menuId: 'network-acl', depth: 4, link: '/csp-resource/ncp/vpc/network-acl' },
                            { name: 'VPC Peering', menuId: 'vpc-peering', depth: 4, link: '/csp-resource/ncp/vpc/vpc-peering' },
                            { name: 'Route Table', menuId: 'route-table', depth: 4, link: '/csp-resource/ncp/vpc/route-table' },
                        ],
                    },
                    {
                        name: 'Load Balancer',
                        menuId: 'load-balancer',
                        depth: 3,
                        children: [
                            {
                                name: 'Load Balancer',
                                menuId: 'load-balancer',
                                depth: 4,
                                link: '/csp-resource/ncp/load-balancer/load-balancer',
                            },
                            {
                                name: 'Target Group',
                                menuId: 'target-group',
                                depth: 4,
                                link: '/csp-resource/ncp/load-balancer/target-group',
                            },
                        ],
                    },
                ],
            },
            {
                name: 'NHN',
                menuId: 'nhn',
                depth: 2,
                children: [
                    {
                        name: 'Compute',
                        menuId: 'compute',
                        depth: 3,
                        children: [
                            {
                                name: 'Instance',
                                menuId: 'instance',
                                depth: 4,
                                children: [{ name: 'Server', menuId: 'server', depth: 5, link: '/csp-resource/nhn/compute/server' }],
                            },
                            { name: 'Image', menuId: 'image', depth: 4, link: '/csp-resource/nhn/compute/image' },
                        ],
                    },
                    {
                        name: 'Container',
                        menuId: 'container',
                        depth: 3,
                        children: [
                            {
                                name: 'Kubernetes Service',
                                menuId: 'kubernetes-service',
                                depth: 4,
                                children: [
                                    {
                                        name: 'Clusters',
                                        menuId: 'clusters',
                                        depth: 5,
                                        link: '/csp-resource/nhn/kubernetes-service/clusters',
                                    },
                                    // {name: 'Node groups', menuId: 'node-groups', depth: 5, link: '/csp-resource/nhn/kubernetes-service/node-groups' }
                                ],
                            },
                        ],
                    },
                    {
                        name: 'Network',
                        menuId: 'network',
                        depth: 3,
                        children: [
                            {
                                name: 'VPC',
                                menuId: 'vpc',
                                depth: 4,
                                children: [
                                    { name: 'VPC', menuId: 'vpc', depth: 5, link: '/csp-resource/nhn/network/vpc' },
                                    { name: 'VPC Subnet', menuId: 'vpc-subnet', depth: 5, link: '/csp-resource/nhn/network/vpc-subnet' },
                                    {
                                        name: 'Routing table',
                                        menuId: 'routing-table',
                                        depth: 5,
                                        link: '/csp-resource/nhn/network/routing-table',
                                    },
                                    // {name: 'Route', menuId: 'route', depth: 5, link: '/csp-resource/nhn/network/route' }
                                ],
                            },
                            { name: 'Floating IP', menuId: 'floating-ip', depth: 4, link: '/csp-resource/nhn/network/floating-ip' },
                            {
                                name: 'Network ACL',
                                menuId: 'network-acl',
                                depth: 4,
                                children: [
                                    { name: 'ACL', menuId: 'acl', depth: 5, link: '/csp-resource/nhn/network/acl' },
                                    // {name: 'ACL Rule', menuId: 'acl-rule', depth: 5, link: '/csp-resource/nhn/network/acl-rule' },
                                    // {name: 'ACL binding', menuId: 'acl-binding', depth: 5, link: '/csp-resource/nhn/network/acl-binding' }
                                ],
                            },
                            {
                                name: 'Security Groups',
                                menuId: 'security-groups',
                                depth: 4,
                                link: '/csp-resource/nhn/network/security-groups',
                            },
                            {
                                name: 'Load Balancer',
                                menuId: 'load-balancer',
                                depth: 4,
                                children: [
                                    {
                                        name: 'Load Balancer',
                                        menuId: 'load-balancer',
                                        depth: 5,
                                        link: '/csp-resource/nhn/network/load-balancer',
                                    },
                                    // {name: 'Listeners', menuId: 'listeners', depth: 5, link: '/csp-resource/nhn/network/listeners' },
                                    // {name: 'Pools', menuId: 'pools', depth: 5, link: '/csp-resource/nhn/network/pools' },
                                    {
                                        name: 'IP ACL groups',
                                        menuId: 'ip-acl-groups',
                                        depth: 5,
                                        link: '/csp-resource/nhn/network/ip-acl-groups',
                                    },
                                    // {name: 'IP ACL targets', menuId: 'ip-acl-targets', depth: 5, link: '/csp-resource/nhn/network/ip-acl-targets' }
                                ],
                            },
                        ],
                    },
                    {
                        name: 'Storage',
                        menuId: 'storage',
                        depth: 3,
                        children: [
                            {
                                name: 'Block Storage',
                                menuId: 'block-storage',
                                depth: 4,
                                children: [
                                    {
                                        name: 'Block Storage',
                                        menuId: 'block-storage',
                                        depth: 5,
                                        link: '/csp-resource/nhn/storage/block-storage',
                                    },
                                    { name: 'Snapshot', menuId: 'snapshot', depth: 5, link: '/csp-resource/nhn/storage/snapshot' },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                name: 'KT',
                menuId: 'kt',
                depth: 2,
                children: [
                    {
                        name: 'Computing',
                        menuId: 'computing',
                        depth: 3,
                        children: [
                            {
                                name: 'Server',
                                menuId: 'server',
                                depth: 4,
                                link: '/csp-resource/kt/computing/server',
                            },
                            {
                                name: 'Public IP',
                                menuId: 'public-ip',
                                depth: 4,
                                link: '/csp-resource/kt/computing/public-ip',
                            },
                            {
                                name: 'Image',
                                menuId: 'image',
                                depth: 4,
                                link: '/csp-resource/kt/computing/image',
                            },
                            {
                                name: 'Volume',
                                menuId: 'volume',
                                depth: 4,
                                link: '/csp-resource/kt/computing/volume',
                            },
                            {
                                name: 'Snapshot',
                                menuId: 'snapshot',
                                depth: 4,
                                link: '/csp-resource/kt/computing/snapshot',
                            },
                            {
                                name: 'Subnet',
                                menuId: 'subnet',
                                depth: 4,
                                link: '/csp-resource/kt/computing/subnet',
                            },
                        ],
                    },
                    {
                        name: 'Network',
                        menuId: 'network',
                        depth: 3,
                        children: [
                            {
                                name: 'Load Balancer',
                                menuId: 'load-balancer',
                                depth: 4,
                                link: '/csp-resource/kt/network/load-balancer',
                            },
                        ],
                    },
                    {
                        name: 'Storage',
                        menuId: 'storage',
                        depth: 3,
                        children: [
                            {
                                name: 'NAS',
                                menuId: 'nas',
                                depth: 4,
                                link: '/csp-resource/kt/storage/nas',
                            },
                            {
                                name: 'NAS Snapshot',
                                menuId: 'nsnap',
                                depth: 4,
                                link: '/csp-resource/kt/storage/nsnap',
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
