import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@/components';
import { useCallback, useQueryClient, useState } from '@/hooks';
import styles from './TableUtilBar.module.scss';
export default function TableUtilBar(props) {
    const { children, tableRef, queryKey, isSearch } = props;
    const queryClient = useQueryClient();
    const [inputValue, setInputValue] = useState();
    const onClickSearch = useCallback(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (inputValue) {
            (_b = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.setQuickFilter(inputValue);
        }
        else {
            (_d = (_c = tableRef.current) === null || _c === void 0 ? void 0 : _c.api) === null || _d === void 0 ? void 0 : _d.setQuickFilter('');
        }
        if (((_f = (_e = tableRef.current) === null || _e === void 0 ? void 0 : _e.api) === null || _f === void 0 ? void 0 : _f.getDisplayedRowCount()) === 0) {
            (_h = (_g = tableRef.current) === null || _g === void 0 ? void 0 : _g.api) === null || _h === void 0 ? void 0 : _h.showNoRowsOverlay();
        }
        else {
            (_k = (_j = tableRef.current) === null || _j === void 0 ? void 0 : _j.api) === null || _k === void 0 ? void 0 : _k.hideOverlay();
        }
    }, [inputValue]);
    const onKeyPressEnter = useCallback(() => {
        onClickSearch();
    }, [onClickSearch]);
    const onClickRefreshBtn = useCallback(() => {
        var _a, _b, _c, _d;
        (_b = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.clearFocusedCell();
        (_d = (_c = tableRef.current) === null || _c === void 0 ? void 0 : _c.api) === null || _d === void 0 ? void 0 : _d.deselectAll();
        setInputValue('');
        onClickSearch();
        queryClient.invalidateQueries(queryKey);
    }, [queryClient, queryKey]);
    const onChangeInputValue = (e) => {
        setInputValue(e.target.value);
    };
    return (_jsx("div", Object.assign({ className: styles.utilBarFrame }, { children: _jsxs("div", Object.assign({ className: styles['titBar'] }, { children: [children, _jsx("div", Object.assign({ className: styles['searchArea'] }, { children: _jsxs("div", Object.assign({ className: styles['searchInner'] }, { children: [isSearch && (_jsxs("div", Object.assign({ className: styles['searchBox'] }, { children: [_jsx("input", { type: "search", className: "searchInput", placeholder: "\uAC80\uC0C9", onKeyUp: onKeyPressEnter, value: inputValue, onChange: onChangeInputValue }), _jsx(IconButton, { icon: "search", onClick: onClickSearch })] }))), _jsx("div", Object.assign({ className: styles['iconBtnGroup'] }, { children: _jsx(IconButton, { icon: "refresh", onClick: onClickRefreshBtn }) }))] })) }))] })) })));
}
