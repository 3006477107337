import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover, RadioGroupButton } from '@innogrid-ui/core';
import React, { useEffect } from 'react';
import { useDataProcessStore } from '@/stores';
import styles from './ApplicationDataProcessPage.module.scss';
export const PrivacyPolicyModal = () => {
    const { isPrivacy, setIsPrivacy, updateStepState } = useDataProcessStore();
    useEffect(() => {
        updateStepState('dataProcess', { deid_yn: `${isPrivacy}` });
    }, [isPrivacy]);
    const onChangeRadio = (value) => {
        setIsPrivacy(value);
    };
    return (_jsxs("section", Object.assign({ className: styles.innerBox }, { children: [_jsxs("div", Object.assign({ className: styles.flexBox }, { children: [_jsxs("h3", Object.assign({ className: styles.tit }, { children: ["\uAC1C\uC778\uC815\uBCF4 \uCC98\uB9AC\uC5EC\uBD80", _jsxs(Popover, Object.assign({ className: "popover", iconPath: "", variant: "blue", contentClassName: "popoverContent" }, { children: ["\uAC1C\uC778\uC744 \uC2DD\uBCC4\uD560 \uC218 \uC788\uB294 \uC8FC\uBBFC\uB4F1\uB85D", _jsx("br", {}), "\uBC88\uD638, \uC5EC\uAD8C\uBC88\uD638, \uC6B4\uC804\uBA74\uD5C8\uBC88\uD638,", _jsx("br", {}), "\uC678\uAD6D\uC778\uB4F1\uB85D\uBC88\uD638, \uD578\uB4DC\uD3F0\uBC88\uD638\uAC00", _jsx("br", {}), "\uCEEC\uB7FC\uC758 \uAC12\uC77C \uACBD\uC6B0 \uB9C8\uC2A4\uD0B9\uCC98\uB9AC\uB85C", _jsx("br", {}), "\uC124\uC815\uD558\uC2DC\uBA74 \uC790\uB3D9 \uBE44\uC2DD\uBCC4\uCC98\uB9AC \uD6C4", _jsx("br", {}), "\uC800\uC7A5\uD569\uB2C8\uB2E4."] }), "false bottom")] })), _jsx("div", Object.assign({ className: styles.cont }, { children: _jsx("div", Object.assign({ className: styles.radioBox }, { children: _jsx(RadioGroupButton, { id: "radio-group", options: [{ label: '마스킹 처리', value: true, variant: 'outlined' }], value: isPrivacy, onValueChange: onChangeRadio }) })) }))] })), _jsxs("p", Object.assign({ className: styles.description }, { children: ["*\uAC1C\uC778\uC815\uBCF4 \uBCF4\uD638\uB97C \uC704\uD574 \uC8FC\uBBFC\uB4F1\uB85D\uBC88\uD638\uC640 \uAC19\uC740 \uAC1C\uC778 \uC2DD\uBCC4\uC815\uBCF4\uAC00", _jsx("br", {}), "\uCEEC\uB7FC\uC758 \uAC12\uC77C \uACBD\uC6B0 \uB9C8\uC2A4\uD0B9 \uCC98\uB9AC\uB429\uB2C8\uB2E4."] }))] })));
};
