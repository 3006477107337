import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Loading, PageErrorBoundary } from '@/components';
import NavigationBar from '@/components/navbar/NavigationBar';
import { getCookie } from '@/utils';
import { Footer, Navbar } from './_components';
import styles from './mainLayout.module.scss';
export function MainLayout() {
    return (_jsx(PageErrorBoundary, { children: _jsxs("div", Object.assign({ className: styles.wrap }, { children: [_jsx(Navbar, {}), _jsx(Suspense, Object.assign({ fallback: _jsx(Loading, {}) }, { children: _jsx(Outlet, {}) })), _jsx(Footer, {})] })) }));
}
export function TabclouditMainLayout() {
    if (!getCookie('portalToken'))
        return _jsx(Navigate, { to: "/" });
    return (_jsx(PageErrorBoundary, { children: _jsxs("div", Object.assign({ className: styles.mainRoot }, { children: [_jsx(NavigationBar, {}), _jsx("div", Object.assign({ className: styles.mainContent }, { children: _jsx(Outlet, {}) }))] })) }));
}
