import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Input, RadioGroupButton, useAlertDialog } from '@innogrid-ui/core';
import { useMutation } from '@tanstack/react-query';
import React, { memo, useMemo, useState } from 'react';
import { processNull } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/promise';
import { useDataProcessStore } from '@/stores';
import { validateError } from '@/utils';
const Component = ({ column, submenu, id }) => {
    const { open } = useAlertDialog();
    const { processResult } = useDataProcessStore((state) => state.processInfo)[id];
    const updateProcessInfo = useDataProcessStore((state) => state.updateProcessInfo);
    const [formData, setFormData] = useState({ options: '' });
    const options = useMemo(() => submenu.map((menu) => ({
        label: menu.procs_submenu_kor_nm,
        value: menu.options,
        variant: 'outlined',
    })), [submenu]);
    const onChangeRadio = (value) => {
        setFormData({ options: value, value: value === 'value' ? '' : undefined });
    };
    const { mutate } = useMutation({
        mutationFn: processNull,
        onSuccess: ({ data }) => {
            if (!validateError(data, open)) {
                updateProcessInfo({ data: data.result, actionType: 'increment' });
            }
        },
    });
    const onClick = () => {
        if (!formData.options)
            return open({ children: '속성을 선택해 주세요.', variant: 'common' });
        mutate({
            defaultData: { datasetInfo: processResult, column },
            contents: formData,
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(RadioGroupButton, { id: "radio-group", options: options, orientation: "vertical", onValueChange: onChangeRadio }), _jsx("div", Object.assign({ className: "dataInput" }, { children: _jsx(Input, { onChange: (e) => setFormData((prev) => (Object.assign(Object.assign({}, prev), { value: e.target.value }))), value: (formData === null || formData === void 0 ? void 0 : formData.value) || '', disabled: (formData === null || formData === void 0 ? void 0 : formData.options) !== 'value' }) })), _jsx("div", Object.assign({ className: "dataBtns" }, { children: _jsx(Button, Object.assign({ onClick: onClick, size: "small" }, { children: "\uC801\uC6A9" })) }))] }));
};
export const NullValueProcessor = memo(Component);
