import { useQuery } from '@tanstack/react-query';
import { DATA_API_URL } from '@/pages/console/myData/processedDataManagement/constant';
import axiosUtil from '@/utils/axios';
export const useScheduleQueries = ({ userSeq, schemaName, id, isTypeDataset, }) => {
    // IF_KBI_00008 스키마 상세 조회 API
    const { data: tableData, isFetching: isSchemaFetching } = useQuery({
        queryKey: [`${DATA_API_URL}/${userSeq}/schema`],
        queryFn: ({ queryKey, signal }) => axiosUtil.get(queryKey[0], { params: { tableName: schemaName, transferedDataYn: false }, signal }),
    });
    // PP-070003: 가공 이력 조회 API
    const { data: historyData, isFetching: isHistoryFetching } = useQuery({
        queryKey: [`${DATA_API_URL}/work/history`, id],
        queryFn: ({ queryKey, signal }) => axiosUtil.get(queryKey[0], { params: { datasetId: schemaName }, signal }),
        enabled: !!id,
    });
    const { data: fusionData, isFetching: isFusionFetching } = useQuery({
        queryKey: [`${DATA_API_URL}/work/history/fusion/info`, id],
        queryFn: ({ queryKey, signal }) => axiosUtil.get(queryKey[0], { params: { datasetId: schemaName }, signal }),
        enabled: !!id,
    });
    // PP-070005: 스케줄 조회 API
    const { data: scheduleData, isFetching: isSchedulerFetching } = useQuery({
        queryKey: [`${DATA_API_URL}/job/user/select`, id],
        queryFn: ({ queryKey, signal }) => axiosUtil.post(queryKey[0], null, {
            params: { datasetId: schemaName },
            signal,
        }),
        enabled: !!id && !isTypeDataset,
    });
    return {
        tableData,
        historyData,
        fusionData,
        scheduleData,
        isSchemaFetching,
        isHistoryFetching,
        isFusionFetching,
        isSchedulerFetching,
    };
};
