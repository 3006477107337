import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ColumnSplitter } from './ColumnSplitter';
import { DateConversion } from './DateConversion';
import { NullValueProcessor } from './NullValueProcessor';
import { TextConversion } from './TextConversion';
import { UnitConversion } from './UnitConversion';
export const AccordionInfo = {
    null_column: {
        popoverContent: (_jsxs(_Fragment, { children: ["\uB370\uC774\uD130\uC758 \uAC12\uC774 \uBE44\uC5B4\uC788\uC744 \uACBD\uC6B0", _jsx("br", {}), "\uC120\uD0DD \uCEEC\uB7FC \uAE30\uC900\uC73C\uB85C", _jsx("br", {}), "\uD574\uB2F9 \uD589 \uC804\uCCB4\uB97C \uC0AD\uC81C\uD558\uAC70\uB098", _jsx("br", {}), "\uC785\uB825\uD55C \uAC12\uC73C\uB85C \uB300\uCCB4\uD558\uB294", _jsx("br", {}), "\uAC00\uACF5 \uAE30\uB2A5\uC785\uB2C8\uB2E4."] })),
        component: NullValueProcessor,
    },
    unitconversion: {
        popoverContent: (_jsxs(_Fragment, { children: ["\uC12D\uC528/\uD654\uC528, kg/g\uB4F1 \uD544\uC694\uC5D0 \uB530\uB77C", _jsx("br", {}), " \uB2E8\uC704\uB97C \uBCC0\uD658\uD558\uB294 \uAC00\uACF5\uAE30\uB2A5\uC785\uB2C8\uB2E4."] })),
        component: UnitConversion,
    },
    datetimespitevariable: {
        popoverContent: (_jsxs(_Fragment, { children: ["\uB0A0\uC9DC \uB370\uC774\uD130\uB97C \uC6D0\uD558\uB294 \uB0A0\uC9DC\uD3EC\uB9F7\uC73C\uB85C", _jsx("br", {}), "\uBCC0\uD658\uD558\uB294 \uAC00\uACF5 \uAE30\uB2A5\uC785\uB2C8\uB2E4.", _jsx("br", {}), "\uC785\uB825 \uC608\uC2DC\uB97C \uD655\uC778 \uD6C4 \uB0A0\uC9DC \uD3EC\uBA67\uC744", _jsx("br", {}), "\uC785\uB825\uD558\uC138\uC694."] })),
        component: DateConversion,
    },
    split_column: {
        popoverContent: (_jsxs(_Fragment, { children: ["\uC120\uD0DD\uD55C \uCEEC\uB7FC\uC758 \uB370\uC774\uD130\uB97C", _jsx("br", {}), "\uC124\uC815\uD55C \uAE30\uD638 \uB610\uB294 \uC124\uC815\uD55C \uAE38\uC774\uB300\uB85C \uBD84\uD560 \uCC98\uB9AC\uD558\uC5EC", _jsx("br", {}), "\uC0C8\uB85C\uC6B4 \uCEEC\uB7FC\uC744 \uC0DD\uC131\uD558\uB294 \uAC00\uACF5 \uAE30\uB2A5\uC785\uB2C8\uB2E4."] })),
        component: ColumnSplitter,
    },
    replace_column: {
        popoverContent: (_jsxs(_Fragment, { children: ["\uC785\uB825\uD55C \uD2B9\uC815 \uBB38\uC790\uC5F4\uC744", _jsx("br", {}), "\uC785\uB825\uD55C \uBB38\uC790\uC5F4\uB85C \uBC14\uAFB8\uB294 \uAC00\uACF5 \uAE30\uB2A5\uC785\uB2C8\uB2E4."] })),
        component: TextConversion,
    },
};
export const DeleteInfo = {
    drop_column: {
        popoverContent: (_jsxs(_Fragment, { children: ["\uBD88\uD544\uC694\uD55C \uCEEC\uB7FC\uC744 \uC81C\uAC70\uD558\uB294", _jsx("br", {}), " \uAC00\uACF5 \uAE30\uB2A5\uC785\uB2C8\uB2E4."] })),
    },
    duplicated_column: {
        popoverContent: (_jsxs(_Fragment, { children: ["\uC120\uD0DD\uD55C \uCEEC\uB7FC\uC758 \uB370\uC774\uD130\uAC00 \uC911\uBCF5\uC5C6\uC774", _jsx("br", {}), "\uC720\uC77C\uD574\uC57C \uD558\uB294 \uACBD\uC6B0 \uC911\uBCF5\uB41C \uD589 \uC804\uCCB4\uB97C", _jsx("br", {}), "\uC0AD\uC81C\uD558\uB294 \uAC00\uACF5\uAE30\uB2A5\uC785\uB2C8\uB2E4."] })),
    },
};
