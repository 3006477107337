import { jsx as _jsx } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import MultipleMenuItem from './MultipleMenuItem';
import SingleMenuItem from './SingleMenuItem';
export default function MenuItem({ item }) {
    const hasItem = (item) => {
        return !isEmpty(item.children);
    };
    const Component = hasItem(item) ? MultipleMenuItem : SingleMenuItem;
    return _jsx(Component, { item: item });
}
