import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Scrollbar, ToastViewer } from '@/components';
import { useCallback, useCustomQuery, useEffect, useNavigate, useRef, useState } from '@/hooks';
import { DefaultLayout } from '@/layouts';
import styles from './JoinTermPage.module.scss';
export default function JoinTermPage() {
    const upperViewerRef = useRef(null);
    const underViewerRef = useRef(null);
    const veryUnderViewerRef = useRef(null);
    const navigate = useNavigate();
    const [isAgree, setIsAgree] = useState(false);
    const onClickAgree = useCallback((_) => {
        setIsAgree((prev) => !prev);
    }, []);
    const { data } = useCustomQuery({
        queryKey: ['/api/v1/cmp/systems/terms'],
        select: (data) => data.reduce((obj, { termType, contents }) => {
            obj[termType] = contents;
            return obj;
        }, {}),
    });
    useEffect(() => {
        var _a, _b, _c;
        (_a = upperViewerRef.current) === null || _a === void 0 ? void 0 : _a.getInstance().setMarkdown(data === null || data === void 0 ? void 0 : data.TERM_OF_SERVICE);
        (_b = underViewerRef.current) === null || _b === void 0 ? void 0 : _b.getInstance().setMarkdown(data === null || data === void 0 ? void 0 : data.PRIVACY_POLICY);
        (_c = veryUnderViewerRef.current) === null || _c === void 0 ? void 0 : _c.getInstance().setMarkdown(data === null || data === void 0 ? void 0 : data.CLOUD_USAGE_POLICY);
        console.log(data);
    }, [data]);
    return (_jsx(DefaultLayout, Object.assign({ breadcrumb: { Home: '/', 로그인: '', 회원가입: '', '약관 동의': '' } }, { children: _jsx("div", Object.assign({ className: styles.inner }, { children: _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsxs("div", Object.assign({ className: styles.termContainer }, { children: [_jsx("h1", { children: "\uC774\uC6A9\uC57D\uAD00" }), _jsx("div", Object.assign({ className: styles.termViewer }, { children: _jsx(Scrollbar, { children: _jsx(ToastViewer, { ref: upperViewerRef }) }) }))] })), _jsxs("div", Object.assign({ className: styles.termContainer }, { children: [_jsx("h1", { children: "\uAC1C\uC778\uC815\uBCF4\uCC98\uB9AC\uBC29\uCE68" }), _jsx("div", Object.assign({ className: styles.termViewer }, { children: _jsx(Scrollbar, { children: _jsx(ToastViewer, { ref: underViewerRef }) }) }))] })), _jsxs("div", Object.assign({ className: styles.termContainer }, { children: [_jsx("h1", { children: "\uD074\uB77C\uC6B0\uB4DC \uC790\uC6D0 \uC774\uC6A9\uC81C\uD55C\uC5D0 \uB300\uD55C \uACF5\uC9C0" }), _jsx("div", Object.assign({ className: styles.cloudUsageViewer }, { children: _jsx(Scrollbar, { children: _jsx(ToastViewer, { ref: veryUnderViewerRef }) }) }))] })), _jsx("div", Object.assign({ className: styles.agreeContainer }, { children: _jsxs("div", Object.assign({ className: styles.agreeWrapper }, { children: [_jsx("input", { id: "agree", type: "checkbox", onChange: onClickAgree }), _jsx("label", Object.assign({ htmlFor: "agree" }, { children: _jsx("span", { children: "\uC704 \uC57D\uAD00\uC758 \uB0B4\uC6A9\uB4E4\uC744 \uBAA8\uB450 \uC77D\uC5C8\uC73C\uBA70 \uC774\uC5D0 \uBAA8\uB450 \uB3D9\uC758\uD569\uB2C8\uB2E4." }) }))] })) })), _jsx("div", Object.assign({ className: styles.nextBtn }, { children: _jsx(Button, Object.assign({ onClick: () => navigate('/join'), disabled: !isAgree }, { children: "\uB2E4\uC74C" })) }))] })) })) })));
}
