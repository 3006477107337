import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Loading } from '@/components';
import { useQuery, useState } from '@/hooks';
import { ConsolePageLayout } from '@/layouts';
import DataBox from '@/pages/console/myData/requestData/_components/DataBox';
import RequestDataModal from '@/pages/console/myData/requestData/_components/RequestDataModal';
import { API_URL } from '@/pages/console/myData/requestData/constant';
import axiosUtil from '@/utils/axios';
import styles from './RequestDataPage.module.scss';
export default function RequestDataPage() {
    const [isOpen, setIsOpen] = useState(false);
    const { data, isFetching } = useQuery({
        queryKey: [API_URL + '/list'],
        queryFn: ({ queryKey }) => axiosUtil({
            url: queryKey[0],
            method: 'POST',
        }).then((res) => res.data),
    });
    let temp;
    if (isFetching)
        temp = _jsx(Loading, { color: "#268eff" });
    else if (data && data.length === 0)
        temp = _jsx("div", Object.assign({ className: styles.noData }, { children: "\uB370\uC774\uD130\uAC00 \uC874\uC7AC\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4." }));
    return (_jsxs(ConsolePageLayout, Object.assign({ breadcrumb: ['My Data', '데이터 등록 요청'] }, { children: [_jsxs("div", Object.assign({ className: styles.boardListHeader }, { children: [_jsxs("div", Object.assign({ className: styles.boardCount }, { children: ["\uCD1D", _jsx("strong", { children: data ? data.length : 0 }), "\uAC74\uC758 \uC2E0\uCCAD\uB0B4\uC5ED\uC774 \uC788\uC2B5\uB2C8\uB2E4."] })), _jsx(Button, Object.assign({ onClick: () => setIsOpen(true) }, { children: "\uB370\uC774\uD130 \uB4F1\uB85D \uC694\uCCAD" }))] })), _jsx("div", Object.assign({ className: styles.dataListWrapper }, { children: temp || _jsx("div", Object.assign({ className: styles.dataList }, { children: data && data.map((d) => _jsx(DataBox, { data: d }, d.seq)) })) })), isOpen && _jsx(RequestDataModal, { isOpen: isOpen, setIsOpen: setIsOpen, isCreate: true })] })));
}
