import moment from 'moment/moment';
export const COL_DEF = [
    { headerName: 'ID', field: 'memberServerImageInstanceNo', width: 150 },
    { headerName: '이름', field: 'memberServerImageName' },
    { headerName: '상태', field: 'memberServerImageInstanceStatusName' },
    {
        headerName: '메모',
        field: 'memberServerImageDescription',
        valueFormatter: (params) => params.value || '-',
    },
    {
        headerName: '생성일시',
        field: 'createDate',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    },
];
