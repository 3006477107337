import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from '@tanstack/react-query';
import { Button, Input, ToastEditor } from '@/components';
import { useCallback, useCustomQuery, useEffect, useNavigate, useParams, useRef, useState } from '@/hooks';
import { isFormValidate, requestFn } from '@/utils';
import { FORM_VALIDATE } from '../type';
import styles from './QnaForm.module.scss';
export default function QnaForm({ formType }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const [Form, setForm] = useState({
        title: '',
        contents: '',
        qnaStatus: 'PRIVATE',
    });
    const { data, isFetching } = useCustomQuery({
        queryKey: [`/api/v1/cmp/community/qna/${id}`],
        enabled: formType !== 'create',
    });
    useEffect(() => {
        var _a;
        if (data) {
            setForm(data);
            (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.getInstance().setMarkdown(data.contents);
        }
    }, [formType, data]);
    const { mutate } = useMutation({
        mutationFn: () => requestFn('/api/v1/cmp/community/qna', {
            method: formType === 'detail' ? 'DELETE' : formType === 'update' ? 'PUT' : 'POST',
            data: formType === 'detail' ? { id: id } : Form,
        }),
        onSuccess: () => {
            navigate(`/qna?page=1`);
            window.scrollTo({ top: 0 });
        },
    });
    const onChangeTitle = useCallback((event) => {
        setForm((prev) => (Object.assign(Object.assign({}, prev), { title: event.target.value })));
    }, []);
    const onChangeCheckBox = useCallback((event) => {
        setForm((prev) => (Object.assign(Object.assign({}, prev), { qnaStatus: event.target.value === 'PUBLIC' ? 'PRIVATE' : 'PUBLIC' })));
    }, []);
    const onChangeEditor = useCallback(() => {
        const contents = editorRef.current.getInstance().getMarkdown();
        setForm((prev) => (Object.assign(Object.assign({}, prev), { contents })));
    }, []);
    const onClickSubmit = useCallback(() => {
        if (!isFormValidate(Form, FORM_VALIDATE))
            return;
        mutate();
    }, [Form, mutate]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.titleWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.titleContainer }, { children: [_jsxs("div", Object.assign({ className: styles.indicateText }, { children: ["\uC81C\uBAA9", _jsx("span", { className: styles.required })] })), _jsx(Input, { type: "text", onChange: onChangeTitle, value: Form.title })] })), _jsxs("div", Object.assign({ className: styles.statusArea }, { children: [_jsx("h2", Object.assign({ className: styles.indicateText }, { children: "\uACF5\uAC1C \uC5EC\uBD80" })), _jsx("div", Object.assign({ className: styles.inputWrapper }, { children: _jsx("input", { type: "checkbox", onChange: onChangeCheckBox, checked: Form.qnaStatus === 'PUBLIC', value: Form.qnaStatus }) }))] }))] })), _jsx("div", Object.assign({ className: styles.textEditorWrapper }, { children: _jsx(ToastEditor, { ref: editorRef, height: "100%", initialEditType: 'markdown', onChange: onChangeEditor, initialValue: Form.contents }) })), _jsxs("div", Object.assign({ className: styles.submitBtnWrapper }, { children: [_jsx(Button, Object.assign({ onClick: () => navigate(-1) }, { children: "\uCDE8\uC18C" })), _jsx(Button, Object.assign({ onClick: () => onClickSubmit() }, { children: formType === 'create' ? '생성' : '수정' }))] }))] }));
}
