// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YO1SthMOIjaJKdfdoYIk{position:relative;min-width:1260px;min-height:100%;margin:0 auto;background-color:#fff}.yPlIwUQx9ZcGJjhmEWEy{display:flex;height:100vh;width:100vw;background:#007bd4}.Sq_91JqGJAq_vf0xeQ4C{background:#f0f1f4;width:calc(100vw - 231px);display:flex;flex-direction:column;padding:0 10px 10px;border-top-left-radius:7px;flex:1;margin-top:4px}`, "",{"version":3,"sources":["webpack://./src/layouts/main/mainLayout.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,YAAA,CACA,WAAA,CACA,kBAAA,CAGF,sBACE,kBAAA,CACA,yBAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,0BAAA,CACA,MAAA,CACA,cAAA","sourcesContent":[".wrap {\r\n  position: relative;\r\n  min-width: 1260px;\r\n  min-height: 100%;\r\n  margin: 0 auto;\r\n  background-color: #fff;\r\n}\r\n\r\n.mainRoot {\r\n  display: flex;\r\n  height: 100vh;\r\n  width: 100vw;\r\n  background: #007bd4;\r\n}\r\n\r\n.mainContent {\r\n  background: #F0F1F4;\r\n  width: calc(100vw - 231px);\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 0 10px 10px;\r\n  border-top-left-radius: 7px;\r\n  flex: 1;\r\n  margin-top: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `YO1SthMOIjaJKdfdoYIk`,
	"mainRoot": `yPlIwUQx9ZcGJjhmEWEy`,
	"mainContent": `Sq_91JqGJAq_vf0xeQ4C`
};
export default ___CSS_LOADER_EXPORT___;
