var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { parseJwt, requestFn } from '@/utils';
const useAuthStore = create()(devtools(persist((set, get) => ({
    userInfo: {
        userSeq: '',
        userId: '',
        username: '',
        phone: '',
        email: null,
        expiresAt: null,
        userStatus: '',
        otpCodeEnabled: false,
        isAuthorized: false,
    },
    resetUserInfo: () => set({
        userInfo: {
            userSeq: '',
            userId: '',
            username: '',
            phone: '',
            email: null,
            expiresAt: null,
            userStatus: '',
            otpCodeEnabled: false,
            isAuthorized: false,
        },
    }),
    setUserInfo: (token) => __awaiter(void 0, void 0, void 0, function* () {
        const userSeq = parseJwt(token).userseq;
        const userInfo = yield requestFn('/api/v1/auth/init', { data: { userSeq }, method: 'POST' }).then((res) => res.data.content).catch((e) => {
            console.error(e);
        });
        // To prevent errors, added the catch statement above and if statement below.
        if (userInfo) {
            set({
                userInfo: Object.assign(Object.assign({}, userInfo), { userSeq, isAuthorized: ['USE', 'RENEWAL_WAIT'].includes(userInfo.userStatus) }),
            });
        }
    }),
}), { name: 'auth' }), { name: 'auth' }));
export default useAuthStore;
