import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './ClientPagingTable.scss';
import { AgGridReact } from 'ag-grid-react';
import TableUtilBar from '@/components/table/clientPaging/components/TableUtilBar';
import { useCommonStore } from '@/stores';
import { DropdownButton, TextIconButton } from '../../button';
const AGGRID_TEMPLATES = {
    LOADING: (message) => `<div class="ag-overlay-loading-center"><span>${message}</span></div>`,
    NO_ROWS: (message) => `<div class="noDataOverlay"><span>${message}</span></div>`,
};
export default function ClientPagingTable(props) {
    const { queryKey, tableRef, children, columnDefs, isSearch = true, actions, height, onClickCreate, rowData, onClickPreviousPage, onClickNextPage, onGridReady, onGridSizeChanged, onChangePageSize, onPaginationChanged, currPage, totPage, } = props;
    const size = useCommonStore((state) => state.size);
    return (_jsxs(_Fragment, { children: [_jsxs(TableUtilBar, Object.assign({ tableRef: tableRef, queryKey: queryKey, isSearch: isSearch }, { children: [onClickCreate && (_jsx("div", Object.assign({ className: "buttonWrapper" }, { children: _jsx(TextIconButton, Object.assign({ icon: "create", onClick: onClickCreate }, { children: "\uC0DD\uC131" })) }))), !!actions && (_jsx("div", Object.assign({ className: "buttonWrapper" }, { children: _jsx(DropdownButton, Object.assign({ actions: actions }, { children: "ACTION" })) }))), children] })), _jsx("div", Object.assign({ style: { height: 'calc(100% - 85px)' } }, { children: _jsxs("div", Object.assign({ id: "myGrid", style: {
                        height: height,
                        width: '100%',
                    }, className: "ag-theme-alpine" }, { children: [_jsx(AgGridReact, Object.assign({}, props, { ref: tableRef, rowData: rowData, rowSelection: "single", onGridReady: onGridReady, onGridSizeChanged: onGridSizeChanged, pagination: true, paginationPageSize: size, onPaginationChanged: onPaginationChanged, defaultColDef: { suppressMovable: true, sortable: false, resizable: true }, columnDefs: columnDefs, suppressDragLeaveHidesColumns: true, rowModelType: "clientSide", overlayLoadingTemplate: AGGRID_TEMPLATES.LOADING('데이터를 불러오는 중입니다.'), overlayNoRowsTemplate: AGGRID_TEMPLATES.NO_ROWS('데이터가 존재하지 않습니다.') })), _jsxs("div", Object.assign({ id: "pagination", className: "client-paging-wrap" }, { children: [_jsxs("div", { children: [_jsx("select", Object.assign({ onChange: onChangePageSize, value: size }, { children: [10, 15, 20, 30, 50, 100, 500].map((n) => {
                                                return (_jsx("option", Object.assign({ value: n.toString() }, { children: `${n.toString()} 건` }), Math.random() + n.toString()));
                                            }) })), _jsxs("span", { children: ["\uCD1D ", (rowData === null || rowData === void 0 ? void 0 : rowData.length) || 0, " \uAC74"] })] }), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "iconButton-group" }, { children: _jsx("button", { className: "btn__icon prev_arr", onClick: onClickPreviousPage, disabled: currPage === 0 || currPage === 1 }) })), _jsxs("div", Object.assign({ className: "paging-totPage" }, { children: [_jsx("span", Object.assign({ className: "totPage" }, { children: currPage })), _jsxs("span", Object.assign({ className: "totPage" }, { children: ["/\u00A0", totPage || 0] }))] })), _jsx("div", Object.assign({ className: "iconButton-group" }, { children: _jsx("button", { className: "btn__icon next_arr", onClick: onClickNextPage, disabled: currPage === totPage }) }))] })] }))] })) }))] }));
}
