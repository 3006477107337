import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from '@innogrid-ui/core';
import { useQuery } from '@tanstack/react-query';
import { chain, find, get, includes, isEmpty, omit, pick } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Loading } from '@/components';
import { DATA_API_URL } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/constant';
import { useAuthStore, useDataProcessStore } from '@/stores';
import { swal } from '@/utils';
import axiosUtil from '@/utils/axios';
import styles from './DataFusion.module.scss';
export function DataFusion() {
    var _a, _b;
    const { userSeq } = useAuthStore((state) => state.userInfo);
    const id = useDataProcessStore((state) => state.id);
    const updateStepState = useDataProcessStore((state) => state.updateStepState);
    const dataFusionCreateState = (_b = (_a = useDataProcessStore((state) => state.stepState)) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.dataFusionCreate;
    const fusionGroups = useDataProcessStore((state) => get(state.fusionGroups, id));
    const [fusionState, setFusionState] = useState({
        new_tbl: get(dataFusionCreateState, 'tableName', ''),
        dataset_list: [],
        fusion_schema: [],
        selectedColumns: {},
    });
    const tableHeaders = useMemo(() => {
        return get(dataFusionCreateState, 'columnDataList', []).map((item) => ({ title: item.name }));
    }, [dataFusionCreateState]);
    const mappedDatasetList = useMemo(() => {
        const items = get(fusionGroups, 'items', []);
        if (isEmpty(items))
            return [];
        const datasets = items.map((item) => ({
            dataset_id: item.dataId,
            data_type: item.datatype === 'dataset' ? 'FILE' : item.datatype.toUpperCase(),
            org_tbl: item.title,
            api_url: item.datatype === 'api' ? item.accessUrl : '',
            file_path: item.datatype === 'dataset' ? item.downloadUrl : '',
        }));
        setFusionState((prev) => (Object.assign(Object.assign({}, prev), { dataset_list: datasets.map((dataset) => pick(dataset, ['data_type', 'org_tbl', 'api_url', 'dataset_id', 'file_path'])) })));
        return datasets;
    }, [fusionGroups]);
    // PP-080002 융합컬럼조회
    const { data: columnData, isFetching } = useQuery({
        queryKey: [`${DATA_API_URL}/fusion/get/columns`, userSeq],
        queryFn: ({ queryKey, signal }) => axiosUtil.post(queryKey[0], { dataset_list: mappedDatasetList }, { signal }).then((res) => {
            var _a, _b;
            if ((_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.code) === null || _b === void 0 ? void 0 : _b.startsWith('F')) {
                swal(res.data.message || '오류가 발생했습니다.');
                return Object.assign(Object.assign({}, res.data), { result: [] });
            }
            return res.data;
        }),
        enabled: mappedDatasetList.length >= 2 && mappedDatasetList.length <= 4,
    });
    const onChangeSelect = (datasetId, colIndex, value) => {
        const columns = get(columnData, 'result', []);
        if (isEmpty(columns))
            return;
        setFusionState((prev) => {
            const newSelectedColumns = Object.assign(Object.assign({}, prev.selectedColumns), { [`${datasetId}-${colIndex}`]: value });
            const updatedSchema = [...prev.fusion_schema];
            const colList = [];
            columns.forEach((dataset) => {
                const selectedValue = dataset.datasetId === datasetId ? value : newSelectedColumns[`${dataset.datasetId}-${colIndex}`];
                if (selectedValue) {
                    colList.push({
                        org_tbl: dataset.orgTbl,
                        org_col: selectedValue,
                    });
                }
            });
            updatedSchema[colIndex] = {
                new_col: get(tableHeaders, `${colIndex}.title`, ''),
                col_list: colList,
            };
            return Object.assign(Object.assign({}, prev), { selectedColumns: newSelectedColumns, fusion_schema: updatedSchema });
        });
    };
    const onRenderColumnSelect = (columnList, datasetId, colIndex) => {
        const currentValue = get(fusionState.selectedColumns, `${datasetId}-${colIndex}`, '');
        const usedValues = chain(fusionState.selectedColumns)
            .toPairs()
            .filter(([key, value]) => {
            if (isEmpty(value))
                return false;
            const id = key.slice(0, key.lastIndexOf('-'));
            const index = parseInt(key.slice(key.lastIndexOf('-') + 1));
            return id === datasetId && index !== colIndex;
        })
            .map(1)
            .value();
        const availableOptions = chain(columnList)
            .filter(({ colNm }) => colNm === currentValue || !includes(usedValues, colNm))
            .map(({ colNm }) => ({
            label: colNm,
            value: colNm,
        }))
            .value();
        const sampleValue = get(find(columnList, { colNm: currentValue }), 'sample', '-');
        return (_jsxs("div", Object.assign({ className: styles.flexBox }, { children: [_jsx("div", Object.assign({ className: styles.selectBox }, { children: _jsx(Select, { options: availableOptions, placeholder: "\uC120\uD0DD", rootProps: {
                            onValueChange: (value) => onChangeSelect(datasetId, colIndex, value),
                            value: currentValue,
                        } }) })), _jsx("p", Object.assign({ title: sampleValue }, { children: sampleValue }))] })));
    };
    const columns = get(columnData, 'result', []) || [];
    useEffect(() => {
        if (!fusionState)
            return;
        updateStepState('dataFusion', { fusionState: omit(fusionState, ['selectedColumns']) });
    }, [fusionState, updateStepState]);
    return isFetching ? (_jsx("div", Object.assign({ className: styles.loadingWrapper }, { children: _jsx(Loading, { color: "#268eff" }) }))) : (_jsxs("div", Object.assign({ className: styles.innerBox }, { children: [_jsx("div", Object.assign({ className: styles.tableBox }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsx("tr", { children: _jsx("th", { children: "\uC735\uD569\uB300\uC0C1 \uB370\uC774\uD130" }) }) }), _jsx("tbody", { children: columns.map((item, index) => (_jsx("tr", Object.assign({ title: item.orgTbl }, { children: _jsx("td", Object.assign({ className: styles.txLeft }, { children: item.orgTbl })) }), `dataset-${item.datasetId}-${index}`))) })] }) })), _jsx("div", Object.assign({ className: styles.tableBox }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsx("tr", { children: tableHeaders.map((header, index) => (_jsx("th", { children: header.title }, `header-${header.title}-${index}`))) }) }), _jsx("tbody", { children: columns.map((dataset, rowIdx) => (_jsx("tr", { children: tableHeaders.map((header, colIdx) => (_jsx("td", { children: !isEmpty(dataset.orgColList) ? (onRenderColumnSelect(dataset.orgColList, dataset.datasetId, colIdx)) : (_jsx("div", Object.assign({ className: styles.flexBox }, { children: _jsx("p", { children: "-" }) }))) }, `cell-${dataset.datasetId}-${header.title}-${colIdx}`))) }, `dataset-row-${dataset.datasetId}-${rowIdx}`))) })] }) }))] })));
}
