import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
export const QnaColDef = [
    {
        field: 'title',
        headerName: '제목',
        format: (value, rowData) => {
            if (rowData) {
                return (_jsxs(_Fragment, { children: [_jsx("span", { children: value }), _jsx("span", Object.assign({ style: { color: 'red', marginLeft: '3px' } }, { children: `(${rowData.commentCount})` }))] }));
            }
            return value;
        },
    },
    {
        field: 'creatorUsername',
        headerName: '작성자',
    },
    {
        field: 'createdAt',
        headerName: '작성일',
        format: (date) => moment(date).add(9, 'h').format('YYYY-MM-DD HH:mm'),
    },
];
