import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ClientPagingTable } from '@/components';
import { useClientPagingTable } from '@/hooks';
import { ConsoleTablePageLayout } from '@/layouts';
import { COL_DEF } from './constant';
export default function NcpClustersPage() {
    const tableProps = useClientPagingTable({
        queryKey: ['/api/v1/ncp/cluster'],
        select: (data) => data === null || data === void 0 ? void 0 : data.clusters,
    });
    return (_jsx(_Fragment, { children: _jsx(ConsoleTablePageLayout, Object.assign({ breadcrumb: ['CSP 자원조회', 'NCP', 'Kubernetes Service', 'Cluster'] }, { children: _jsx(ClientPagingTable, Object.assign({}, tableProps, { height: "100%", columnDefs: COL_DEF })) })) }));
}
