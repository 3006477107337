import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuthStore, useCommonStore } from '@/stores';
import MenuItem from './MenuItem';
import styles from './navMenuList.module.scss';
export default function MultipleMenuItem({ item }) {
    var _a;
    const setSelectedNavMenu = useCommonStore((state) => state.setSelectedNavMenu);
    const selectedNavMenu = useCommonStore((state) => state.selectedNavMenu);
    const openedMenus = useCommonStore((state) => state.openedMenus);
    const isAuthorized = useAuthStore((state) => state.userInfo.isAuthorized);
    const onClickMultiMenu = (item) => {
        setSelectedNavMenu(item.depth, item.menuId);
    };
    const isOpen = selectedNavMenu[item.depth - 1] === item.menuId || openedMenus.includes(item.menuId);
    return (_jsx(_Fragment, { children: (isAuthorized || !(item === null || item === void 0 ? void 0 : item.isCheckAuthorized)) && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.menuTitleWrapper, style: { marginLeft: (item.depth - 1) * 15 }, onClick: () => onClickMultiMenu(item) }, { children: _jsx("span", Object.assign({ className: isOpen ? `${styles.multiMenuTitle} ${styles.opened}` : styles.multiMenuTitle }, { children: item.name })) })), _jsx("div", Object.assign({ className: isOpen ? `${styles.childrenWrapper} ${styles.opened}` : styles.childrenWrapper }, { children: (_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.map((child, index) => _jsx(MenuItem, { item: child }, index)) }))] })) }));
}
