var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import * as process from "process";
import { deleteTokenCookies, getCookie, setCookie } from './cookie';
import { parseJwt } from './encrypt';
import { swal, swalConfirm } from './swal';
const axiosUtil = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.PROD_URL : process.env.DEV_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: getCookie('portalToken'),
    },
});
axiosUtil.interceptors.request.use((config) => {
    var _a;
    const token = getCookie(((_a = config === null || config === void 0 ? void 0 : config.url) === null || _a === void 0 ? void 0 : _a.startsWith('/api/v1/paas')) ? 'portalPaasToken' : 'portalToken');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});
let isRevalidateToken = false;
const revalidateSubscribers = new Set();
axiosUtil.interceptors.response.use((response) => {
    const { method } = response.config;
    if (method === 'post' || method === 'delete' || method === 'put') {
        if (response.data) {
            return response;
        }
        swal('요청이 성공적으로 완료되었습니다.');
    }
    return response.data;
}, (error) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    if ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) {
        // responseType이 blob인 경우에 대한 예외 (파일 다운로드)
        if (error.response.data instanceof Blob) {
            const { code, message } = JSON.parse(yield error.response.data.text());
            if (code === '9' || code === '7') {
                return Promise.reject(error);
            }
            swal(message);
            return Promise.reject(error);
        }
        // 리프레시 토큰이 유효X / 만료 0 인 경우
        else if (((_b = error.response) === null || _b === void 0 ? void 0 : _b.config.url) === '/api/v1/auth/userauth/refreshAccessToken' ||
            ((_c = error.response) === null || _c === void 0 ? void 0 : _c.config.url) === '/api/v1/auth/userauth/refreshPaasAccessToken') {
            swal('로그인이 만료되었습니다. 다시 로그인해주세요.').then(() => {
                deleteTokenCookies();
                document.location.replace('/');
            });
            return Promise.reject(error);
        }
        // 토큰이 만료된 경우 -> code가 몇인지 확인 필요
        else if (error.response.data.code === '7') {
            if (!isRevalidateToken) {
                isRevalidateToken = true;
                const refreshResponse = yield axiosUtil({
                    url: `/api/v1/auth/userauth/refreshAccessToken`,
                    method: 'post',
                    data: {
                        access_token: getCookie('portalToken'),
                        refresh_token: getCookie('portalRefreshToken'),
                        session_state: parseJwt(getCookie('portalToken')).session_state,
                        scope: parseJwt(getCookie('portalToken')).scope,
                    },
                });
                const refreshPaasResponse = yield axiosUtil({
                    url: `/api/v1/auth/userauth/refreshPaasAccessToken`,
                    method: 'post',
                    data: { token: getCookie('portalPaasToken'), refresh_token: getCookie('portalPaasRefreshToken') },
                });
                setCookie('portalToken', refreshResponse.data.access_token);
                setCookie('portalRefreshToken', refreshResponse.data.refresh_token);
                setCookie('portalPaasToken', refreshPaasResponse.data.token);
                setCookie('portalPaasRefreshToken', refreshPaasResponse.data.refresh_token);
                isRevalidateToken = false;
                revalidateSubscribers.forEach((cb) => cb());
                revalidateSubscribers.clear();
                return axiosUtil(error.config);
            }
            else {
                return new Promise((resolve) => revalidateSubscribers.add(() => resolve(axiosUtil(error.config))));
            }
        }
        const message = error.response.data.message;
        error.response.data.code !== '7' && swal(message ? message : '요청을 수행하는데 에러가 발생했습니다.');
        if (error.response.data.code === '5') {
            swalConfirm('로그아웃 되었습니다. 콘솔을 종료합니다').then((res) => {
                if (res.isConfirmed) {
                    window.close();
                }
            });
        }
    }
    return Promise.reject(error);
}));
export const requestFn = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosUtil(Object.assign(Object.assign({}, options), { url }));
    if (options.method === 'get')
        return response.content;
    return response;
});
export default axiosUtil;
