import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import ReactDom from 'react-dom';
import Draggable from 'react-draggable';
import { deleteCookie, requestFn, setCookie } from '@/utils';
import styles from './PopUpModal.module.scss';
const Portal = (props) => {
    const { isOpen, children, index } = props;
    if (!isOpen)
        return null;
    const modalWidth = 465;
    const modalHeight = 560;
    const leftBound = window.innerWidth * (30 / 100) + 100 * index;
    const topBound = window.innerHeight * (15 / 100) + 15 * index;
    return ReactDom.createPortal(_jsx(Draggable, Object.assign({ bounds: {
            left: -leftBound,
            top: -topBound,
            right: window.innerWidth - leftBound - modalWidth,
            bottom: document.documentElement.scrollHeight - topBound - modalHeight,
        } }, { children: children })), document.getElementById('portal-root'));
};
export default function PopUpModal({ data, index }) {
    const [isOpen, setIsOpen] = useState(true);
    const { data: imageUrl } = useQuery({
        queryKey: ['/api/v1/cmp/popup/popup-auth/download', data],
        queryFn: ({ queryKey }) => requestFn(queryKey[0], {
            method: 'POST',
            data: {
                postSeq: data.seq,
                fileOrder: data.files[0].fileOrder,
            },
            responseType: 'blob',
        }),
        select: (response) => window.URL.createObjectURL(response.data),
    });
    const onClickHidePopupForOneWeek = useCallback((event) => {
        if (event.currentTarget.checked) {
            const oneWeekFromNow = new Date();
            oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
            setCookie(`hiddenPopUp-${data.seq}`, [data.seq], { expires: oneWeekFromNow });
        }
        else {
            deleteCookie(`hiddenPopUp-${data.seq}`);
        }
    }, [data.seq]);
    return (_jsx(Portal, Object.assign({ isOpen: isOpen, index: index }, { children: _jsxs("div", Object.assign({ className: styles.modalWrapper, style: { top: '17vh', left: 'auto', right: '5vw', marginLeft: index * 100, marginTop: index * 15 } }, { children: [_jsx("div", Object.assign({ className: styles.imgWrapper }, { children: imageUrl && _jsx("img", { src: imageUrl, alt: "\uD31D\uC5C5 \uC774\uBBF8\uC9C0", draggable: "false" }) })), _jsxs("div", Object.assign({ className: styles.popUpFooter }, { children: [_jsx("div", Object.assign({ className: styles.popUpLink }, { children: _jsx("a", Object.assign({ href: data.address, target: "_blank", rel: "noreferrer" }, { children: "\uC774\uB3D9\uD558\uAE30" })) })), _jsxs("div", Object.assign({ className: styles.popupFooterBtnGroup }, { children: [_jsx("span", { children: "7\uC77C\uAC04 \uBCF4\uC9C0 \uC54A\uAE30" }), _jsx("input", { type: "checkbox", onChange: onClickHidePopupForOneWeek }), _jsx("span", { className: styles.closeBtn, onClick: () => setIsOpen(false) })] }))] }))] })) })));
}
