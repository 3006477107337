import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useLocation, useNavigate } from '@/hooks';
import { useAuthStore, useCommonStore } from '@/stores';
import styles from './navMenuList.module.scss';
export default function SingleMenuItem({ item }) {
    const navigate = useNavigate();
    const setSelectedNavMenu = useCommonStore((state) => state.setSelectedNavMenu);
    const selectedNavMenu = useCommonStore((state) => state.selectedNavMenu);
    const setOpenedMenus = useCommonStore((state) => state.setOpenedMenus);
    const isAuthorized = useAuthStore((state) => state.userInfo.isAuthorized);
    const getSelectedMenuClassName = () => {
        return styles.cmp;
    };
    const onClickSingleMenu = (item) => {
        if (item.menuId !== selectedNavMenu[item.depth]) {
            setSelectedNavMenu(item.depth, item.menuId);
        }
        setOpenedMenus([]);
        navigate(item.link);
    };
    return (_jsx(_Fragment, { children: (isAuthorized || !(item === null || item === void 0 ? void 0 : item.isCheckAuthorized)) && (_jsx("div", Object.assign({ className: styles.menuTitleWrapper, onClick: () => onClickSingleMenu(item) }, { children: _jsx("div", Object.assign({ className: styles.menuWrapper, style: { marginLeft: (item.depth - 1) * 15 } }, { children: _jsx("span", Object.assign({ className: `${styles.singleMenuTitle} ${useLocation().pathname.includes((item === null || item === void 0 ? void 0 : item.link) || '') ? getSelectedMenuClassName() : ''}` }, { children: item.name })) })) }))) }));
}
