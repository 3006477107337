import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from '@innogrid-ui/core';
import React from 'react';
import { Loading } from '@/components';
import { PAGE_SIZE_OPTIONS } from './constant';
import styles from './DataTable.module.scss';
export function DataTable({ data, columns, pagination, idField = 'id', onPageChange, onPageSizeChange, isShowPagingSelectBox = true, isFetching = false, }) {
    const { currentPage, totalPage, pageSize } = pagination;
    const renderPaginationButtons = () => {
        const maxButtons = 5;
        let startPage;
        if (currentPage % maxButtons === 0) {
            startPage = currentPage - (maxButtons - 1);
        }
        else
            startPage = currentPage - ((currentPage - 1) % maxButtons);
        const endPage = Math.min(totalPage, startPage + maxButtons - 1);
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => {
            const pageNum = startPage + i;
            return (_jsx("button", Object.assign({ type: "button", className: `${styles.pager} ${pageNum === currentPage ? styles.current : ''}`, onClick: () => onPageChange(pageNum) }, { children: pageNum }), `page-${pageNum}`));
        });
    };
    const renderTableContent = () => {
        if (isFetching) {
            return (_jsx("tr", Object.assign({ className: styles.loadingRow }, { children: _jsx("td", Object.assign({ colSpan: columns.length }, { children: _jsx("div", Object.assign({ className: styles.loadingContainer }, { children: _jsx(Loading, { color: "#268eff", className: styles.tableSpinner }) })) })) })));
        }
        if (!data.length) {
            return (_jsx("tr", Object.assign({ className: styles.emptyRow }, { children: _jsx("td", Object.assign({ colSpan: columns.length }, { children: _jsx("div", Object.assign({ className: styles.noData }, { children: _jsx("p", { children: "\uB370\uC774\uD130\uAC00 \uC874\uC7AC\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4." }) })) })) })));
        }
        return data.map((item, index) => {
            const rowKey = item[idField] || `row-${index}`;
            return (_jsx("tr", { children: columns.map((column, colIndex) => (_jsx("td", Object.assign({ className: column.className ? styles[column.className] : '' }, { children: column.render ? column.render(item) : item[column.key] }), `cell-${rowKey}-${colIndex}`))) }, `row-${rowKey}`));
        });
    };
    return (_jsxs("div", Object.assign({ className: styles.dataTableWrapper }, { children: [_jsx("div", Object.assign({ className: `${styles.tableBox} ${isFetching || !data.length ? styles.noBottomBorder : ''}` }, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsx("tr", { children: columns.map((column, index) => (_jsx("th", Object.assign({ style: { width: column.width }, className: column.className ? styles[column.className] : '' }, { children: column.title }), `header-${index}`))) }) }), _jsx("tbody", { children: renderTableContent() })] }) })), _jsxs("div", Object.assign({ className: styles.paging }, { children: [isShowPagingSelectBox && (_jsx("div", Object.assign({ className: styles.pagingSelectBox }, { children: _jsx(Select, { options: PAGE_SIZE_OPTIONS, useEmptyOption: false, rootProps: {
                                onValueChange: onPageSizeChange,
                                value: pageSize === null || pageSize === void 0 ? void 0 : pageSize.toString(),
                            } }) }))), _jsxs("div", Object.assign({ className: styles.pagerPaging }, { children: [_jsx("button", Object.assign({ type: "button", className: `${styles.btnPager} ${styles.prev}`, disabled: currentPage === 1 || totalPage === 0, onClick: () => onPageChange(currentPage - 1) }, { children: _jsx("span", { children: "\uC774\uC804" }) })), _jsx("div", Object.assign({ className: styles.pagerText }, { children: renderPaginationButtons() })), _jsx("button", Object.assign({ type: "button", className: `${styles.btnPager} ${styles.next}`, disabled: currentPage === totalPage || totalPage === 0, onClick: () => onPageChange(currentPage + 1) }, { children: _jsx("span", { children: "\uB2E4\uC74C" }) }))] }))] }))] })));
}
