var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { countBy, isEmpty } from 'lodash';
import { DATA_API_URL } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/constant';
import useDataProcessStore from '@/stores/dataProcessing/useDataProcessStore';
import { requestFn } from '@/utils/axios';
import { DataCreate, DataFusion, DataFusionCreate, DataOpen, DataProcess, DataSetup } from './_components';
import { PrivacyPolicyModal } from './PrivacyPolicyModal';
const DEFAULT_ALERT = {
    title: '',
    variant: 'common',
    cancelButtonText: '취소',
    children: '다음 단계로 이동하시겠습니까?',
};
export const onApiResponse = (response) => {
    return (response === null || response === void 0 ? void 0 : response.data.code) === 'S-200';
};
export const STEP_CONFIG = {
    dataProcess: {
        alert: {
            title: '다음 단계로 이동하시겠습니까?',
            variant: 'common',
            children: _jsx(PrivacyPolicyModal, {}),
            cancelButtonText: '취소',
            onClickConfirm: () => {
                const store = useDataProcessStore.getState();
                store.resetIsPrivacy();
            },
        },
        mutation: ({ state, updateStepState }) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const response = yield requestFn(`${DATA_API_URL}/table/create`, {
                method: 'POST',
                data: (_a = Object.values(state)[0]) === null || _a === void 0 ? void 0 : _a.dataProcess,
            });
            if (onApiResponse(response)) {
                updateStepState('dataOpen', {
                    apiUrl: response.data.result.api_url,
                    datasetName: response.data.result.dataset_nm,
                });
            }
            return response;
        }),
    },
    dataSetup: {
        validate: ({ state }) => {
            var _a;
            const dataSetup = (_a = Object.values(state)[0]) === null || _a === void 0 ? void 0 : _a.dataSetup;
            const cycle = (dataSetup === null || dataSetup === void 0 ? void 0 : dataSetup.cycle) || {};
            if (isEmpty(dataSetup === null || dataSetup === void 0 ? void 0 : dataSetup.name)) {
                return { isValid: false, message: '테이블명을 입력해 주세요.' };
            }
            const hasValidValue = Object.keys(cycle).some((key) => key !== 'type' && cycle[key] !== undefined);
            if ((dataSetup === null || dataSetup === void 0 ? void 0 : dataSetup.is_active) && !hasValidValue) {
                return { isValid: false, message: '스케줄 활성으로 선택하셨습니다. 스케줄 배치 설정을 하세요.' };
            }
            return { isValid: true };
        },
        alert: DEFAULT_ALERT,
        mutation: ({ state }) => __awaiter(void 0, void 0, void 0, function* () {
            var _b;
            const dataSetup = (_b = Object.values(state)[0]) === null || _b === void 0 ? void 0 : _b.dataSetup;
            // pp-070001 : 스케쥴러 등록 및 수정 API
            return requestFn(`${DATA_API_URL}/job/insert`, {
                method: 'POST',
                data: dataSetup,
            });
        }),
    },
    dataCreate: {
        validate: () => ({ isValid: true }),
        alert: Object.assign(Object.assign({}, DEFAULT_ALERT), { title: '다음 단계로 이동하시겠습니까?', children: '다음단계 이동 시 테이블이 생성되어 작업 수정이 불가합니다.' }),
    },
    dataOpen: {
        validate: ({ state }) => {
            var _a;
            const { public_yn, title, requestDescription } = ((_a = Object.values(state)[0]) === null || _a === void 0 ? void 0 : _a.dataOpen) || {};
            if (!public_yn)
                return { isValid: false, message: '등록 여부를 선택하세요.' };
            if (public_yn === 'Y' && (!title || !requestDescription)) {
                return {
                    isValid: false,
                    message: !title ? '제목을 입력해주세요.' : '목적을 입력해주세요.',
                };
            }
            return { isValid: true };
        },
        alert: null,
        mutation: ({ state }) => __awaiter(void 0, void 0, void 0, function* () {
            var _c;
            const dataOpen = ((_c = Object.values(state)[0]) === null || _c === void 0 ? void 0 : _c['dataOpen']) || {};
            const { tableName } = dataOpen, rest = __rest(dataOpen, ["tableName"]);
            // IF_KBI_00013 테이블 스키마 수정
            return yield requestFn(`${DATA_API_URL}/schema/update?tableName=${tableName}`, {
                method: 'POST',
                data: rest,
            });
        }),
    },
    dataFusionCreate: {
        validate: ({ state }) => {
            var _a;
            const { tableName, columnDataList } = ((_a = Object.values(state)[0]) === null || _a === void 0 ? void 0 : _a.dataFusionCreate) || {};
            if (isEmpty(tableName)) {
                return { isValid: false, message: '테이블명을 입력해주세요.' };
            }
            if (columnDataList === null || columnDataList === void 0 ? void 0 : columnDataList.some(({ name }) => isEmpty(name))) {
                return { isValid: false, message: '컬럼명을 입력해주세요.' };
            }
            const nameCounts = countBy(columnDataList, 'name');
            if (Object.values(nameCounts).some((count) => count > 1)) {
                return { isValid: false, message: '컬럼명이 중복되었습니다.' };
            }
            return { isValid: true };
        },
        alert: Object.assign(Object.assign({}, DEFAULT_ALERT), { title: '다음 단계로 이동하시겠습니까?', children: '다음단계 이동 시 테이블이 생성되어 작업 수정이 불가합니다.' }),
    },
    dataFusion: {
        validate: ({ state }) => {
            var _a, _b, _c;
            const fusionCreateState = ((_a = Object.values(state)[0]) === null || _a === void 0 ? void 0 : _a['dataFusionCreate']) || {};
            const { fusionState } = ((_b = Object.values(state)[0]) === null || _b === void 0 ? void 0 : _b.dataFusion) || {};
            const allNamesExist = (_c = fusionCreateState === null || fusionCreateState === void 0 ? void 0 : fusionCreateState.columnDataList) === null || _c === void 0 ? void 0 : _c.map((data) => data.name).every((name) => fusionState === null || fusionState === void 0 ? void 0 : fusionState.fusion_schema.some((schema) => schema.new_col === name && schema.col_list.length > 0));
            if (!allNamesExist) {
                return {
                    isValid: false,
                    message: (_jsxs(_Fragment, { children: ["\uC0DD\uC131\uD560 \uAC01 \uCEEC\uB7FC\uC5D0 \uB300\uD574 \uB9F5\uD551\uD560 \uCEEC\uB7FC\uC744", _jsx("br", {}), "\uCD5C\uC18C \uD558\uB098 \uC774\uC0C1 \uC120\uD0DD\uD574 \uC8FC\uC138\uC694."] })),
                };
            }
            return { isValid: true };
        },
        alert: Object.assign(Object.assign({}, DEFAULT_ALERT), { title: '다음 단계로 이동하시겠습니까?', children: '다음단계 이동 시 컬럼이 맵핑되어 작업 수정이 불가합니다.' }),
        mutation: ({ state, setInitDataset }) => __awaiter(void 0, void 0, void 0, function* () {
            var _d, _e;
            const id = Object.keys(state)[0];
            const { fusionState } = ((_d = Object.values(state)[0]) === null || _d === void 0 ? void 0 : _d.dataFusion) || {};
            // PP-080001 데이터 융합 API
            const response = yield requestFn(`${DATA_API_URL}/fusion`, {
                method: 'POST',
                data: fusionState,
            });
            if (onApiResponse(response)) {
                setInitDataset === null || setInitDataset === void 0 ? void 0 : setInitDataset(id, (_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.result);
            }
            return response;
        }),
    },
};
export const Steps = {
    api: [{ title: '데이터 가공' }, { title: '스케줄 설정' }, { title: '데이터 생성' }, { title: '데이터 개방' }],
    dataset: [{ title: '데이터 가공' }, { title: '데이터 생성' }, { title: '데이터 개방' }],
    dataFusion: [
        { title: '데이터 생성' },
        { title: '데이터 융합' },
        { title: '데이터 가공' },
        { title: '스케줄 설정' },
        { title: '데이터 개방' },
    ],
};
export const COMPONENT_MAP = {
    api: [DataProcess, DataSetup, DataCreate, DataOpen],
    dataset: [DataProcess, DataCreate, DataOpen],
    dataFusion: [DataFusionCreate, DataFusion, DataProcess, DataSetup, DataOpen],
};
export const STEP_VALIDATIONS = {
    api: {
        0: STEP_CONFIG.dataProcess,
        1: STEP_CONFIG.dataSetup,
        2: STEP_CONFIG.dataCreate,
        3: STEP_CONFIG.dataOpen,
    },
    dataset: {
        0: STEP_CONFIG.dataProcess,
        1: STEP_CONFIG.dataCreate,
        2: STEP_CONFIG.dataOpen,
    },
    dataFusion: {
        0: STEP_CONFIG.dataFusionCreate,
        1: STEP_CONFIG.dataFusion,
        2: STEP_CONFIG.dataProcess,
        3: STEP_CONFIG.dataSetup,
        4: STEP_CONFIG.dataOpen,
    },
};
