var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useNavigate, useQuery } from '@/hooks';
import { DefaultLayout } from '@/layouts';
import axiosUtil from '@/utils/axios';
import { API_URL } from './constant';
import styles from './PlatformService.module.scss';
import { BASE_URL } from "@/constants";
export default function PlatformServicePage() {
    const navigate = useNavigate();
    const { data } = useQuery({
        queryKey: [`${API_URL}/getBuildPackList`],
        queryFn: ({ queryKey }) => axiosUtil.get(queryKey[0], { baseURL: BASE_URL }),
    });
    const onClickFileDownload = useCallback((appSampleFileName) => __awaiter(this, void 0, void 0, function* () {
        const fileData = yield axiosUtil.get(`${API_URL}/downloadAppSample`, {
            params: { appSampleFileName: appSampleFileName },
            baseURL: BASE_URL,
            responseType: 'blob',
        });
        const blob = new Blob([fileData], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = appSampleFileName; // 파일명 및 확장자를 지정
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }), []);
    return (_jsxs(DefaultLayout, Object.assign({ breadcrumb: { Home: '/', '플랫폼 서비스': '' } }, { children: [_jsx("header", Object.assign({ className: styles.headerWrapper }, { children: _jsx("h3", { children: "\uC571 \uBC30\uD3EC\uD658\uACBD" }) })), _jsx("section", Object.assign({ className: styles.appContainer }, { children: data === null || data === void 0 ? void 0 : data.map((app) => (_jsx("div", Object.assign({ className: styles.app }, { children: _jsxs("div", Object.assign({ className: styles.appContent }, { children: [_jsxs("div", Object.assign({ className: styles.description, onClick: () => navigate(`/platform-service/${app.name}?no=${app.no}`) }, { children: [_jsx("span", Object.assign({ className: styles.name }, { children: app.name })), _jsx("span", Object.assign({ className: styles.summary }, { children: app.summary }))] })), _jsxs("div", Object.assign({ className: styles.download, onClick: () => onClickFileDownload(app.appSampleFileName) }, { children: ["\uC571 \uC0D8\uD50C", _jsx("br", {}), "\uB2E4\uC6B4\uB85C\uB4DC"] }))] })) }), app.no))) }))] })));
}
