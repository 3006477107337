export const CLOUD_SERVICE_LIST = [
    {
        sectionTitle: 'Server',
        items: [
            {
                title: 'Server',
                description: '클라우드 상에서 서버를 생성하고 확장할 수 있는 서비스',
            },
            {
                title: 'SSD Server',
                description: '빠른 입출력(I/O) 처리가 가능한 SSD가 장착된 서버를 제공하는 서비스',
            },
            {
                title: 'GPU Server',
                description: '병렬 연산에 최적화된 GPU 서버의 고성능 컴퓨팅 파워를 제공하는 서비스',
            },
        ],
    },
    {
        sectionTitle: 'Storage',
        items: [
            {
                title: 'Object Storage',
                description: '모든 종류의 데이터를 인터넷상에 저장/검색할 수 있는 객체 스토리지',
            },
            {
                title: 'Archive Storage',
                description: '데이터 아카이빙 및 장기 백업에 최적화된 스토리지',
            },
            {
                title: 'Block Storage',
                description: '데이터를 블록 단위로 분할하고 고유 식별자를 부여/저장하는 스토리지',
            },
        ],
    },
    {
        sectionTitle: 'Containers',
        items: [
            {
                title: 'Container Registry',
                description: '도커 컨테이너 이미지를 간편하게 저장, 관리, 배포할 수 있는 서비스',
            },
            {
                title: 'Kubernetes Service',
                description: '쿠버네티스 기반으로 컨테이너화된 앱을 배포/관리할 수 있는 서비스',
            },
        ],
    },
    {
        sectionTitle: 'Networking',
        items: [
            {
                title: 'VPU(Virtual Private Cloud)',
                description: '퍼블릭 클라우드 상에서 제공되는 고객 전용 사설 네트워크 공간',
            },
            {
                title: 'Load Balancer',
                description: '서버 성능과 부하량을 고려하여 네트워크 트래픽을 분산해주는 서비스',
            },
            {
                title: 'Cloud Connect',
                description: '데이터 센터 환경과 네이버 클라우드 플랫폼을 전용 사설로 연결 가능',
            },
        ],
    },
    {
        sectionTitle: 'Database',
        items: [
            {
                title: 'Cloud DB for PostgreSQL',
                description: 'PostgreSQL 서비스를 손쉽게 구축하고 자동으로 관리' + '',
            },
            {
                title: 'Cloud DB for MySQL',
                description: 'MySQL 데이터베이스 서비스를 손쉽게 구축하고 자동으로 관리',
            },
            {
                title: 'Cloud DB for Redis',
                description: 'Redis 캐시를 클라우드 상에서 간편하게 구축하고 안정적으로 운영 가능',
            },
        ],
    },
    {
        sectionTitle: 'Security',
        items: [
            {
                title: 'Secure Zone',
                description: '개인정보 등 중요 정보자원을 보안이 강화된 별도 Zone에서 관리하는 서비스',
            },
            {
                title: 'Basic Security',
                description: '모든 고객에게 기본으로 제공되는 무료 보안 서비스',
            },
            {
                title: 'ACG',
                description: 'IP 기반 필터링 기능으로 서버로의 네트워크 접근을 관리하는 서비스',
            },
        ],
    },
    {
        sectionTitle: 'AI Services',
        items: [
            {
                title: 'AiTEMS',
                description: '사용자별 이력을 분석하여 관심사와 취향에 맞는 상품을 추천하는 서비스',
            },
            {
                title: 'CLOVA AiCall',
                description: 'AI 기능을 기반으로 고객센터를 구축/운영할 수 있는 서비스',
            },
            {
                title: 'CLOVA Chatbot',
                description: '마케팅, 고객 응대 등 다양한 서비스에 활용할 수 있는 챗봇 생성 서비스',
            },
        ],
    },
    {
        sectionTitle: 'Application Services',
        items: [
            {
                title: 'GeoLocation',
                description: '사용자 IP 기반의 위치 정보를 제공하는 API',
            },
            {
                title: 'Maps',
                description: '네이버 지도 기능을 활용하여 위치 기반 서비스를 만들 수 있는 API',
            },
            {
                title: 'CAPTCHA',
                description: '다양한 방식으로 사람과 컴퓨터를 판별/어뷰징을 방지할 수 있는 서비스',
            },
        ],
    },
    {
        sectionTitle: 'Big Data & Analytics',
        items: [
            {
                title: 'Cloud Hadoop',
                description: 'Cloud Hadoop',
            },
            {
                title: 'Cloud Search',
                description: '웹사이트의 검색 기능을 간편하게 설정/관리할 수 있는 관리형 서비스',
            },
            {
                title: 'Search Engine Services',
                description: '다양한 데이터를 검색/분석/시각화할 수 있는 관리형 서비스',
            },
        ],
    },
];
