var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import imgCont from '@/assets/images/img_cont_1.png';
import goLogo from '@/assets/images/logo/etc/logo_go.png';
import javaLogo from '@/assets/images/logo/etc/logo_java.png';
import nginxLogo from '@/assets/images/logo/etc/logo_nginx.png';
import nodeLogo from '@/assets/images/logo/etc/logo_node.png';
import pythonLogo from '@/assets/images/logo/etc/logo_python.png';
import rubyLogo from '@/assets/images/logo/etc/logo_ruby.png';
import { BASE_URL } from '@/constants';
import { useNavigate, useQuery } from '@/hooks';
import { Default2Layout } from '@/layouts';
import axiosUtil from '@/utils/axios';
import { API_URL } from './constant';
import styles from './PlatformService.module.scss';
const logoSrcUrl = {
    nginx: nginxLogo,
    python: pythonLogo,
    go: goLogo,
    nodejs: nodeLogo,
    java8_online: javaLogo,
    ruby: rubyLogo,
};
export default function PlatformServicePage() {
    const navigate = useNavigate();
    const { data } = useQuery({
        queryKey: [`${API_URL}/getBuildPackList`],
        queryFn: ({ queryKey }) => axiosUtil.get(queryKey[0], { baseURL: BASE_URL }),
    });
    const onClickFileDownload = useCallback((appSampleFileName) => __awaiter(this, void 0, void 0, function* () {
        const fileData = yield axiosUtil.get(`${API_URL}/downloadAppSample`, {
            params: { appSampleFileName: appSampleFileName },
            baseURL: BASE_URL,
            responseType: 'blob',
        });
        const blob = new Blob([fileData], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = appSampleFileName; // 파일명 및 확장자를 지정
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }), []);
    return (_jsxs(Default2Layout, Object.assign({ breadcrumb: { Home: '/', '플랫폼 서비스': '' } }, { children: [_jsxs("div", Object.assign({ className: styles.headerWrapper }, { children: [_jsxs("h3", { children: ["\uD50C\uB7AB\uD3FC \uC11C\uBE44\uC2A4\uB97C \uC774\uC6A9\uD558\uC5EC \uC560\uD50C\uB9AC\uCF00\uC774\uC158\uC744", _jsx("br", {}), "\uAC1C\uBC1C \u00B7 \uBC30\uD3EC\uD558\uB294 \uC808\uCC28\uB97C \uC548\uB0B4\uD569\uB2C8\uB2E4."] }), _jsx("img", { src: imgCont, alt: "service step" })] })), _jsx("section", Object.assign({ className: styles.appContainer }, { children: _jsx("div", Object.assign({ className: styles.appBox }, { children: data === null || data === void 0 ? void 0 : data.map((app) => (_jsx("div", Object.assign({ className: styles.app }, { children: _jsxs("div", Object.assign({ className: styles.appContent }, { children: [_jsxs("div", Object.assign({ className: styles.description, onClick: () => navigate(`/platform-service/${app.name}?no=${app.no}`) }, { children: [_jsxs("div", Object.assign({ className: styles.logoBox }, { children: [_jsx("span", Object.assign({ className: styles.logo }, { children: _jsx("img", { src: (logoSrcUrl === null || logoSrcUrl === void 0 ? void 0 : logoSrcUrl[app.classificationCode]) || '', alt: app.classificationCode }) })), _jsx("span", Object.assign({ className: styles.summary }, { children: app.summary }))] })), _jsx("i", { className: styles.descArrow })] })), _jsx("div", Object.assign({ className: styles.download, onClick: () => onClickFileDownload(app.appSampleFileName) }, { children: "\uC571 \uC0D8\uD50C \uB2E4\uC6B4\uB85C\uB4DC" }))] })) }), app.no))) })) }))] })));
}
