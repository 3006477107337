import moment from "moment/moment";
export const COL_DEF = [
    { headerName: 'id', field: 'id' },
    { headerName: 'Name', field: 'name' },
    { headerName: 'Description', field: 'description' },
    { headerName: 'Status', field: 'status' },
    { headerName: '생성일시',
        field: 'createdAt',
        valueFormatter: (params) => moment(params.value).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss')
    }
];
