import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { Button, ToastViewer } from '@/components';
import { useCallback, useCustomQuery, useNavigate, useParams } from '@/hooks';
import { DetailLayout } from '@/layouts';
import QnaCommentForm from '@/pages/cmp/community/qna/_components/QnaCommentForm';
import { getCookie, parseJwt, requestFn, swalConfirm } from '@/utils';
import styles from './qnaDetail.module.scss';
export default function QnaDetailPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isFetching } = useCustomQuery({
        queryKey: [`/api/v1/cmp/community/qna/${id}`],
    });
    const { mutate: deleteMutate } = useMutation({
        mutationFn: () => requestFn('/api/v1/cmp/community/qna', {
            method: 'DELETE',
            data: { seq: id },
        }),
        onSuccess: () => navigate(`/qna?page=1`),
    });
    const onClickDelete = useCallback(() => {
        swalConfirm('삭제하시겠습니까?').then((res) => {
            if (res.isConfirmed)
                deleteMutate();
        });
    }, [deleteMutate]);
    const onClickUpdatePageLink = useCallback(() => {
        navigate(`/qna/update/${id}`);
    }, [navigate, id]);
    const onClickListBtn = useCallback(() => {
        navigate(`/qna`);
        window.scrollTo({ top: 0 });
    }, [navigate]);
    const { userseq } = (getCookie('portalToken') && parseJwt(getCookie('portalToken'))) || { userseq: -1 };
    return (_jsx(DetailLayout, Object.assign({ breadcrumb: { Home: '/', 'Q&A': '/qna' } }, { children: _jsxs("section", Object.assign({ id: "contetns" }, { children: [_jsx("table", Object.assign({ className: styles.board_list_board_view }, { children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", Object.assign({ className: styles.td_subject }, { children: data === null || data === void 0 ? void 0 : data.title })), _jsxs("td", Object.assign({ className: styles.td_date }, { children: [_jsx("span", { children: data === null || data === void 0 ? void 0 : data.creatorUsername }), _jsx("span", { children: (data === null || data === void 0 ? void 0 : data.qnaStatus) === 'PRIVATE' ? '비공개' : '공개' }), _jsx("span", { children: moment(data === null || data === void 0 ? void 0 : data.createdAt)
                                                .add(9, 'h')
                                                .format('YYYY-MM-DD HH:mm:ss') })] }))] }) }) })), userseq === (data === null || data === void 0 ? void 0 : data.userSeq) && (_jsxs("div", Object.assign({ className: styles.headerBtnGroup }, { children: [_jsx(Button, Object.assign({ onClick: onClickUpdatePageLink }, { children: "\uC218\uC815" })), _jsx(Button, Object.assign({ onClick: onClickDelete }, { children: "\uC0AD\uC81C" }))] }))), _jsx("div", Object.assign({ className: styles.view_contents }, { children: !isFetching && _jsx(ToastViewer, { initialValue: data === null || data === void 0 ? void 0 : data.contents }) })), _jsx(QnaCommentForm, {}), _jsx("div", Object.assign({ className: styles.board_btn_wrap }, { children: _jsx(Button, Object.assign({ type: "button", onClick: onClickListBtn }, { children: "\uBAA9\uB85D" })) }))] })) })));
}
